import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import $ from "jquery";
import {toast} from "react-toastify";
import API_LINK from "../link/Link";

function DepositCard({data}) {
    const [price, setPrice] = useState(1)
    const [quantity, setQuantity] = useState('10');

    const LoadPlatformID = async (platform) => {
        try {
            let platformID = "";
            let formdata = new FormData();
            formdata.append("page", "LoadUserPlatformID");
            formdata.append("userId", localStorage.getItem('UserId'));
            formdata.append("platform", platform);
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    // console.log(result);
                    if (result.status === 1) {
                        platformID = result.data[0].PlatformID;
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                })
                .catch(error => console.log('error', error));
            return platformID;
        } catch (e) {
            console.log(e)
        }
    }

    async function handleCart() {
        try {
            let continueNext = true;
            if (localStorage.getItem("UserId") === null || localStorage.getItem("UserId") === undefined || localStorage.getItem("UserId") === "") {
                toast.warn("You need to login to add items to your cart.");
            } else {
                let toaster;
                var card = $('.card-footer');
                card.find("button").attr("disabled", true);
                toaster = toast.loading("Processing...");
                let cart = localStorage.getItem('cart');
                if (cart === null || cart === undefined) {
                    cart = [];
                } else
                    cart = JSON.parse(cart);
                if (cart.length > 0) {
                    let filterData = cart.filter((item) => item.name.toLowerCase() === data.name.toLowerCase())
                    // console.log(filterData)
                    // console.log(cart)
                    if (filterData.length <= 0) {
                        // console.log("pushed")
                        data.qty = quantity;
                        data.platformID = await LoadPlatformID(data.name.trim());
                        if (data.platformID) {
                            cart.push(data);
                        } else {
                            toast.update(toaster, {
                                type: "warning",
                                render: "You need to add platformID first to add this item to your cart.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            continueNext = false;
                        }
                    }
                } else {
                    data.qty = quantity;
                    data.platformID = await LoadPlatformID(data.name.trim());
                    if (data.platformID) {
                        cart.push(data);
                    } else {
                        toast.update(toaster, {
                            type: "warning",
                            render: "You need to add platformID first to add this item to your cart.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        continueNext = false;
                    }
                }
                if (continueNext) {
                    setTimeout(() => {
                        toast.update(toaster, {
                            type: "success",
                            render: "Item added to cart successfully.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        $('#cart-badge').text(cart.length);
                        localStorage.setItem('cart', JSON.stringify(cart))
                        card.find("button").attr("disabled", false);
                    }, 1500)
                } else {
                    card.find("button").attr("disabled", false);
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="deposit-card-container">
            <div className="deposit-card">
                <div className="card-header">
                    <img src={data.image} alt="Game Image"/>
                </div>
                <div className="card-body">
                    <div className="card-title">{data.name}</div>
                    <div className="card-quantity">
                        <span>${price}.00</span>
                        <input type="number" value={quantity} onChange={event => setQuantity(event.target.value)}
                               min={10}/>
                    </div>
                </div>
                <div className="card-footer">
                    <button className={'btn btn-primary'} onClick={handleCart}>Add To Cart</button>
                    <NavLink to={`${data.path}`}>Learn More</NavLink>
                </div>
            </div>
        </div>
    );
}

export default DepositCard;