import {HiArrowLongLeft, HiArrowLongRight} from "react-icons/hi2";
import {NavLink} from "react-router-dom";

const FAQData = [
    {
        title: "What is Cloud9?",
        content: <p>Cloud9 is an online crypto casino platform that offers you exceptional gaming titles where you
            can play and win real money. There are more than 300+ interactive online casino games you can try
            on&nbsp;Cloud9, and all of them are optimized for both desktop and mobile devices.</p>,
    },
    {
        title: "What Are the available platforms on Cloud9?",
        content: <NavLink to={'/'} className={'btn btn-icon btn-primary mv-2'} style={{width : 245}}>Go to
            Platforms <HiArrowLongRight/></NavLink>,
    },
    {
        title: "How do you register on Cloud9?",
        content: <p>To sign up on Cloud9, you need to click on the "sign-up" button on the website’s homepage. Then,
            you will be redirected to the sign-up page, where you need to feel the necessary details to complete the
            registration process. After you submit those details, our team will review the application and approve it as
            soon as possible. After the approval, you will get an email about confirmation. Then you can enter your
            account, pick among the available online casino platforms, and start playing your favorite games on
            Cloud9.</p>,
    },
    {
        title: "What is an ID number in registration?",
        content: <p>The identification number is printed on official documents, such as your international passport,
            driver’s license, residents permit, and ID card.</p>,
    },
    {
        title: "How do I deposit on Cloud9?",
        content: <p>You can use your Bitcoin, Litecoin, or Dogecoin wallet to deposit funds on Cloud9. To do that,
            click "Transactions" and "Deposit". Next, choose your preferred platform and enter the amount you want to
            deposit. After, click on "Add to Cart" and proceed to the checkout page. </p>,
    },
    {
        title: "How long does it take for the deposit to be credited to my game account?",
        content: <p><span><span><span>The transaction speed depends on the fee amount that the user picks to pay. If you pay a higher fee, the instant deposits are available for you, which takes around 0 to 3 hours. As soon as we get the funds, they will be credited to your account by Cloud9 staff. During this time, please be patient.</span></span></span>
        </p>,
    },
    {
        title: "How do I withdraw from Cloud9?",
        content: <p>To cash out your winnings, click on “Withdrawal”. On the withdrawal page, enter the amount and the
            platform you want to withdraw from. If you haven’t added your Litecoin, Dogecoin, or Bitcoin wallet, do so
            before completing the transaction.</p>,
    },
    {
        title: "How long does it take to complete the withdrawal process?",
        content: <p>The withdrawal process can take up to 8 hours due to the issues regarding the checks on the account,
            verification, balance, and crypto transactions, which might be delayed because of the high traffic in the
            blockchain. However, there is no need to worry because, in general, it takes less time, and you manage to
            withdraw the funds instantly.</p>,
    },
    {
        title: "Can I withdraw my sign-up bonus?",
        content: <p>To be eligible for withdrawals, you need to deposit cash, earn your bonus, play, and win. Without
            playing, you cannot withdraw the funds right after signing up.</p>,
    },
    {
        title: "What is a BTC address?",
        content: <p>A Bitcoin wallet address is a 26-35 digit combination consisting of both numbers and letters. For
            the reference, it looks like this: <br/> 1FfmbHfnpaZjKFvyi1okTjJJusN455paPH</p>,
    },
    {
        title: "How to play?",
        content: <p>To play the best sweepstakes slots on Cloud9, you need to first register at the platform and
            verify your account by providing the necessary details. From then, click on the "platforms" tab to see the
            available options and games that you can try.</p>,
    },
    {
        title: "How can I refer to my friend?",
        content: <p>After setting up your Cloud9 account, you can use the referral program to earn extra cash. First,
            find the profile tab on your account page and copy the referral link that you can find there. As soon as you
            send that link to your friends, and they register on Cloud9 and make at least $20 worth of deposits by
            using that particular link, you and your friends will earn $10 worth of free credits.</p>,
    },
    {
        title: "How do I buy, send, or receive Bitcoin using Coinbase?",
        content: <p>You can easily make all the transactions as mentioned earlier through Coinbase. See our detailed
            guide: <br/><a href="#">Click here</a></p>,
    },
    {
        title: "What Happens if fraud is detected?",
        content: <p>Any user who has made a deposit and is found engaging in fraudulent activity will be temporarily
            blocked for one week from the site. The suspension will last for three months if the person hasn’t made any
            deposit.</p>,
    },
    {
        title: "Will my account be banned after a few months of inactivity?",
        content: <p>Yes, your gaming account will be deactivated if you don’t use it for over a 60-day period.</p>,
    },
    {
        title: "What is Dogecoin?",
        content: <p>Dogecoin is one of the major cryptocurrencies invented in the early 2010s. It is a decentralized
            coin that runs on blockchain technology. </p>,
    },
    {
        title: "Is Dogecoin widely used?",
        content: <p>Yes, Dogecoin enjoys high popularity among crypto enthusiasts and is widely used across many
            industries. </p>,
    },
    {
        title: "Is Dogecoin similar to Bitcoin?",
        content: <p>Yes, Dogecoin and Bitcoin are both cryptocurrencies that use decentralized blockchain technology.
            Dogecoin was created as one of the alternatives for Bitcoin and has been fairly successful in this
            role. </p>,
    },
    {
        title: "Can I use Dogecoin on Cloud9?",
        content: <p>Yes, you can use Dogecoin on Cloud9. Many Cloud9 users opt for Dogecoins for their many
            advantages and use them for making deposits and withdrawals. </p>,
    },
    {
        title: "What are the pros of Dogecoin?",
        content: <p>Dogecoin has a strong community full of innovative initiatives. This token stands out for its fast
            confirmation of payments and low transaction fees. </p>,
    },
    {
        title: "Are Dogecoin transactions safe?",
        content: <p>Yes, Dogecoin transactions are safe. As we pointed out above, Dogecoin uses a decentralized system
            where transactions are made with smart contracts on blockchain technology. This system ensures the maximum
            level of safety. </p>,
    },
    {
        title: "How can I get Dogecoin?",
        content: "You can get Dogecoin at many crypto wallets or crypto exchanges. For our part, we recommend purchasing it on Edge Wallet. ",
    },
    {
        title: "What is Edge Wallet?",
        content: <p>Edge is an easy-to-use crypto wallet that supports a large set of cryptocurrencies. Edge Wallet has
            been a popular choice in the crypto community for a long time and continues to provide customers with the
            best services on the market. </p>,
    },
    {
        title: "Will my funds be safe on Edge Wallet?",
        content: <p>Yes, you are guaranteed that you will be able to store your funds safely on Edge Wallet. Edge
            provides non-custodial service and has no access to your coins or keys. It also allows you to use the 2FA
            security system. </p>,
    },
    {
        title: "How can I withdraw Dogecoin from my Cloud9 account?",
        content: <p>Open Edge Wallet and go to “wallets,” select Dogecoin, and click “request.” Copy the generated
            crypto address and then log in to your Cloud9 account. Go to withdrawals, enter the amount you wish to
            withdraw and paste your Edge Wallet Dogecoin crypto address. Review the payment and confirm it. </p>,
    },

]
export default FAQData;