import React, {useEffect, useState} from 'react';
import {HiClipboard} from "react-icons/hi";
import {toast} from "react-toastify";
import $ from "jquery";
import API_LINK from "../../../components/link/Link";
import {useNavigate} from "react-router-dom";

function Profile() {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [referralCode, setReferralCode] = useState('');
    const [role, setRole] = useState('');
    const [phone, setPhone] = useState('');
    const [userName, setUserName] = useState('');

    useEffect(() => {
        setReferralCode("af5d59787")
        setRole("LEVEL 1")
        setFirstName(localStorage.getItem("FirstName"))
        setLastName(localStorage.getItem("LastName"))
        setEmail(localStorage.getItem("Email"))
        setPhone(localStorage.getItem("Phone"))
        setUserName(localStorage.getItem("UserName"))
    }, []);

    function handleSubmit(event) {
        try {
            event.preventDefault();
            event.stopPropagation();
            let toaster;
            var form = $('.auth-form');
            let formdata = new FormData();

            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            formdata.append("page", "UpdateProfile");
            formdata.append("userId", localStorage.getItem("UserId"))
            formdata.append("firstname", firstName)
            formdata.append("lastname", lastName)
            formdata.append("email", email)
            formdata.append("username", userName)
            formdata.append("phone", phone)
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${API_LINK}Authentication.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if (result.status === 1) {
                        toast.update(toaster, {
                            type: "success",
                            render: "Profile details has been changed & updated successfully.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        localStorage.setItem("FirstName", firstName)
                        localStorage.setItem("LastName", lastName)
                        localStorage.setItem("UserName", userName)
                        localStorage.setItem("Email", email)
                        localStorage.setItem("Phone", phone)
                        form.find("button").attr("disabled", false);
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content">
                <form onSubmit={handleSubmit}>
                    <div className="profile-form">
                        <div className="left">
                            <div className="profile-form-title">
                                <h2>Personal Info</h2>
                            </div>
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" value={firstName} required
                                       onChange={event => setFirstName(event.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" value={lastName}
                                       onChange={event => setLastName(event.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" value={email} required onChange={event => setEmail(event.target.value)}/>
                            </div>
                        </div>
                        <div className="right">
                            <div className="profile-form-title">
                                <h2>Account Details</h2>
                            </div>
                            <div className="form-group">
                                <label>Referral Code</label>
                                <div className="form-group-row">
                                    <input type="text" style={{width: "80%", alignItems: "flex-end"}}
                                           value={referralCode}
                                           onChange={event => setReferralCode(event.target.value)} disabled/>
                                    <button className={'btn-clipboard'} onClick={() => {
                                        toast.success("Referral copied successfully")
                                    }} type="button">
                                        <HiClipboard/>
                                    </button>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Phone</label>
                                <input type="text" value={phone} onChange={event => setPhone(event.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Role</label>
                                <input type="text" value={role} onChange={event => setRole(event.target.value)}
                                       disabled/>
                            </div>
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" value={userName} required
                                       onChange={event => setUserName(event.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-group-row" style={{marginTop: '30px'}}>
                        <div className="form-group">
                            <button className={'btn btn-primary'}>Update Profile</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Profile;