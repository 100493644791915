import React from 'react';
import {Table} from "antd";

function Messages() {
    const columns = [
        {
            title: '#',
        },
        {
            title: 'Subject',
        },
        {
            title: 'Date',
        },
        {
            title: 'Seen',
        },
        {
            title: 'Actions',
        }
    ];
    const data = [];

    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content">
                <div className="dashboard-content-title">
                </div>
                <div className="dashboard-content-table">
                    <Table columns={columns} dataSource={data} />
                </div>
            </div>
        </div>
    );
}

export default Messages;