import {LOGO} from "../../assets/images/Images";
import {NavLink, useNavigate} from "react-router-dom";
import {GiftOutlined, ShoppingCartOutlined, BellOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {RiMenu4Line} from "react-icons/ri";
import {AiOutlineClose} from "react-icons/ai";
import $ from 'jquery'

export const Menu = () => {
    const navigate = useNavigate();
    const [cartCount, setCartCount] = useState(0)
    useEffect(() => {
        setCartCount(localStorage.getItem("cart") === null || localStorage.getItem("cart") === undefined ? 0 : JSON.parse(localStorage.getItem("cart")).length);
        $('.nav-item a').click(function () {
            $('#mobile_menu').removeClass('show');
        })
    }, [navigate]);
    return (
        <div className={'navbar'}>
            <div className="navbar-brand">
                <NavLink to="/">
                    <img src={LOGO} alt="Brand Logo"/>
                </NavLink>
            </div>
            <div className="navbar-menu-container" id={'mobile_menu'}>
                <button className="btn-mobile-menu btn-close" onClick={() => {
                    $('#mobile_menu').removeClass('show');
                }}>
                    <AiOutlineClose/>
                </button>
                <div className="navbar-menu">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink to='/how-it-works'><span className={'nav-count'}>1</span>How It Works</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to='/promotions'><span className={'nav-count'}>2</span>Promotions</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to='/'><span className={'nav-count'}>3</span>Withdrawal</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to='/deposit'><span className={'nav-count'}>4</span>Deposit Now!</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="navbar-menu">
                    <ul className="navbar-nav">
                        {
                            localStorage.getItem("UserId") !== null && localStorage.getItem("UserId") !== undefined && localStorage.getItem("UserId") !== "" ?
                                <>
                                    <li className="nav-item">
                                        <NavLink to='/deposit' className={'btn-square-box'}>
                                            <GiftOutlined/>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/cart' className={'btn-square-box'}>
                                            <div className={'cart-badge-container'}>
                                            <span
                                                className={'cart-badge'} id={'cart-badge'}>{cartCount}</span>
                                            </div>
                                            <ShoppingCartOutlined/>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/user/messages' className={'btn-square-box'}>
                                            <BellOutlined/>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/user/dashboard' className={'btn-square-box bg'}>
                                            <span>{localStorage.getItem("UserName").slice(0, 2).toUpperCase()}</span>
                                        </NavLink>
                                    </li>
                                </> :
                                <>
                                    <li className="nav-item">
                                        <NavLink to='/login' className={'btn-login'}>Login</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/register' className={'sign-up'}>Sing Up</NavLink>
                                    </li>
                                </>
                        }

                    </ul>
                </div>
            </div>
            <button className="btn-mobile-menu" onClick={() => {
                $('#mobile_menu').addClass('show');
            }}>
                <RiMenu4Line/>
            </button>
        </div>
    )
}
