import { ABOUT_VIDEO, HERO_INTRO } from "../../../assets/images/Images";
import GameCard from "../../../components/cards/GameCard";
import GameData from "../../../components/games/GameData";
import { TestimonialCard } from "../../../components/cards/TestimonialCard";
import TestimonialData from "../../../components/testimonials/TestimonialData";
import PromotoinData from "../../../components/promotions/PromotoinData";
import { PromotionCard } from "../../../components/cards/PromotionCard";
import { ArrowRightOutlined, MobileOutlined } from "@ant-design/icons";
import { HiArrowLongRight } from "react-icons/hi2";
import { BiGlobe } from "react-icons/bi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { FcMultipleDevices } from "react-icons/fc";
import { GrMultiple } from "react-icons/gr";

export const Home = () => {
    return (
        <div className={'container'}>
            <div className="message-bar">
                <p><b>Experience quick and easy withdrawals at Cloud9</b> Where your winnings are just a click away</p>
            </div>
            <section className="section-hero" data-aos={'fade-up'}>
                <video autoPlay muted controls={false} loop={true}>
                    <source src={HERO_INTRO} type="video/webm" />
                </video>
            </section>
            <section className="section-platforms" data-aos={'fade-up'}>
                <h1 className="section-title">Platforms</h1>
                <div className="platforms-cards">
                    {
                        GameData.map((g, index) => {
                            return (
                                <GameCard data={g} />
                            )
                        })
                    }
                </div>
            </section>
            {/*<section className="section-platforms" data-aos={'fade-up'}>*/}
            {/*    <h1 className="section-title">Testimonials</h1>*/}
            {/*    <div className="platforms-cards">*/}
            {/*        {*/}
            {/*            TestimonialData.map((t, index) => {*/}
            {/*                return (*/}
            {/*                    <TestimonialCard data={t} key={index}/>*/}
            {/*                )*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</section>*/}
            <section className="section-platforms" data-aos={'fade-up'}>
                <h1 className="section-title">Promotions</h1>
                <div className="platforms-cards">
                    {
                        PromotoinData.map((p, index) => {
                            return (
                                <PromotionCard data={p} key={index} />
                            )
                        })
                    }
                </div>
            </section>
            <section className="section-about" data-aos={'fade-up'}>
                <h1 className="section-title"><b>Step Into the World of Cloud9</b> – Your Ultimate Gaming Destination</h1>
                <p>At Cloud9 Casino, we bring you an unmatched selection of premium games from leading online casino providers, along with the chance to win incredible cash prizes!</p>
                <p>Discover a thrilling variety of games, enticing bonuses, and exclusive promotions that ensure you’ll always have a reason to return for more excitement!</p>
                <p>As a leader in the online casino industry, we deliver state-of-the-art gaming solutions that are both intuitive and reliable. This ensures you enjoy an unparalleled sweepstakes gaming adventure every time!</p>
                <p><b>Join Cloud9</b> today, the ultimate platform that promises an unforgettable gaming journey!</p>
                <button className={'btn btn-icon btn-primary mv-3'}>Sign Up Now <HiArrowLongRight /></button>
            </section>

            <section className="section-choose" data-aos={'fade-up'}>
                <h1 className="section-title">What Makes Cloud9 Your Best Choice?</h1>
                <ul className={'choose-items'}>
                    <li data-aos={'fade-up'}>
                        <div className="box">
                            <BiGlobe />
                        </div>
                        <div className={'content'}>
                            <h4>Immerse Yourself in a True Casino Experience</h4>
                            <p>We bring you the newest and most exciting casino games with unbeatable odds. These games feature stunning graphics and immersive sound effects, ensuring top-tier satisfaction. Don’t forget to take advantage of Cloud9 promo codes and referral codes to earn free credits while you play.</p>
                        </div>

                    </li>
                    <li data-aos={'fade-up'}>
                        <div className="box">
                            <RiSecurePaymentLine />
                        </div>
                        <div className={'content'}>
                            <h4>Reliable and Secure Payment System</h4>
                            <p>Cloud9 Casino offers a robust and secure payment system to safeguard all your transactions. This ensures you can enjoy your favorite games worry-free and without limitations. No matter which payment method you choose, you can trust that deposits and withdrawals with Cloud9 will be seamless and hassle-free.</p>
                        </div>

                    </li>
                    <li data-aos={'fade-up'}>
                        <div className="box">
                            <GrMultiple />
                        </div>
                        <div className={'content'}>
                            <h4>Versatile Multi-Platform Casino Experience</h4>
                            <p>Cloud9 Casino brings you access to over 20 premium platforms, including RiverMonster Casino, Fire Kirin, and Orion Stars Casino. Each platform features distinctive games, generous payout rates, and exclusive promotions. Dive into a collection of over 1,000 top-quality games and enjoy an immersive experience while earning real money! With Cloud9 Casino, excitement is guaranteed at every moment!</p>
                        </div>

                    </li>
                    <li data-aos={'fade-up'}>
                        <div className="box">
                            <MobileOutlined />
                        </div>
                        <div className={'content'}>
                            <h4>Cloud9 Games are Mobile-Optimized</h4>
                            <p>Download the Cloud9 app to enjoy your favorite games on your smartphone. Every platform is fully optimized for mobile gameplay, delivering HD graphics, immersive sound, and captivating themes. Plus, when you access the platform via mobile, you can unlock exclusive rewards by using Cloud9 no deposit bonus codes.</p>
                        </div>

                    </li>
                </ul>
            </section>
            {/* <section className="section-about2" data-aos={'fade-up'}>
                <h1 className="section-title">About Cloud9</h1>
                <div className="content">
                    <div data-aos={'fade-right'}>
                        <video controls={true} autoPlay={false} loop={true} muted={true}>
                            <source src={ABOUT_VIDEO} type="video/mp4" />
                        </video>
                    </div>
                    <div data-aos={'fade-left'}>
                        <p>Welcome to Cloud9, the ultimate online casino platform designed to deliver a top-tier gaming experience right at your fingertips! Leveraging advanced casino software, we offer an extensive collection of premium games and incredible online casino promotions.</p>
                        <p>At Cloud9, we believe gaming should be exciting and immersive. That’s why we’ve curated a platform featuring the latest and most thrilling casino games, including timeless classics, video slots, and traditional table games.</p>
                        <p>If you’re looking for a platform that provides an unparalleled online casino gaming experience, look no further than Cloud9. Join us today and elevate your gameplay to new heights!</p>
                    </div>

                </div>
            </section> */}
        </div>
    )
}
