import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import $ from "jquery";
import {HiArrowLongRight} from "react-icons/hi2";
import {BiBitcoin} from "react-icons/bi";
import {SiDogecoin} from "react-icons/si";
import {Modal, QRCode} from "antd";
import {HiClipboard} from "react-icons/hi";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {toast} from "react-toastify";
import API_LINK from "../../../components/link/Link";

function Cart() {
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([])
    const [orderType, setOrderType] = useState('summary');
    const [paymentType, setPaymentType] = useState('bitcoin');
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [totalOrderPayment, setTotalOrderPayment] = useState('0.00000')
    const [isProof, setIsProof] = useState(false);
    const [proofImage, setProofImage] = useState('');


    useEffect(() => {
        let cart = localStorage.getItem('cart');
        if (cart) {
            cart = JSON.parse(cart);
            setCartItems(cart);
        }
    }, [navigate]);

    const convertPayment = async () => {
        try {
            let price = 0;
            cartItems.forEach((item, index) => {
                price = price + parseInt(item.qty);
            })

            setLoading(true);
            var settings = {
                "url": `https://blockchain.info/tobtc?currency=USD&value=${price}`,
                "method": "GET",
                "timeout": 0,
            };

            $.ajax(settings).done(function (response) {
                setTotalOrderPayment(response)
            });
            setLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    function handleRemove(id) {
        try {
            const filteredItems = cartItems.filter(item => item.id !== id);

            setCartItems(filteredItems)
            localStorage.setItem("cart", JSON.stringify(filteredItems));
            $('#cart-badge').text(filteredItems.length);
        } catch (e) {
            console.log(e)
        }
    }

    async function placeOrder() {
        try {
            if (proofImage) {
                const cart = cartItems;
                let price = 0;
                cartItems.forEach((item, index) => {
                    price = price + parseInt(item.qty);
                })
                let toaster;
                let formdata = new FormData();
                toaster = toast.loading("Processing...");
                formdata.append("page", "PlaceOrder");
                formdata.append("userId", localStorage.getItem("UserId"));
                formdata.append("platforms", JSON.stringify(cart))
                formdata.append("payment_type", paymentType);
                formdata.append("totalAmount", price);
                formdata.append("image", proofImage);
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                await fetch(`${API_LINK}Orders.php`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result)
                        if (result.status === 1) {
                            setTimeout(() => {
                                setCartItems([]);
                                $('#cart-badge').text(cartItems.length);
                                localStorage.setItem("cart", JSON.stringify([]));

                                toast.update(toaster, {
                                    type: "success",
                                    render: "Your order has been placed successfully.",
                                    isLoading: false,
                                    autoClose: toaster,
                                    pauseOnHover: false,
                                    pauseOnFocusLoss: false,
                                    closeButton: true,
                                    closeOnClick: true,
                                })
                                setOpen(false)
                            }, 1500)

                        } else {
                            toast.update(toaster, {
                                type: "error",
                                render: "Server Error! Please try again later.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        }
                    })
                    .catch(error => console.log('error', error));
                setOpen(false)
            } else {
                toast.warn("You need to add proof of payment before placing order.")
            }
        } catch (e) {
            console.log(e)
        }

    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProofImage(reader.result)
                setIsProof(true);
            };
            reader.readAsDataURL(file);
        } else {
            setIsProof(false)
        }
    }

    return (
        <>
            <Modal
                title={<p>{paymentType.toUpperCase()} Address</p>}
                loading={loading}
                open={open}
                onOk={placeOrder}
                okText={'Place Order'}
                onCancel={() => setOpen(false)}
            >
                <div className="crypto-container">
                    <QRCode
                        errorLevel="H"
                        value={paymentType === 'bitcoin' ? process.env.REACT_APP_BITCOIN : process.env.REACT_APP_DOGECOIN}
                        icon={paymentType === 'bitcoin' ? process.env.REACT_APP_BITCOIN_ICON : process.env.REACT_APP_DOGECOIN_ICON}
                    />
                    <span className="crypto-amount">{totalOrderPayment} BTC</span>
                    <div className="crypto-address">
                        <span>{paymentType === 'bitcoin' ? process.env.REACT_APP_BITCOIN : process.env.REACT_APP_DOGECOIN}</span>
                        <CopyToClipboard
                            text={paymentType === 'bitcoin' ? process.env.REACT_APP_BITCOIN : process.env.REACT_APP_DOGECOIN}
                            onCopy={() => {
                                toast.success("Address copied to clipboard.")
                            }}>
                            <button>
                                <HiClipboard/>
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div className="form-group mv-2">
                        <label>Proof Of Payment</label>
                        <input type="file" onChange={handleFileChange}/>
                    </div>
                </div>
            </Modal>

            <div className={'container'}>
                <div className="text-banner">
                    <h1>Your Cart</h1>
                </div>
                <section className="section-cart">
                    {
                        cartItems.length > 0 ?
                            <>
                                <div className="cart-details-container">
                                    <div className="cart-details">
                                        {
                                            cartItems.map((item, index) => (
                                                <div className="cart-item">
                                                    <div>
                                                        <img className="cart-image" src={item.image}
                                                             alt={'Game Image'}/>
                                                        <span className={'cart-name'}>{item.name}</span>
                                                    </div>
                                                    <span className={'cart-qty'}>{item.qty}</span>
                                                    <span className={'cart-qty'}>${parseInt(item.qty)}</span>
                                                    <button onClick={() => {
                                                        handleRemove(item.id);
                                                    }}>
                                                        <CloseOutlined/>
                                                    </button>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                                <div className="cart-details-payment-container">
                                    <div className="cart-details-payment">
                                        {
                                            orderType === "summary" ?
                                                <>
                                                    <h3>Order Summary</h3>
                                                    <div>
                                                        <small>Subtotal</small>
                                                        <small>${
                                                            function () {
                                                                let price = 0;
                                                                cartItems.forEach((item, index) => {
                                                                    price = price + parseInt(item.qty);
                                                                })
                                                                return price;
                                                            }.call(this)
                                                        }.00</small>
                                                    </div>
                                                    <div>
                                                        <span>Total</span>
                                                        <span>${
                                                            function () {
                                                                let price = 0;
                                                                cartItems.forEach((item, index) => {
                                                                    price = price + parseInt(item.qty);
                                                                })
                                                                return price;
                                                            }.call(this)
                                                        }.00</span>
                                                    </div>
                                                    <button className={'btn btn-primary mv-2'}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center"
                                                            }}
                                                            onClick={() => setOrderType('checkout')}>Checkout <HiArrowLongRight/>
                                                    </button>
                                                    <NavLink to={'/deposit'}>Continue shopping...</NavLink>
                                                </> :
                                                <>
                                                    <h3>CheckOut</h3>
                                                    <div>
                                                        <span>Total Amount</span>
                                                        <span>${
                                                            function () {
                                                                let price = 0;
                                                                cartItems.forEach((item, index) => {
                                                                    price = price + parseInt(item.qty);
                                                                })
                                                                return price;
                                                            }.call(this)
                                                        }.00</span>
                                                    </div>
                                                    <button className={'btn btn-primary mv-2'}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center"
                                                            }} onClick={() => {
                                                        convertPayment();
                                                        setOpen(!open)
                                                        setPaymentType('bitcoin')
                                                    }}>PAY VIA Bitcoin <BiBitcoin/>
                                                    </button>
                                                    <button className={'btn btn-primary'}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center"
                                                            }} onClick={() => {
                                                        convertPayment();
                                                        setOpen(!open)
                                                        setPaymentType('dogecoin')
                                                    }}>PAY VIA Dogecoin <SiDogecoin/>
                                                    </button>
                                                </>
                                        }

                                    </div>
                                </div>
                            </> :
                            <div className="no-cart-items">
                                <h3>No items found!</h3>
                            </div>
                    }

                </section>
            </div>
        </>
    );
}

export default Cart;