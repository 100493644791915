import {Outlet} from 'react-router-dom';
import {Menu} from "../menu/Menu";
import Footer from "../footer/Footer";
import SideBar from "../side_bar/SideBar";
import HomeLayout from "./HomeLayout";
import $ from "jquery";
import {RiMenu4Line} from "react-icons/ri";

export default function DashboardLayout() {

    // const navigation = useNavigate();
    // useEffect(() => {
    //     let userId = localStorage.getItem("UserId");
    //     if (userId === null || userId === undefined || userId === "") {
    //         navigation('/');
    //     }
    // }, [navigation]);
    return (
        <div className={'dashboardLayout'}>
            <SideBar/>
            <div className="d-container">
                <button className="btn-mobile-menu btn-dashboard" onClick={() => {
                    $('#side_panel').addClass('show');
                }}>
                    <RiMenu4Line/>
                </button>
                <Outlet/>
            </div>
        </div>
    )
}