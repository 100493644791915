import React, {useEffect, useState} from 'react';
import {Button, Modal, Popconfirm, Table, Tag} from "antd";
import {BiCheckDouble, BiTrash} from "react-icons/bi";
import {toast} from "react-toastify";
import API_LINK from "../../../components/link/Link";
import {SiProcessingfoundation} from "react-icons/si";
import {UploadOutlined} from "@ant-design/icons";
import $ from 'jquery';
import ImgsViewer from "react-images-viewer";
import GameData from "../../../components/games/GameData";

function Withdrawal() {
    const [depositData, setDepositData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('');
    const [proofImage, setProofImage] = useState('');
    const [isViewImage, setIsViewImage] = useState(false);

    // Withdrawal
    const [approveData, setApproveData] = useState(null);
    const [approveModal, setApproveModal] = useState(false);
    const [platforms, setPlatforms] = useState([]);
    const [platformName, setPlatformName] = useState('');
    const [withdrawalAmount, setWithdrawAmount] = useState('');
    const [accountDetail, setAccountDetail] = useState('');

    useEffect(() => {
        LoadData();
        LoadWithdrawalRequests();
    }, []);
    const LoadData = async () => {
        try {
            let formdata = new FormData();
            formdata.append("page", "LoadUserPlatform");
            formdata.append("userId", localStorage.getItem('UserId'));

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        setPlatforms(result.data);
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    const LoadWithdrawalRequests = async () => {
        try {
            let formdata = new FormData();
            if (localStorage.getItem("Role") === "Admin") {
                formdata.append("page", "LoadWithdrawal");
            } else {
                formdata.append("page", "LoadUserWithdrawal");
                formdata.append("userId", localStorage.getItem('UserId'));
            }

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        setDepositData(result.data);
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                })
                .catch(error => console.log('error', error));

        } catch (e) {
            console.log(e)
        }
    }

    async function handleSubmit(platform) {
        let toaster;
        let formdata = new FormData();
        toaster = toast.loading("Processing...");
        try {
            formdata.append("page", "SendWithdrawalRequest");
            formdata.append("userId", localStorage.getItem("UserId"));
            formdata.append("platform", JSON.stringify([platform]));
            formdata.append("amount", withdrawalAmount);
            formdata.append("accountDetail", accountDetail);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        setTimeout(() => {
                            toast.update(toaster, {
                                type: "success",
                                render: "Withdrawal request has been sent successfully!",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            LoadWithdrawalRequests();
                            setPlatformName("")
                            setWithdrawAmount("")
                            setAccountDetail("")
                        }, 1000)
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
            setOpen(false);
        } catch (e) {
            console.log(e)
        }
    }

    async function handleTableSubmit(u, status) {
        let toaster;
        let formdata = new FormData();
        toaster = toast.loading("Processing...");
        try {
            formdata.append("page", "ApproveWithdrawalRequest");
            formdata.append("userId", localStorage.getItem("UserId"));
            formdata.append("withdrawId", u.id);
            formdata.append("image", proofImage);
            formdata.append("email", u.Email);
            formdata.append("status", status);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        setTimeout(() => {
                            toast.update(toaster, {
                                type: "success",
                                render: "Withdrawal request status has been changed successfully!",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            LoadWithdrawalRequests();
                        }, 1000)
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
            setApproveModal(false);
        } catch (e) {
            console.log(e)
        }
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProofImage(reader.result)
            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <ImgsViewer
                imgs={[
                    {src: proofImage}
                ]}
                isOpen={isViewImage}
                onClose={() => setIsViewImage(false)}
            />
            <Modal
                title={<p>Withdrawal Request</p>}
                loading={loading}
                open={open}
                onOk={async () => {
                    let platform = platforms.filter((p) => p.Platform === platformName);
                    platform = platform[0];
                    platform = {id: platform.PlatformID, platform: platform.Platform};
                    handleSubmit(platform)
                }}
                okText={'Submit Request'}
                onCancel={() => setOpen(false)}
            >
                <div className="model-form">
                    <div className="form-group">
                        <label>Platform</label>
                        <select name="platform" id="platform" value={platformName}
                                onChange={(e) => {
                                    // console.log(e.target.value);
                                    setPlatformName(e.target.value)
                                }}>
                            <option value="" disabled selected>Select</option>
                            {
                                platforms.map((p, i) => {
                                    return (
                                        <option value={p.Platform}>{p.Platform}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Amount</label>
                        <input type="number" placeholder={'Enter Amount'} value={withdrawalAmount}
                               onChange={(e) => setWithdrawAmount(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Account Details</label>
                        <textarea name="accountDetail" id="accountDetail" value={accountDetail}
                                  onChange={event => setAccountDetail(event.target.value)}></textarea>
                    </div>
                </div>
            </Modal>
            <Modal
                title={<p>Approve Withdrawal</p>}
                loading={loading}
                open={approveModal}
                onOk={async () => {
                    handleTableSubmit(approveData, "Approved")
                }}
                okText={'Approve Now'}
                onCancel={() => setApproveModal(false)}
            >
                <div className="model-form">
                    <div className="form-group">
                        <label>Withdraw Proof</label>
                        <input type="file" onChange={handleFileChange}/>
                    </div>
                </div>
            </Modal>
            <div className="dashboard-content-container">
                <div className="dashboard-content">
                    <div className="dashboard-content-title"
                         style={{flexDirection: "row", justifyContent: "space-between"}}>
                        <h1>Withdrawal</h1>
                        {
                            localStorage.getItem("Role") === "User" &&
                            <button className={'btn btn-primary'} onClick={() => setOpen(true)}>Request</button>
                        }
                    </div>
                    <div className="dashboard-content-table">
                        <Table dataSource={depositData} pagination={{pageSize: 10}} loading={isLoading}
                               rowHoverable={true}>
                            <Table.Column title="#" width={70} render={(j, r, index) => {
                                return (
                                    <span>{index + 1}</span>
                                )
                            }}/>
                            {
                                localStorage.getItem("Role") === "Admin" &&
                                <>
                                    <Table.Column title="Username" dataIndex="UserName"/>
                                    <Table.Column title="Email Address" dataIndex="Email"/>
                                    <Table.Column title="Phone No." dataIndex="Phone"/>
                                </>
                            }
                            <Table.Column title="Platform" render={(d) => {
                                const platforms = JSON.parse(d.platform);
                                return (
                                    platforms.map((platform, index) => {
                                        return (
                                            <p style={{minWidth: 200}}
                                               key={index}>{platform.platform}</p>
                                        )
                                    })
                                )
                                // console.log(platforms)

                            }}/>
                            <Table.Column title="Platform ID" render={(d) => {
                                const platforms = JSON.parse(d.platform);
                                return (
                                    platforms.map((platform, index) => {
                                        return (
                                            <p key={index}>{platform.id ? platform.id : ""}</p>
                                        )
                                    })
                                )
                                // console.log(platforms)

                            }}/>
                            <Table.Column title="Total Price" render={(d) => {
                                return (
                                    <p>${d.totalAmount}</p>
                                )
                            }}/>
                            <Table.Column title="Account Details" render={(d) => {
                                return (
                                    <p>{d.AccountDetail}</p>
                                )
                            }}/>
                            <Table.Column title="Created At" dataIndex="Date"/>
                            <Table.Column title="Withdrawal Proof" render={(d) => {
                                if (d.Image) {
                                    return <a href="javascript:void(0)" onClick={() => {
                                        setProofImage(d.Image);
                                        setIsViewImage(true)
                                    }}><img
                                        src={d.Image} style={{width: 150, height: 80, objectFit: 'fill'}}/></a>
                                }
                            }}
                            />
                            <Table.Column title="Status" render={(u) => {
                                let color = "";
                                if (u.Status === "Pending") {
                                    color = "gray";
                                    return <Tag color={color}>
                                        {u.Status.toUpperCase()}
                                    </Tag>
                                } else if (u.Status === "Rejected") {
                                    color = "#ff0000";
                                    return <Tag color={color}>
                                        {u.Status.toUpperCase()}
                                    </Tag>
                                } else if (u.Status === "Approved") {
                                    color = "green";
                                    return <Tag color={color}>
                                        {u.Status.toUpperCase()}
                                    </Tag>
                                } else if (u.Status === "Processing") {
                                    color = "yellow";
                                    return <Tag color={color}>
                                        {u.Status.toUpperCase()}
                                    </Tag>
                                }
                            }}/>
                            {
                                localStorage.getItem("Role") === "Admin" &&
                                <>
                                    <Table.Column title="Approve" render={(u) => {
                                        return (
                                            <Button type={'primary'} icon={<BiCheckDouble width={15}/>}
                                                    onClick={() => {
                                                        setApproveData(u)
                                                        setApproveModal(true)
                                                        // handleSubmit(u, "Approved");
                                                    }}/>
                                        )
                                    }}/>
                                    <Table.Column title="Reject" render={(u) => {
                                        return (
                                            <Popconfirm
                                                title="Delete/Reject this user?"
                                                description="Are you sure to reject this withdraw?"
                                                onConfirm={() => {
                                                    handleTableSubmit(u, "Rejected");
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type={'primary'} danger icon={<BiTrash width={15}/>}/>
                                            </Popconfirm>

                                        )
                                    }}/>
                                </>
                            }
                        </Table>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Withdrawal;