import {NavLink} from "react-router-dom";

export default function GameCard({data}) {
    return (
        <div className="game-card-container" data-aos={'fade-up'}>
            <NavLink to={`${data.path}`} className="game-card">
                <div className="game-img">
                    <img src={data.image} alt=""/>
                </div>
                <div className="game-title">
                    <span>{data.name}</span>
                </div>
            </NavLink>
        </div>
    )
}