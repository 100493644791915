import {Route, Routes} from "react-router-dom";
import Login from "../../screens/interface/auth/login/Login";
import {Register} from "../../screens/interface/auth/register/Register";
import HomeLayout from "../layouts/HomeLayout";
import {Home} from "../../screens/interface/home/Home";
import {HowItWorks} from "../../screens/interface/how_it_works/HowItWorks";
import {Promotions} from "../../screens/interface/promotions/Promotions";
import ForgotPassword from "../../screens/interface/auth/forgot-password/ForgotPassword";
import Deposit from "../../screens/interface/deposit/Deposit";
import GameDetail from "../../screens/interface/game_detail/GameDetail";
import ReferralProgram from "../../screens/interface/referral-program/ReferralProgram";
import About from "../../screens/interface/about/About";
import {TermsConditions} from "../../screens/interface/documents/TermsConditions";
import {PrivacyPolicy} from "../../screens/interface/documents/PrivacyPolicy";
import {Faqs} from "../../screens/interface/faq/Faqs";
import Support from "../../screens/interface/support/Support";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../../screens/dashboard/dashboard/Dashboard";
import Profile from "../../screens/dashboard/profile/Profile";
import ChangePassword from "../../screens/dashboard/change-password/ChangePassword";
import Verifications from "../../screens/dashboard/verifications/Verifications";
import Messages from "../../screens/dashboard/messages/Messages";
import DashboardDeposit from "../../screens/dashboard/deposit/Deposit";
import Withdrawal from "../../screens/dashboard/withdrawal/Withdrawal";
import Cart from "../../screens/interface/cart/Cart";
import Logout from "../../screens/dashboard/logout/Logout";
import UploadPlatformId from "../../screens/dashboard/platforms/UploadPlatformID";

export const Navigation = () => {
    return (
        <Routes>
            {/*<Route path="/auth/login" element={<Login />} />*/}
            <Route element={<HomeLayout/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/:game" element={<GameDetail/>}/>
                <Route path="/how-it-works" element={<HowItWorks/>}/>
                <Route path="/promotions" element={<Promotions/>}/>
                <Route path="/deposit" element={<Deposit/>}/>
                <Route path="/cart" element={<Cart/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/register" element={<Register/>}/>
                {/*    */}
                <Route path="/referral-program" element={<ReferralProgram/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/terms-of-use" element={<TermsConditions/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/faq" element={<Faqs/>}/>
                <Route path="/support" element={<Support/>}/>
                <Route element={<DashboardLayout/>}>
                    <Route path="/user/dashboard" element={<Dashboard/>}/>
                    <Route path="/user/profile" element={<Profile/>}/>
                    <Route path="/user/change-password" element={<ChangePassword/>}/>
                    <Route path="/user/verifications" element={<Verifications/>}/>
                    <Route path="/user/messages" element={<Messages/>}/>
                    <Route path="/user/deposit" element={<DashboardDeposit/>}/>
                    <Route path="/user/withdrawals" element={<Withdrawal/>}/>
                    <Route path="/user/platforms" element={<UploadPlatformId/>}/>
                    <Route path="/user/logout" element={<Logout/>}/>
                </Route>
            </Route>

        </Routes>
    )
}
