import React from 'react';
import {NavLink} from "react-router-dom";

function ForgotPassword() {
    return (
        <div className={'container'}>
            <div className="auth-form-container">
                {/*<form>*/}
                <div className="auth-form">
                    <div className="auth-form-title">
                        <h1>Request Reset Password</h1>
                    </div>
                    <div className="auth-form-field">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" autoFocus tabIndex={1} name="email" placeholder="john@example.com"/>
                        </div>
                        <div className="form-group ">
                            <button className={'btn btn-primary mv-2'} tabIndex={3} type={'submit'}>Reset Password
                            </button>
                        </div>
                        <div className="form-group" style={{flexDirection: "row", alignItems: "center"}}>
                            <label>Already have an account?</label>&nbsp;&nbsp;
                            <NavLink to={'/login'}>Sign In</NavLink>
                        </div>
                    </div>
                </div>
                {/*</form>*/}
            </div>
        </div>
    );
}

export default ForgotPassword;
