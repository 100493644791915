import PromotoinData from "../../../components/promotions/PromotoinData";
import {PromotionCard} from "../../../components/cards/PromotionCard";

export const Promotions = () => {
    return (
        <div className="container">
            <div className="text-banner">
                <h1>Promotions</h1>
            </div>
            <section className="section-platforms" style={{background :"var(--bar_color)"}} data-aos={'fade-up'}>
                <div className="platforms-cards" style={{marginTop : 0}}>
                    {
                        PromotoinData.map((p, index) => {
                            return (
                                <PromotionCard data={p} key={index} title={true}/>
                            )
                        })
                    }
                </div>
            </section>
        </div>
    )
}
