import React from 'react';
import {HiArrowLongRight} from "react-icons/hi2";

function About() {
    const VIDEO_URL = () => (<iframe width="792" height="400"
                                     src="https://www.youtube.com/embed/CZK003ebtOk?si=eax6SiwLHV05FPxw"
                                     title="YouTube video player" frameborder="0"
                                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                     referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)

    return (
        <div className="container">
            <div className="text-banner">
                <h1>About Us</h1>
            </div>
            <section className="registration-section">
                <div className="col-50" data-aos={'fade-right'}>
                    <h1>Cloud9</h1>
                    <p>As one of the best online casino platforms, Cloud9 aims to provide a seamless gaming
                        experience for you by delivering the latest online casino games that you can play on both mobile
                        and desktop devices.</p>
                    <button className={'mv-2 btn btn-primary btn-icon'}>Register Now <HiArrowLongRight/></button>
                    <p><b>Cloud9</b> offers you a wide range of exciting sweepstakes titles ranging from fish, slot, and
                        table card games.Thus, you will have more than 300 bitcoin casino games to choose from.</p>
                    <p>All it takes is to create an online casino account at the Cloud9, deposit with either BTC,
                        DOGE or LTC and start spinning the best online slot games reels instantly.</p>
                    <p>We are trying to engage with our audience and meet their needs immediately. Therefore, 24/7
                        online customer service is available, and our team of professionals is waiting to fix all the
                        issues that might come up while using <b>Cloud9</b>.</p>
                </div>
                <div className="col-50" data-aos={'fade-left'}>
                    <VIDEO_URL/>
                </div>
            </section>
        </div>
    );
}

export default About;