import React from 'react';
import GameData from "../../../components/games/GameData";
import DepositCard from "../../../components/cards/DepositCard";

function Deposit() {
    return (
        <div className={'container'}>
            <div className="text-banner">
                <h1>Deposit</h1>
            </div>
            <section className="section-deposit">
                {
                    GameData.map((g, index) => (
                        <DepositCard data={g} key={index}/>
                    ))
                }
            </section>
        </div>
    );
}

export default Deposit;