import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import GameData from "../../../components/games/GameData";
import {BiChevronUp} from "react-icons/bi";
import {BsChevronDown, BsChevronUp} from "react-icons/bs";

function GameDetail() {
    const [gameData, setGameData] = useState(null); // Start with `null` to check for data
    const {game} = useParams();
    const [activeIndex, setActiveIndex] = useState(-1);
    const [hiddenIndices, setHiddenIndices] = useState([]);
    const toggleHide = (index) => {
        setHiddenIndices((prevHiddenIndices) => {
            if (prevHiddenIndices.includes(index)) {
                // Remove index if it already has the `hide` class
                return prevHiddenIndices.filter(i => i !== index);
            } else {
                // Add index if it doesn't have the `hide` class
                return [...prevHiddenIndices, index];
            }
        });
    };

    useEffect(() => {
        const GameDetail = GameData.find((g) => g.path === `/${game}`);
        setGameData(GameDetail); // Set `null` if GameDetail is not found
    }, [game]);

    return (
        <div className='container'>
            {
                gameData && gameData.sections ? (
                    <>
                        {
                            gameData.sections.map((section, index) => {
                                // Cycle counter from 1 to 3
                                const count = (index % 3) + 1;

                                // Choose content based on the current count
                                if (count === 1) {
                                    return (
                                        <div key={index} className={'section-game-1'}>
                                            <div className="section-content">
                                                <h2>{section.title}</h2>
                                                <div>{section.detail}</div>
                                            </div>
                                            <div className="section-image">
                                                <img src={section.image} alt="Section Image"/>
                                            </div>
                                        </div>
                                    );
                                } else if (count === 2) {
                                    return (
                                        <div key={index} className={'section-game-2'}>
                                            <div className="section-image">
                                                <img src={section.image} alt="Section Image"/>
                                            </div>
                                            <div className="section-content">
                                                <h2>{section.title}</h2>
                                                <div>{section.detail}</div>
                                            </div>
                                        </div>
                                    );
                                } else if (count === 3) {
                                    return (
                                        <div key={index} className={'section-game-3'}>
                                            <div className="section-content">
                                                <h2>{section.title}</h2>
                                                <div>{section.detail}</div>
                                            </div>
                                            <div className="section-image">
                                                <img src={section.image} alt="Section Image"/>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })
                        }

                        {
                            gameData && gameData.faqs.length > 0 &&
                            <div className={'faqs-container'}>
                                <div className="faq-title">
                                    <h1>FAQs</h1>
                                </div>
                                {
                                    gameData.faqs.map((faq, index) => (
                                        <div
                                            className={`faq-item ${hiddenIndices.includes(index) ? 'hide' : ''}`} // Apply `hide` class conditionally
                                            key={index}
                                            onClick={() => toggleHide(index)} // Toggle hide on click
                                        >
                                            <div className="faq-header">
                                                <span>{faq.title}</span>
                                                <button>
                                                    {
                                                        hiddenIndices.includes(index) ?
                                                            <BsChevronDown/> : <BsChevronUp/>
                                                    }

                                                </button>
                                            </div>
                                            <div className="faq-content">
                                                <p>
                                                    {faq.content}
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </>
                ) : (
                    <h1>No Record Found</h1>
                )
            }
        </div>
    );
}

export default GameDetail;
