export const TermsConditions = () => {
    return (
        <div className="container">
            <div className="text-banner">
                <h1>Terms Of Use</h1>
            </div>
            <section className="terms-conditions-section">
                <div className="css-1qxed09"><h4
                    className="chakra-heading css-1lh6x0m">Introduction</h4><p className="chakra-text css-0">The Terms
                    and Conditions written on this page shall manage your use of the website <b>Cloud9</b>.
                    <a className="chakra-link css-f4h6uy" href="/">https://cloud9.cc/</a>.</p>
                    <br/><p className="chakra-text css-0">The Terms and Conditions that are mentioned here will fully
                        impact your use of the website. By using our website, you accept the terms that are mentioned
                        here. If you disagree with any of the mentioned points, you should not use the website. Users
                        who are below 21 are not allowed to use this website.</p><h4
                        className="chakra-heading css-1saq518">Intellectual Property Rights</h4><p
                        className="chakra-text css-0">Under these Terms and Conditions, the intellectual property rights
                        for all the information and materials you can find on this website belong to <b>Cloud9</b> or
                        its licensors.
                    </p><br/><p className="chakra-text css-0">You only have a limited license for viewing the
                        information and materials available on the website.</p><h4
                        className="chakra-heading css-1lh6x0m">Registration and Account use</h4><p
                        className="chakra-text css-0">To use our services, you are obliged to create an account
                        on <b>Cloud9</b>. You must provide the
                        necessary information for the registration process. In case we detect any fake information on
                        user accounts, we have the right to block those users and stop providing services for them.</p>
                    <br/><p className="chakra-text css-0">You have complete responsibility for using your account and
                        maintaining confidentiality regarding the password that you have set. However, if you face some
                        issues regarding unauthorized access, you agree to notify us about it.</p><br/><p
                        className="chakra-text css-0">In addition to that, you agree to keep your contact information up
                        to date so that our team can contact you whenever it is necessary.</p><h4
                        className="chakra-heading css-1saq518">Fraud Prevention</h4>
                    <p className="chakra-text css-0">Cloud9 is a sweepstakes site that offers a variety of online
                        game platforms. By accepting and agreeing to the terms and conditions mentioned here, you agree
                        not to perform any fraudulent actions or cheat using Cloud9 or any available platforms. The
                        fraudulent actions might include but are not limited to:
                        <ul>
                            <li>Using additional tools to cheat the games</li>
                            <li>Using or creating gaming accounts that involve fake information or using the personal
                                data of other people
                            </li>
                            <li>Creating multiple accounts at Cloud9</li>
                        </ul>
                    </p>
                    <p>When we detect suspicious activity on your side, Cloud9 has the right to contact those users
                        and ask for additional information to verify the account. In case users do not cooperate and do
                        not provide necessary information when we detect fraudulent activity, Cloud9 has the right to
                        block the user immediately.</p><h4 className="chakra-heading css-1saq518">Payment</h4><p
                        className="chakra-text css-98urqk"><span>All the payments on <b>Cloud9</b> are accepted via Bitcoin. By agreeing to the terms and conditions written on this page, you accept that payments made with any other cryptocurrency will not be processed, and that is not the responsibility of <b>Cloud9</b>.</span>
                    </p><p className="chakra-text css-0">Cloud9
                        is not responsible for the payments that are not confirmed on the website, and by agreeing to
                        this document, you accept that those payments are not refundable by Cloud9. That means
                        Cloud9 will not process any payment until the user completes it and we receive it.</p><h4
                        className="chakra-heading css-1saq518">Restrictions</h4><p className="chakra-text css-0">You are
                        restricted from below mentioned actions while using the site</p>
                    <ul role="list" className="css-tu0njr">
                        <li className="css-0">Publishing the website content or any other material on another platform
                        </li>
                        <li className="css-0">Commercializing as well as selling website material</li>
                        <li className="css-0">Publicly showing or performing any website material</li>
                        <li className="css-0">Using the website in a manner that can potentially damage this website
                        </li>
                        <li className="css-0">Using this website in a manner that can negatively impact the user's
                            access to this website
                        </li>
                        <li className="css-0">Using this website in a manner that can potentially harm other person or
                            business entity
                        </li>
                        <li className="css-0">Using this website in a manner that is contrary to local/international
                            laws and regulations
                        </li>
                        <li className="css-0">Engaging in activities such as data harvesting, data mining, data
                            extracting, or any other similar action
                        </li>
                        <li className="css-0">Using this website for marketing purposes</li>
                    </ul>
                    <p className="chakra-text css-0">In absolute discretion, Cloud9 has the right to restrict access
                        for users to particular sections of the website whenever necessary. Your password and private
                        data are absolutely confidential, and we urge you to maintain confidentiality.</p><h4
                        className="chakra-heading css-1saq518">Your Content</h4><p
                        className="chakra-text css-0">According to the Website's Terms and Conditions, "Your Content"
                        defines as any image, audio, video text, or other material that you choose to show on this
                        website. By sharing "your content" with the website, you grant access to Cloud9 to use,
                        publish, translate, reproduce and distribute all that media. "Your content" must belong to you
                        and should not invade any third party's property rights. Cloud9 has the right to remove any
                        of your content whenever necessary without notice.</p><h4
                        className="chakra-heading css-1saq518">No warranties</h4><p className="chakra-text css-0">This
                        website is provided "as is” which means that <b>Cloud9</b> expresses no
                        warranties for any content or material that the website contains. Nothing that is displayed on
                        this website should be interpreted as advice.</p><h4
                        className="chakra-heading css-1saq518">Limitation of liability</h4><p
                        className="chakra-text css-0">Cloud9, nor its officers, directors, and employees, cannot be
                        held liable for any event that is connected or arose by your use of this website Cloud9.</p>
                    <h4 className="chakra-heading css-1saq518">Indemnification</h4><p className="chakra-text css-0">You
                        accept to compensate for any or all costs, causes of action, liabilities, damages, and expenses
                        that arose from your actions regarding the breach of mentioned provisions of these Terms.</p><h4
                        className="chakra-heading css-1saq518">Severability</h4><p className="chakra-text css-0">Under
                        any applicable law, if any of these provisions mentioned on this page are found to be invalid,
                        those provisions should be deleted without affecting the remaining ones.</p><h4
                        className="chakra-heading css-1saq518">Variation of Terms</h4><p className="chakra-text css-0">
                        <b>Cloud9</b> has the right to
                        change these Terms whenever necessary, and by using this website, you agree to review them
                        frequently.</p><h4 className="chakra-heading css-1saq518">Assignment</h4><p
                        className="chakra-text css-0">Without prior notice, <b>Cloud9</b> may assign, transfer,
                        or subcontract its rights or obligations under these terms. However, any of your rights or
                        duties under these terms may not be assigned, transferred, or subcontracted.</p><h4
                        className="chakra-heading css-1saq518">Entire Agreement</h4><p
                        className="chakra-text css-0">These Terms replace all earlier agreements and understandings
                        between <b>Cloud9</b> and you
                        in connection to your use of this Website.</p><h4
                        className="chakra-heading css-1saq518">Governing Law &amp; Jurisdiction</h4><p
                        className="chakra-text css-0">These terms will be interpreted and governed by the local laws of
                        the State of the US.</p></div>
            </section>
        </div>
    )
}
