import {Outlet} from 'react-router-dom';
import {Menu} from "../menu/Menu";
import Footer from "../footer/Footer";
import {useEffect, useState} from "react";
import PromotionModal from "../modal/PromotionModal";

export default function HomeLayout() {

    // const navigation = useNavigate();
    // useEffect(() => {
    //     let userId = localStorage.getItem("UserId");
    //     if (userId === null || userId === undefined || userId === "") {
    //         navigation('/');
    //     }
    // }, [navigation]);
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 2000)
    }, []);

    return (
        <>
            <PromotionModal isVisible={isVisible} setIsVisible={setIsVisible}/>
            <div className={'dark_container'}>
                <Menu/>
                <main>
                    <Outlet/>
                </main>
                <Footer/>
            </div>
        </>
    )
}
