// export const HowItWorks = () => {
//     const VIDEO_URL = () => (<iframe width="560" height="315"
//         src="https://www.youtube.com/embed/iw9EjH0_mdU?si=i3wmG_HEO6v8VzTe"
//         title="YouTube video player" frameborder="0"
//         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//         referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>)
//     return (
//         <div className="container">
//             <div className="text-banner" data-aos={'fade-up'}>
//                 <h1>How It Works</h1>
//             </div>
//             <section className="registration-section">
//                 <div className="col-50" data-aos={'fade-right'}>
//                     <h1>Registration</h1>
//                     <p>Before exploring the wide variety of platforms to play the best online casino games, you’ll need to sign up on Cloud9. To begin, click on the "Sign Up" button. You’ll then be directed to the registration page, where you’ll need to provide personal information, including your full name, email, phone number, a photo of your ID card (front and back), a password, and a selfie holding your ID card. Ensure that all uploaded photos are clear and legible before submitting.</p>
//                     <p>Once you’ve completed the registration form, you’ll receive a confirmation email from us within 24 hours. During this time, our team will review your details and complete the verification process. Your account status will remain pending until approval. After receiving the confirmation email, you can log into your Cloud9 account and select your preferred casino platform from the "Platforms" tab to start playing!</p>
//                 </div>
//                 <div className="col-50" data-aos={'fade-left'}>
//                     <VIDEO_URL />
//                 </div>
//             </section>

//             <section className="deposit-section">
//                 <div className="col-50" data-aos={'fade-right'}>
//                     <VIDEO_URL />
//                 </div>
//                 <div className="col-50" data-aos={'fade-left'}>
//                     <h1>Deposit</h1>
//                     <p>After successfully completing the registration process, your next step is to fund your gaming account. To do so, you’ll need Bitcoin, as it’s the only payment method accepted on Cloud9. Start by clicking on the "Deposit" button on the website. Then, select the platform you’d like to play on and the amount you wish to deposit. Please note, the minimum deposit is $10.</p>
//                     <p>Next, head to the "Cart" and click the "Proceed to Checkout" button. You’ll be able to see the deposit amount and any bonuses you’ve earned in your casino account. Proceed to the next page to finalize your payment by clicking on the "Pay with Bitcoin" button. Be sure to include your email address to ensure the transaction completes successfully. You can either copy and paste your Bitcoin wallet address into the deposit tab or scan the QR code to complete the payment.</p>
//                     <p>Once you’ve completed this step, simply wait for the transaction to be confirmed on the blockchain. Depending on the transaction fee you’ve paid, it may take up to 12 hours for the deposit to be reflected in your online casino account, with lower fees potentially causing longer processing times. Once confirmed, the amount will appear in your Cloud9 casino account.</p>
//                 </div>
//             </section>

//             <section className="withdrawal-section">
//     <div className="col-50" data-aos={'fade-right'}>
//         <h1>Withdrawal</h1>
//         <p className="chakra-text css-pj1t83">With our newly streamlined withdrawal system, taking out your winnings has never been easier! Enjoy faster processing times and a more user-friendly experience, making withdrawals more efficient than ever before.</p>
//         <p className="chakra-text css-vkvjc2">Follow these simple steps:</p>
//         <ol role="list" className="css-15srhg3">
//             <li className="css-2catvg"><span className="chakra-text css-l1xabu">Click “Withdrawal” on the menu:</span> You’ll be redirected to the withdrawal page.</li>
//             <li className="css-2catvg"><span className="chakra-text css-l1xabu">Add your Bitcoin wallet address:</span> Ensure your Bitcoin wallet address is linked to your account. If not, add it before proceeding.</li>
//             <li className="css-2catvg"><span className="chakra-text css-l1xabu">Enter details:</span> Select the platform and the amount you wish to withdraw.</li>
//             <li className="css-2catvg"><span className="chakra-text css-l1xabu">Get paid:</span> Once approved, your withdrawal will be processed and sent to your wallet within 8 hours.</li>
//         </ol>
//     </div>
//     <div className="col-50" data-aos={'fade-left'}>
//         <VIDEO_URL />
//     </div>
// </section>

//         </div>
//     )
// }
export const HowItWorks = () => {
    const Video = ({ url }) => (
        <iframe
            width="560"
            height="315"
            src={url}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        ></iframe>
    );

    return (
        <div className="container">
            <div className="text-banner" data-aos={'fade-up'}>
                <h1>How It Works</h1>
            </div>

            <section className="registration-section">
                <div className="col-50" data-aos={'fade-right'}>
                    <h1>Registration</h1>
                    <p>
                        Before exploring the wide variety of platforms to play the best online casino games, you’ll need to sign up on Cloud9. To begin, click on the "Sign Up" button. You’ll then be directed to the registration page, where you’ll need to provide personal information, including your full name, email, phone number, a photo of your ID card (front and back), a password, and a selfie holding your ID card. Ensure that all uploaded photos are clear and legible before submitting.
                    </p>
                    <p>
                        Once you’ve completed the registration form, you’ll receive a confirmation email from us within 24 hours. During this time, our team will review your details and complete the verification process. Your account status will remain pending until approval. After receiving the confirmation email, you can log into your Cloud9 account and select your preferred casino platform from the "Platforms" tab to start playing!
                    </p>
                </div>
                <div className="col-50" data-aos={'fade-left'}>
                    <Video url="https://www.youtube.com/embed/OOcsX6jyzPk?si=fUFcDnT434MO30uQ" />
                </div>
            </section>

            <section className="deposit-section">
                <div className="col-50" data-aos={'fade-right'}>
                    <Video url="https://www.youtube.com/embed/iw9EjH0_mdU?si=i3wmG_HEO6v8VzTe" />
                </div>
                <div className="col-50" data-aos={'fade-left'}>
                    <h1>Deposit</h1>
                    <p>
                        After successfully completing the registration process, your next step is to fund your gaming account. To do so, you’ll need Bitcoin, as it’s the only payment method accepted on Cloud9. Start by clicking on the "Deposit" button on the website. Then, select the platform you’d like to play on and the amount you wish to deposit. Please note, the minimum deposit is $10.
                    </p>
                    <p>
                        Next, head to the "Cart" and click the "Proceed to Checkout" button. You’ll be able to see the deposit amount and any bonuses you’ve earned in your casino account. Proceed to the next page to finalize your payment by clicking on the "Pay with Bitcoin" button. Be sure to include your email address to ensure the transaction completes successfully. You can either copy and paste your Bitcoin wallet address into the deposit tab or scan the QR code to complete the payment.
                    </p>
                    <p>
                        Once you’ve completed this step, simply wait for the transaction to be confirmed on the blockchain. Depending on the transaction fee you’ve paid, it may take up to 12 hours for the deposit to be reflected in your online casino account, with lower fees potentially causing longer processing times. Once confirmed, the amount will appear in your Cloud9 casino account.
                    </p>
                </div>
            </section>

            <section className="withdrawal-section">
                <div className="col-50" data-aos={'fade-right'}>
                    <h1>Withdrawal</h1>
                    <p>
                        With our newly streamlined withdrawal system, taking out your winnings has never been easier! Enjoy faster processing times and a more user-friendly experience, making withdrawals more efficient than ever before.
                    </p>
                    <p>Follow these simple steps:</p>
                    <ol>
                        <li>Click “Withdrawal” on the menu: You’ll be redirected to the withdrawal page.</li>
                        <li>Add your Bitcoin wallet address: Ensure your Bitcoin wallet address is linked to your account. If not, add it before proceeding.</li>
                        <li>Enter details: Select the platform and the amount you wish to withdraw.</li>
                        <li>Get paid: Once approved, your withdrawal will be processed and sent to your wallet within 8 hours.</li>
                    </ol>
                </div>
                <div className="col-50" data-aos={'fade-left'}>
                    <Video url="https://www.youtube.com/embed/iw9EjH0_mdU?si=i3wmG_HEO6v8VzTe" />
                </div>
            </section>
        </div>
    );
};
