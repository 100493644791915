// import React from 'react';

// function Dashboard() {
//     return (
//         <div className="dashboard-content-container">
//             <div className="dashboard-content">
//                 <div className="dashboard-content-info">
//                     <span>Welcome Back,</span>
//                     <h2>{localStorage.getItem("UserName")}</h2>
//                 </div>
//                 {
//                     localStorage.getItem("Role") === "User" &&
//                     <>
//                         <div className="dashboard-content-cards">
//                             <div className="dashboard-content-card">
//                                 <span>Total Withdrawal</span>
//                                 <h2>${localStorage.getItem("Withdraw")}.00</h2>
//                                 <small>Total Withdrawals You Made</small>
//                             </div>
//                             <div className="dashboard-content-card">
//                                 <span>Total Deposit</span>
//                                 <h2>${localStorage.getItem("Deposit")}.00</h2>
//                                 <small>Total Deposit You Made</small>
//                             </div>
//                         </div>
//                         <div className="dashboard-content-user-level-cards">
//                             <div className="dashboard-content-user-level-card active">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 1</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 2</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 3</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 4</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 5</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 6</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 7</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 8</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 9</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 10</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 11</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 12</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                             <div className="dashboard-content-user-level-card">
//                                 <div className="dashboard-content-user-level-card-content">
//                                     <h1>LEVEL 13</h1>
//                                     <span>Deposit: $0-9</span>
//                                     <span>Daily withdrawal limit: $25</span>
//                                     <span>Deposit Bonus: 0.00%</span>
//                                 </div>
//                             </div>
//                         </div>
//                     </>
//                 }

//             </div>
//         </div>
//     );
// }

// export default Dashboard;

import React, { useEffect } from 'react';

function Dashboard() {
    useEffect(() => {
        // Add Tawk.to script to the document head
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = 'https://embed.tawk.to/67376dae2480f5b4f59ea8d9/1ico88su9';
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        document.head.appendChild(script);
        
        return () => {
            // Clean up the script when the component is unmounted
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content">
                <div className="dashboard-content-info">
                    <span>Welcome Back,</span>
                    <h2>{localStorage.getItem("UserName")}</h2>
                </div>
                {
                    localStorage.getItem("Role") === "User" &&
                    <>
                        <div className="dashboard-content-cards">
                            <div className="dashboard-content-card">
                                <span>Total Withdrawal</span>
                                <h2>${localStorage.getItem("Withdraw")}.00</h2>
                                <small>Total Withdrawals You Made</small>
                            </div>
                            <div className="dashboard-content-card">
                                <span>Total Deposit</span>
                                <h2>${localStorage.getItem("Deposit")}.00</h2>
                                <small>Total Deposit You Made</small>
                            </div>
                        </div>
                        <div className="dashboard-content-user-level-cards">
                            {/* Repeat for all user levels */}
                            <div className="dashboard-content-user-level-card">
                                <div className="dashboard-content-user-level-card-content">
                                    <h1>LEVEL 1</h1>
                                    <span>Deposit: $0-9</span>
                                    <span>Daily withdrawal limit: $25</span>
                                    <span>Deposit Bonus: 0.00%</span>
                                </div>
                            </div>
                            {/* Other levels */}
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default Dashboard;
