import React from 'react';
import {HiArrowLongRight} from "react-icons/hi2";
import {REFERRAL_PROGRAM} from "../../../assets/images/Images";

function ReferralProgram() {
    return (
        <div className="container">
            <div className="text-banner" data-aos={'fade-up'}>
                <h1>Referral Program</h1>
                <p>Cloud9 is an online casino platform that offers you a wide range of exciting bonuses. By utilzing
                    those bonuses, you might as well get an edge over the competition and have a profitable gaming
                    experience. One of those exceptional offers is a Referral Bonus which can help you and your friends
                    earn $10 for free.</p>
            </div>
            <section className="registration-section">
                <div className="col-50" data-aos={'fade-left'}>
                    <img src={REFERRAL_PROGRAM} alt="Referral Program"/>
                </div>
                <div className="col-50" data-aos={'fade-right'}>
                    <h1>How to Claim Referral Bonus?</h1>
                    <p>In order to activate the referral bonus, you need to copy the provided link by Cloud9 and send
                        it to your friends. As your friend use that special link, register on Cloud9, and deposit at
                        least $20 to the gaming account, you and your friend will benefit from a referral bonus. As a
                        reward, you and your referred friend will get $10 for free.</p>
                    <p>The best part about this program is that there is no limitation in terms of the number of people
                        you can refer to. So, start earning from now and invite your friends to Cloud9 so that they
                        can also enjoy a wide range of slot games and other casino genres available on the platform.</p>
                    <button className={'btn btn-primary btn-icon mv-2'}>Get Your Referral Code <HiArrowLongRight/>
                    </button>
                </div>

            </section>
        </div>
    );
}

export default ReferralProgram;