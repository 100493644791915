import React, {useState} from 'react';
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import $ from "jquery";
import {toast} from "react-toastify";
import API_LINK from "../../../components/link/Link";

function ChangePassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPassword, setIsPassword] = useState(false);
    const [isConfirmPassword, setIsConfirmPassword] = useState(false);

    function handleSubmit(event) {
        try {
            event.preventDefault();
            event.stopPropagation();
            if (password !== confirmPassword) {
                toast.warning("Password and Confirm Password must be same.");
                return;
            }
            if (password.length < 6) {
                toast.warning("Password length should be greater than 6 digits.");
                return;
            }
            let toaster;
            var form = $('.auth-form');
            let formdata = new FormData();

            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            formdata.append("page", "UpdateProfilePassword");
            formdata.append("userId", localStorage.getItem("UserId"))
            formdata.append("password", password)
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${API_LINK}Authentication.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if (result.status === 1) {
                        toast.update(toaster, {
                            type: "success",
                            render: "Account password has been changed & updated successfully.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        localStorage.setItem("Password", password)
                        form.find("button").attr("disabled", false);
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content">
                <form onSubmit={handleSubmit}>
                    <div className="profile-form">
                        <div className="profile-form-title">
                            <h2>Change Password</h2>
                        </div>
                        <div className="form-group-row" style={{marginTop: '30px'}}>
                            <div className="form-group">
                                <label>New Password</label>
                                <input type={isPassword ? "text" : "password"} value={password} required
                                       onChange={event => setPassword(event.target.value)}/>
                                <button className='btn_password' onClick={() => setIsPassword(!isPassword)}>
                                    {
                                        isPassword ?
                                            <EyeInvisibleOutlined/> :
                                            <EyeOutlined/>
                                    }
                                </button>
                            </div>
                            <div className="form-group">
                                <label>Confirm Password</label>
                                <input type={isConfirmPassword ? "text" : "password"} value={confirmPassword} required
                                       onChange={event => setConfirmPassword(event.target.value)}/>
                                <button className='btn_password'
                                        onClick={() => setIsConfirmPassword(!isConfirmPassword)}>
                                    {
                                        isConfirmPassword ?
                                            <EyeInvisibleOutlined/> :
                                            <EyeOutlined/>
                                    }
                                </button>
                            </div>
                            <div className="form-group mv-2">
                                <button className={'btn btn-primary'}>Change Password</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChangePassword;