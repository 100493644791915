import {Testimonial_Icon} from "../../assets/images/Images";

export const TestimonialCard = ({data}) => {
    return (
        <div className="testimonial-card-container" data-aos={'fade-up'}>
            <div className="testimonial-card">
                <div className="testimonial-card-header">
                    <img src={Testimonial_Icon} alt="Icon"/>
                </div>
                <div className="testimonial-card-content">
                    <p>{data.content}</p>
                </div>
                <div className="testimonial-card-footer">
                    <img src={data.image} alt="User Image"/>
                    <div>
                        <span>{data.title}</span>
                        <small>{data.slug}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}
