import React from 'react';
import {PhoneOutlined} from "@ant-design/icons";

function Support() {
    return (
        <div className={'container'}>
            <div className="text-banner">
                <h1>Support</h1>
            </div>
            <div className="section-support">
                <div className="col-50">
                    <h1>Do you need help?</h1>
                    <p>Our support experts are here to help you. You can check our Resource page to see if you can find answer to your questions or you can just fill in the form and we will contact you regarding your question.</p>
                    <p>Business time: Monday-Sunday 12pm-06pm (PST). It is advisable to have your ID number with you for a quick solution <a
                        href="tel:+13235270880"><PhoneOutlined /> +1 323 527 0880</a></p>

                </div>
                <div className="col-50">
                    <form>
                        <div className="support-form">
                            <div className="support-form-row">
                                <div className="form-group form-group-50">
                                    <label>First Name <code>*</code></label>
                                    <input type="text" name={'firstName'} required/>
                                </div>
                                <div className="form-group form-group-50">
                                    <label>Last Name <code>*</code></label>
                                    <input type="text" name={'lastName'} required/>
                                </div>
                            </div>
                            <div className="support-form-row">
                                <div className="form-group">
                                    <label>Email <code>*</code></label>
                                    <input type="email" name={'email'} required/>
                                </div>
                            </div>
                            <div className="support-form-row">
                                <div className="form-group form-group-50">
                                    <label>Phone <code>*</code></label>
                                    <input type="number" name={'phone'} required/>
                                </div>
                                <div className="form-group form-group-50">
                                    <label>Subject <code>*</code></label>
                                    <input type="text" name={'subject'} required/>
                                </div>
                            </div>
                            <div className="support-form-row">
                                <div className="form-group">
                                    <label>Message <code>*</code></label>
                                    <textarea name="message" id="message" rows="5" required></textarea>
                                </div>
                            </div>
                            <div className="support-form-row">
                                <button className={'btn btn-primary'}>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Support;