import React, {useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import $ from "jquery";
import {toast} from "react-toastify";
import API_LINK from "../../../../components/link/Link";

function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    function handleSubmit(event) {
        try {
            event.preventDefault();
            event.stopPropagation();
            let toaster;
            var form = $('.auth-form');
            let formdata = new FormData();

            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            formdata.append("page", "UserLogin");
            formdata.append("email", email)
            formdata.append("password", password)
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${API_LINK}Authentication.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            let UserData = result.data[0];
                            console.log(UserData);
                            if (UserData.Status === "Approved") {
                                toast.dismiss(toaster);
                                localStorage.setItem("UserId", UserData.id)
                                localStorage.setItem("FirstName", UserData.FirstName)
                                localStorage.setItem("LastName", UserData.LastName)
                                localStorage.setItem("UserName", UserData.UserName)
                                localStorage.setItem("Email", UserData.Email)
                                localStorage.setItem("Phone", UserData.Phone)
                                localStorage.setItem("DOB", UserData.DOB)
                                localStorage.setItem("Deposit", UserData.Deposit)
                                localStorage.setItem("Withdraw", UserData.Withdraw)
                                localStorage.setItem("IDCard", UserData.IDCard)
                                localStorage.setItem("Password", UserData.Password)
                                localStorage.setItem("Status", UserData.Status)
                                localStorage.setItem("Role", UserData.Role)
                                form.find("button").attr("disabled", false);
                                navigate("/user/dashboard", {replace: true});
                            } else {
                                form.find("button").attr("disabled", false);
                                toast.update(toaster, {
                                    type: "info",
                                    render: "Your account is under process please wait until our team verify your account.",
                                    isLoading: false,
                                    autoClose: toaster,
                                    pauseOnHover: false,
                                    pauseOnFocusLoss: false,
                                    closeButton: true,
                                    closeOnClick: true,
                                })
                            }

                        } else {
                            form.find("button").attr("disabled", false);
                            toast.update(toaster, {
                                type: "info",
                                render: "Please check your credentials. No such record found!",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        }
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={'container'}>
            <div className="auth-form-container">

                <div className="auth-form">
                    <form>
                        <div className="auth-form-title">
                            <h1>Login</h1>
                        </div>
                        <div className="auth-form-field">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" autoFocus tabIndex={1} name="email"
                                       placeholder="john@example.com"
                                       required value={email} onChange={event => setEmail(event.target.value)}/>
                            </div>
                            <div className="form-group">
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <label>Password</label>
                                    {/*<NavLink to={'/forgot-password'}>Forgot Password</NavLink>*/}
                                </div>
                                <input type="password" name="password" tabIndex={2} placeholder="Password" required
                                       value={password} onChange={event => setPassword(event.target.value)}
                                />
                            </div>
                            <div className="form-group ">
                                <button onClick={handleSubmit} className={'btn btn-primary mv-2'}
                                        tabIndex={3} type={'submit'}>Sign In
                                </button>
                            </div>
                            <div className="form-group" style={{flexDirection: "row", alignItems: "center"}}>
                                <label>Do not have an account?</label>&nbsp;&nbsp;
                                <NavLink to={'/register'}>Register Now</NavLink>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
