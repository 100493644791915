import React, {useEffect, useState} from 'react';
import {Button, Popconfirm, Table, Tag} from "antd";
import $ from "jquery";
import {toast} from "react-toastify";
import API_LINK from "../../../components/link/Link";
import {BiCheckDouble, BiEdit, BiTrash} from "react-icons/bi";

function Verifications() {
    const [usersData, setUsersData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        LoadData();
    }, []);
    const LoadData = async () => {
        // if (localStorage.getItem('Role') === "Admin") {
        try {
            setIsLoading(true)
            let formdata = new FormData();
            if (localStorage.getItem('Role') === "Admin") {
                formdata.append("page", "LoadUsers");
            } else {
                formdata.append("page", "LoadUserForVerification");
                formdata.append("userId", localStorage.getItem('UserId'));
            }

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Users.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if (result.status === 1) {
                        setUsersData(result.data);
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                })
                .catch(error => console.log('error', error));
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
        // }
    }


    async function handleSubmit(user, status) {
        let toaster;
        let formdata = new FormData();
        toaster = toast.loading("Processing...");
        try {
            formdata.append("page", "UpdateUserStatus");
            formdata.append("userId", user.id);
            formdata.append("status", status);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Users.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        setTimeout(() => {
                            toast.update(toaster, {
                                type: "success",
                                render: "User status has been changed successfully.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            LoadData();
                        }, 1000)
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content">
                <div className="dashboard-content-title">
                    <h1>Verifications</h1>
                    <small>Please submit the required documents in order to get your identity verified. Unverified
                        accounts do not get the signup bonus and cannot make any deposit or withdrawal.</small>
                </div>
                <div className="dashboard-content-table">
                    <Table dataSource={usersData} pagination={{pageSize: 10}} loading={isLoading}
                           rowHoverable={true}>
                        <Table.Column title="#" width={70} render={(j, r, index) => {
                            return (
                                <span>{index + 1}</span>
                            )
                        }}/>
                        <Table.Column title="FirstName" dataIndex="FirstName"/>
                        <Table.Column title="LastName" dataIndex="LastName"/>
                        <Table.Column title="Email" dataIndex="Email"/>
                        <Table.Column title="Phone" dataIndex="Phone"/>
                        <Table.Column title="IDCard" dataIndex="IDCard"/>
                        {
                            localStorage.getItem("Role") === "Admin" &&
                            <>
                                <Table.Column title="ID Card Front" render={(u) => {
                                    return (
                                        <img src={u.IDCardFront} alt=""
                                             style={{width: 150, height: 80, objectFit: "fill"}}/>
                                    )
                                }}/>
                                <Table.Column title="ID Card Back" render={(u) => {
                                    return (
                                        <img src={u.IDCardBack} alt=""
                                             style={{width: 150, height: 80, objectFit: "fill"}}/>
                                    )
                                }}/>
                            </>
                        }
                        <Table.Column title="Created At" dataIndex="Date"/>
                        <Table.Column title="Status" render={(u) => {
                            let color = "";
                            if (u.Status === "Pending") {
                                color = "gray";
                                return <Tag color={color}>
                                    {u.Status.toUpperCase()}
                                </Tag>
                            } else if (u.Status === "Rejected") {
                                color = "#ff0000";
                                return <Tag color={color}>
                                    {u.Status.toUpperCase()}
                                </Tag>
                            } else if (u.Status === "Approved") {
                                color = "green";
                                return <Tag color={color}>
                                    {u.Status.toUpperCase()}
                                </Tag>
                            }
                        }}/>
                        {
                            localStorage.getItem("Role") === "Admin" &&
                            <>
                                <Table.Column title="Approve" render={(u) => {
                                    return (
                                        <Button type={'primary'} icon={<BiCheckDouble width={15}/>} onClick={() => {
                                            handleSubmit(u, "Approved");
                                        }}/>
                                    )
                                }}/>
                                <Table.Column title="Reject" render={(u) => {
                                    return (
                                        <Popconfirm
                                            title="Delete/Reject this user?"
                                            description="Are you sure to delete/reject this user?"
                                            onConfirm={() => {
                                                handleSubmit(u, "Rejected");
                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type={'primary'} danger icon={<BiTrash width={15}/>}/>
                                        </Popconfirm>

                                    )
                                }}/>
                            </>
                        }
                    </Table>
                </div>
            </div>
        </div>

    );
}

export default Verifications;