import React, {useEffect, useState} from 'react';
import {Button, Modal, Popconfirm, Table, Tag} from "antd";
import {BiCheckDouble, BiTrash} from "react-icons/bi";
import {toast} from "react-toastify";
import API_LINK from "../../../components/link/Link";
import {SiProcessingfoundation} from "react-icons/si";
import {UploadOutlined} from "@ant-design/icons";
import $ from 'jquery';
import ImgsViewer from "react-images-viewer";

function DashboardDeposit() {
    const [depositData, setDepositData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [platformId, setPlatformId] = useState([]);
    const [order, setOrder] = useState('');
    const [proofImage, setProofImage] = useState('');
    const [isViewImage, setIsViewImage] = useState(false);

    useEffect(() => {
        LoadData();
    }, []);
    const LoadData = async () => {
        try {
            setIsLoading(true)
            let formdata = new FormData();
            if (localStorage.getItem('Role') === "Admin") {
                formdata.append("page", "LoadOrders");
            } else {
                formdata.append("page", "LoadUserOrders");
                formdata.append("userId", localStorage.getItem('UserId'));
            }

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    // console.log(result);
                    if (result.status === 1) {
                        setDepositData(result.data);
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                })
                .catch(error => console.log('error', error));
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    async function handleSubmit(user, status) {
        let toaster;
        let formdata = new FormData();
        toaster = toast.loading("Processing...");
        try {
            formdata.append("page", "UpdateOrderStatus");
            formdata.append("id", user.id);
            formdata.append("userId", user.userId);
            formdata.append("amount", user.totalAmount);
            formdata.append("status", status);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        setTimeout(() => {
                            toast.update(toaster, {
                                type: "success",
                                render: "Order status has been changed successfully.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            LoadData();
                        }, 1000)
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    async function handleUploadIDs(newData, id) {
        let toaster;
        let formdata = new FormData();
        toaster = toast.loading("Processing...");
        try {
            formdata.append("page", "UpdateOrderPlatforms");
            formdata.append("id", id);
            formdata.append("platforms", newData.platforms);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 1) {
                        setTimeout(() => {
                            toast.update(toaster, {
                                type: "success",
                                render: "Platform IDs has been uploaded successfully.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            LoadData();
                        }, 1000)
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <ImgsViewer
                imgs={[
                    {src: proofImage}
                ]}
                isOpen={isViewImage}
                onClose={() => setIsViewImage(false)}
            />
            <Modal
                title={<p>Upload Platforms IDs</p>}
                loading={loading}
                open={open}
                onOk={async () => {
                    const filteredData = depositData.filter((item) => item.id === order.id)
                    let platformData = JSON.parse(filteredData[0].platforms);
                    $('.model-form input').each(function () {
                        let platformId = $(this).parent('div').data("platform");
                        // console.log(platformId);
                        let newPlatform = platformData.filter((item) => item.id === platformId)
                        newPlatform[0].platformID = $(this).val();
                        const platformIndex = platformData.findIndex((item) => item.id === platformId);
                        platformData[platformIndex] = newPlatform[0];
                    })
                    filteredData[0].platforms = JSON.stringify(platformData);
                    // console.log(filteredData[0]);
                    let updatedDepositData = [...depositData];
                    // const index = updatedDepositData.findIndex((item) => item.id === order.id);
                    // console.log(index)
                    // if (index !== -1) {
                    //     updatedDepositData[index] = filteredData[0];
                    // }
                    updatedDepositData = depositData.map((item) =>
                        item.id === order.id ? filteredData[0] : item
                    );
                    setOpen(false);
                    await handleUploadIDs(filteredData[0], order.id);
                    setDepositData(updatedDepositData);
                }}
                okText={'Upload'}
                onCancel={() => setOpen(false)}
            >
                <div className="model-form">
                    {
                        (function () {
                            if (order) {
                                let platforms = JSON.parse(order.platforms);
                                // console.log(platforms);
                                return platforms.map((p, index) => (
                                    <div className={'form-group'} key={index} data-platform={p.id}>
                                        <label>{p.name}</label>
                                        <input type="text" onChange={(e) => {
                                            // const filteredData = depositData.filter((item) => item.id === order.id)
                                            // let newPlatform = JSON.parse(filteredData[0].platforms);
                                            // newPlatform = newPlatform.filter((item) => item.id === p.id)
                                            // newPlatform[0].platformID = e.target.value;
                                            // const updatedDepositData = depositData.map((item) =>
                                            //     item.id === order.id ? filteredData : item
                                            // );
                                        }}/>
                                    </div>
                                ));
                            } else {
                                return "";
                            }
                        })()
                    }
                </div>
            </Modal>
            <div className="dashboard-content-container">
                <div className="dashboard-content">
                    <div className="dashboard-content-title">
                        <h1>Deposits</h1>
                    </div>
                    <div className="dashboard-content-table">
                        <Table dataSource={depositData} pagination={{pageSize: 10}} loading={isLoading}
                               rowHoverable={true}>
                            <Table.Column title="#" width={70} render={(j, r, index) => {
                                return (
                                    <span>{index + 1}</span>
                                )
                            }}/>
                            {
                                localStorage.getItem("Role") === "Admin" &&
                                <>
                                    <Table.Column title="Username" dataIndex="UserName"/>
                                    <Table.Column title="Email Address" dataIndex="Email"/>
                                    <Table.Column title="Phone No." dataIndex="Phone"/>
                                </>
                            }
                            <Table.Column title="Platforms" render={(d) => {
                                const platforms = JSON.parse(d.platforms);
                                return (
                                    platforms.map((platform, index) => {
                                        return (
                                            <p style={{minWidth: 200}}
                                               key={index}>{index + 1}- {platform.name} ({platform.qty})</p>
                                        )
                                    })
                                )
                                // console.log(platforms)

                            }}/>
                            <Table.Column title="Platform IDs" render={(d) => {
                                const platforms = JSON.parse(d.platforms);
                                return (
                                    platforms.map((platform, index) => {
                                        return (
                                            <p key={index}>{platform.platformID ? platform.platformID : ""}</p>
                                        )
                                    })
                                )
                                // console.log(platforms)

                            }}/>
                            <Table.Column title="Total Price" render={(d) => {
                                return (
                                    <p>${d.totalAmount}</p>
                                )
                            }}/>
                            <Table.Column title="Created At" dataIndex="Date"/>
                            <Table.Column title="Payment Proof" render={(d) => {
                                return <a href="javascript:void(0)" onClick={() => {
                                    setProofImage(d.Image);
                                    setIsViewImage(true)
                                }}><img
                                    src={d.Image} style={{width: 150, height: 80, objectFit: 'fill'}}/></a>
                            }}/>
                            <Table.Column title="Status" render={(u) => {
                                let color = "";
                                if (u.Status === "Pending") {
                                    color = "gray";
                                    return <Tag color={color}>
                                        {u.Status.toUpperCase()}
                                    </Tag>
                                } else if (u.Status === "Rejected") {
                                    color = "#ff0000";
                                    return <Tag color={color}>
                                        {u.Status.toUpperCase()}
                                    </Tag>
                                } else if (u.Status === "Approved") {
                                    color = "green";
                                    return <Tag color={color}>
                                        {u.Status.toUpperCase()}
                                    </Tag>
                                } else if (u.Status === "Processing") {
                                    color = "yellow";
                                    return <Tag color={color}>
                                        {u.Status.toUpperCase()}
                                    </Tag>
                                }
                            }}/>
                            {
                                localStorage.getItem("Role") === "Admin" &&
                                <>
                                    <Table.Column title="Processing" render={(u) => {
                                        return (
                                            <Button type={'primary'} icon={<SiProcessingfoundation width={15}/>}
                                                    onClick={() => {
                                                        handleSubmit(u, "Processing");
                                                    }}/>
                                        )
                                    }}/>
                                    <Table.Column title="Approve" render={(u) => {
                                        return (
                                            <Button type={'primary'} icon={<BiCheckDouble width={15}/>}
                                                    onClick={() => {
                                                        handleSubmit(u, "Approved");
                                                    }}/>
                                        )
                                    }}/>
                                    <Table.Column title="Reject" render={(u) => {
                                        return (
                                            <Popconfirm
                                                title="Delete/Reject this user?"
                                                description="Are you sure to delete/reject this user?"
                                                onConfirm={() => {
                                                    handleSubmit(u, "Rejected");
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type={'primary'} danger icon={<BiTrash width={15}/>}/>
                                            </Popconfirm>

                                        )
                                    }}/>
                                </>
                            }
                            {/*{*/}
                            {/*    localStorage.getItem("Role") === "User" &&*/}
                            {/*    <>*/}
                            {/*        <Table.Column title="Upload IDs" render={(u) => {*/}
                            {/*            return (*/}
                            {/*                <Button type={'primary'} icon={<UploadOutlined width={15}/>}*/}
                            {/*                        onClick={() => {*/}
                            {/*                            setLoading(true);*/}
                            {/*                            setOrder(u)*/}
                            {/*                            setOpen(!open)*/}
                            {/*                            setTimeout(() => {*/}
                            {/*                                setLoading(false)*/}
                            {/*                            }, 1000)*/}

                            {/*                        }}/>*/}
                            {/*            )*/}
                            {/*        }}/>*/}
                            {/*    </>*/}
                            {/*}*/}
                        </Table>
                    </div>
                </div>
            </div>
        </>

    );
}

export default DashboardDeposit;