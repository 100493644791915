import {
    eGame, EGame1,
    EGame2,
    EGame3,
    EGame4,
    EGame5,
    EGame6,
    EGame7,
    EGame8,
    EGame9,
    EGame10,
    ice81,
    ice82,
    ice83,
    ice84,
    ice85,
    ice86,
    ice87,
    ice88,
    ice89,
    GoldenTressure,
    GoldHunter,
    Ice8,
    PandaMaster,
    Rivers,
    Skillmine,
    UltraPower,
    rivermonster,
    admiral,
    ice7,
    gameroom,
    taichi,
    kingofpop,
    ultrapanda,
    vegassweeps,
    ultraPower0,
    ultraPower1,
    ultraPower2,
    ultraPower3,
    ultraPower4,
    ultraPower5,
    ultraPower6,
    ultraPower7,
    ultraPower8,
    ultraPower9,
    ultraPower10,
    pandaMaster0,
    pandaMaster1,
    pandaMaster2,
    pandaMaster3,
    pandaMaster4,
    pandaMaster5,
    goldHunter0,
    goldHunter1,
    goldHunter2,
    goldHunter3,
    goldHunter4,
    goldHunter5,
    goldHunter6,
    goldHunter7,
    goldHunter8,
    riverSweeps0,
    riverSweeps1,
    riverSweeps2,
    riverSweeps3,
    riverSweeps4,
    riverSweeps5,
    riverSweeps6,
    riverSweeps7,
    riverSweeps8,
    goldenTreasure0,
    goldenTreasure1,
    goldenTreasure2,
    goldenTreasure3,
    goldenTreasure4,
    goldenTreasure5,
    goldenTreasure6,
    goldenTreasure7,
    goldenTreasure8,
    goldenTreasure9,
    goldenTreasure10,
    goldenTreasure11,
    skillmine0,
    skillmine1,
    skillmine2,
    skillmine3,
    skillmine4,
    skillmine5,
    skillmine6,
    skillmine7,
    skillmine8,
    skillmine9,
    skillmine10,
    skillmine11,
    skillmine12,
    kingpop0,
    kingpop1,
    kingpop2,
    kingpop3,
    kingpop4,
    kingpop5,
    kingpop6,
    kingpop7,
    vegasSweeps0,
    vegasSweeps1,
    vegasSweeps2,
    vegasSweeps3,
    vegasSweeps4,
    vegasSweeps5,
    vegasSweeps6,
    vegasSweeps7,
    vegasSweeps8,
    vegasSweeps9,
    vegasSweeps10,
    vegasSweeps11,
    riverMonster0,
    riverMonster1,
    riverMonster2,
    riverMonster3,
    riverMonster4,
    riverMonster5,
    riverMonster6,
    riverMonster7,
    riverMonster8,
    riverMonster9,
    gameRoom0,
    gameRoom1,
    gameRoom2,
    gameRoom3,
    gameRoom4,
    gameRoom5,
    gameRoom6,
    gameRoom7,
    gameRoom8,
    gameRoom9,
    ultraPanda0,
    ultraPanda1,
    ultraPanda2,
    ultraPanda3,
    ultraPanda4,
    ultraPanda5,
    ultraPanda6,
    ultraPanda7,
    ultraPanda8,
    ultraPanda9,
    ultraPanda10,
    admiral0,
    admiral1,
    admiral2,
    admiral3,
    admiral4,
    admiral5,
    admiral6,
    admiral7,
    admiral8,
    admiral9,
    admiral10,
    ice70,
    ice71,
    ice72,
    ice73,
    ice74,
    ice75,
    taichi0,
    taichi1,
    taichi2,
    taichi3,
    taichi4,
    taichi5,
    taichi6,
    taichi7,
    taichi8,
    gameVault0,
    gameVault1,
    gameVault2,
    gameVault3,
    gameVault4,
    gameVault5,
    gameVault6,
    gameVault7,
    gameVault8,
    gameVault9,
    gameVault10,
    gameVault11,
    fireMaster0,
    fireMaster1,
    fireMaster2,
    fireMaster3,
    fireMaster4,
    fireMaster5,
    fireMaster6,
    vegasX0,
    vegasX1,
    vegasX2,
    vegasX3,
    vegasX4,
    vegasX5,
    vegasX6,
    vegasX7,
    vegasX8,
    flamingo0,
    flamingo1,
    flamingo2,
    flamingo3,
    flamingo4,
    flamingo5,
    flamingo6,
    flamingo7,
    flamingo8,
    flamingo9,
    flamingo10,
    flamingo11,
    flamingo12,
    flamingo13,
    flamingo14,
    winStar0,
    winStar1,
    winStar2,
    winStar3,
    winStar4,
    winStar5,
    winStar6,
    mafia0,
    mafia1,
    mafia2,
    mafia3,
    mafia4,
    mafia5,
    mafia6,
    mafia7,
    goldenDragon0,
    goldenDragon1,
    goldenDragon2,
    goldenDragon3,
    goldenDragon4,
    goldenDragon5,
    goldenDragon6,
    goldenDragon7,
    goldenDragon8,
    goldenDragon9,
    goldenDragon10,
    goldenDragon11,
    goldenDragon12,
    vegas70,
    vegas71,
    vegas72,
    vegas73,
    vegas74,
    vegas75,
    vegas76,
    vegas77,
    vegas78,
    vBlink0,
    vBlink1,
    vBlink2,
    vBlink3,
    vBlink4,
    vBlink5,
    vBlink6,
    vBlink7,
    vBlink8,
    vBlink9,
    vBlink10,
    vegasVolts0,
    vegasVolts1,
    vegasVolts2,
    vegasVolts3,
    vegasVolts4,
    vegasVolts5,
    vegasVolts6,
    vegasVolts7,
    aceBook0,
    aceBook1,
    aceBook2,
    aceBook3,
    aceBook4,
    aceBook5,
    aceBook6,
    aceBook7,
    aceBook8,
    luckyStar0,
    luckyStar1,
    luckyStar2,
    luckyStar3,
    luckyStar4,
    luckyStar5,
    luckyStar6,
    luckyStar7,
    redPlay0,
    redPlay1,
    redPlay2,
    redPlay3,
    redPlay4,
    redPlay5,
    redPlay6,
    redPlay7,
    redPlay8,
    redPlay9,
    redPlay10,
    redPlay11,
    redPlay12,
    redPlay13,
    redPlay14,
    milkyWay0,
    milkyWay1,
    milkyWay2,
    milkyWay3,
    milkyWay4,
    milkyWay5,
    milkyWay6,
    milkyWay7,
    orionStar0,
    orionStar1,
    orionStar2,
    orionStar3,
    orionStar4,
    orionStar5,
    orionStar6,
    orionStar7,
    orionStar8,
    fireKirin0,
    fireKirin1,
    fireKirin2,
    fireKirin3,
    fireKirin4,
    fireKirin5,
    fireKirin6,
    fireKirin7,
    fireKirin8,
    fireKirin9,
    juwa0,
    juwa1,
    juwa2,
    juwa3,
    juwa4,
    juwa5,
    juwa6,
    juwa7,
    juwa8,
    juwa9,
    cashMachine1,
    cashMachine2,
    cashMachine3,
    cashMachine4,
    cashMachine5,
    blueDragon0,
    blueDragon1,
    blueDragon2,
    blueDragon3,
    blueDragon4,
    blueDragon5,
    blueDragon6,
    blueDragon7,
    blueDragon8,
    blueDragon9,
    blueDragon10,
    winstar,
    vblink,
    blueDragon,
    redPlay,
    milkyWay,
    vegasX,
    juwa,
    fireMaster,
    ultraPanda,
    aceBook,
    riversweeps,
    cashMachine0,
    fireKirin,
    gameVault,
    goldenTreasure,
    goldenDragon,
    mafia,
    luckyStar,
    vegas7,
    orionStar,
    vegasVolts,
    cashMachine, flamingo,
} from "../../assets/images/Images";

const GameData = [
    {
        id: 1,
        path: "/e-game",
        name: "E Game",
        image: eGame,
        sections: [
            {
                title: "E game",
                detail: <>
                    <p>Cloud9 Casino is incredible. From the moment you enter the casino, you’re destined to enjoy every aspect of the platform.</p>
                    <p>The website is flawless and works perfectly on any device. Play anywhere, anytime, and enjoy online gaming whenever you desire.</p>
                    <p>Beyond the amazing game catalog and stunning visual effects, you’ll find the platform secure. With Cloud9, you never have to worry about your personal details or funds. Just pick your favorite game and focus on the excitement!</p>

                </>,
                image: EGame1,
            },
            {
                title: "E Games Casino A Premium Gaming Library",
                detail: <>
                    <p>If you're a complete beginner and haven't found your favorite genre yet, or if you enjoy exploring various game genres and prefer to keep your options open, Cloud9 Casino is the perfect place to begin!</p>
                    <p>This unique gaming platform offers up to 50 captivating games across multiple genres. From slots to <a href="#" target="_blank">fish arcade games</a>, keno, and many more classic table games, there’s plenty to enjoy.</p>
                    <p>These are some of the top-rated games at the Cloud9 online casino. Plus, you can access some of the <a href="#" target="_blank">highest RTP slots</a> available in the industry right here!</p>

                </>,
                image: EGame2,
            },
            {
                title: "Crab Defenders",
                detail: <>
                    <p>If you enjoy fish shooting games and want to explore a vibrant underwater world, you can’t miss <b>Crab Defenders</b>. This dynamic fish table game features a tank full of 30 unique sea creatures!</p>
                    <p>Each species moves at different speeds, sizes, and directions, and your goal is to target and defeat the larger creatures to earn the most points. Keep in mind, the bigger the fish, the higher the points, but also the more challenging it is to take down.</p>
                    <p><b>Crab Defenders</b> arcade game comes equipped with advanced weaponry and various bullets, giving you the best aiming and shooting options. Learning the game's mechanics will help you choose the most effective weapons for different species.</p>
                    <p>You can also use the Cloud9 Casino iPhone app to play <b>Crab Defenders</b> on the go!</p>

                </>,
                image: EGame3
            },
            {
                title: "Smooth and Effortless Gaming Experience at Cloud9 Casino",
                detail: <>
                    <p>EGame Casino offers exceptional quality, and it’s no surprise that the platform is refined to perfection!</p>
                    <p>From the moment you visit the site, you’ll notice how user-friendly the platform is. Navigating through pages and games is seamless, with no lag, ensuring you can focus on enjoying the games without interruptions.</p>
                    <p>Another important factor that allows you to fully immerse in your favorite games is the platform’s security. Cloud9 has all the necessary licenses and permissions, ensuring the safety of your data and transactions.</p>
                    <p>Whether you’re registering, depositing, or withdrawing funds, you can trust that your personal details are protected. Simply enjoy the game and have fun!</p>

                </>,
                image: EGame4
            },
            {
                title: "Excellent Customer Support",
                detail: <>
                    <p>E Games Casino offers exceptional quality, and it’s no surprise that the platform is refined to perfection!</p>
                    <p>From the moment you visit the site, you’ll notice how user-friendly the platform is. Navigating through pages and games is seamless, with no lag, ensuring you can focus on enjoying the games without interruptions.</p>
                    <p>Another important factor that allows you to fully immerse in your favorite games is the platform’s security. E Games Casino has all the necessary licenses and permissions, ensuring the safety of your data and transactions.</p>
                    <p>Whether you’re registering, depositing, or withdrawing funds, you can trust that your personal details are protected. Simply enjoy the game and have fun!</p>

                </>,
                image: EGame5
            },
            {
                title: "High-Definition Graphics and Breathtaking Visuals",
                detail: <>
                    <p>E Games Casino guarantees a mind-blowing experience. The premium graphics in every corner of the platform will leave you in awe. And that's just the start!</p>
                    <p>Each game on the platform features stunning visuals and immersive sound effects. All you need to do is sit back and enjoy the show.</p>
                    <p>No matter which game you pick, you’ll be mesmerized by its visual beauty. So, get ready for an eye-popping experience!</p>

                </>,
                image: EGame6
            },
            {
                title: "Seamless Mobile Experience",
                detail: <>
                    <p>For the ultimate gaming experience, playing whenever you want is essential. E Games Casino ensures you get exactly what you deserve!</p>
                    <p>The E Games Casino app is available for any device you own. Whether you’re on the go or relaxing at home, you can enjoy your favorite games with the app. No matter your preferred gaming device, you’ll experience the most authentic online gaming adventure!</p>
                    <p>The best part is that when you install the E Games Casino app, you’ll enjoy the same stunning visual quality as the desktop version!</p>

                </>,
                image: EGame7
            },
            {
                title: "Unlock E Games Casino Bonuses for a Rewarding Gaming Journey",
                detail: <>
                    <p>Bonuses and promotions are your best friends while gaming. That’s why E Games Casino ensures you get plenty of them to enhance your experience on the platform!</p>
                    <p>From the moment you join E Games Casino, you’ll find numerous promotional opportunities waiting for you. Enjoy a generous welcome bonus, deposit match bonuses, and even a referral program that lets you invite friends to join the fun.</p>
                    <p>Moreover, the more loyal you are to the platform, the more bonuses and cashback rewards will come your way.</p>
                    <p>So, download the E Games Casino app for Android, start playing, and win even more rewards!</p>

                </>,
                image: EGame8
            },
            {
                title: "Get Egame Casino",
                detail: <>
                    <p>The E Games Casino download process takes only a few minutes, but in return, it grants you access to unique coupon codes, exclusive offers, and interactive sweepstakes games. Start the process now to enjoy these amazing benefits.</p>
                    <p>By downloading the E Games Casino app, you can enjoy the best mobile-optimized casino games anytime, anywhere. If you have any questions about the download process, feel free to reach out to the support team—they’ll assist you promptly.</p>

                </>,
                image: EGame9,
            },
            {
                title: "Get E Game Casino for Android",
                detail: <>
                    <p>As an Android user, nothing should stand in the way of your mobile gaming experience. With the E Games Casino app for Android, you can enjoy your favorite games right on your mobile device.</p>
                    <p>Downloading the app is simple and straightforward. Just visit E Games Casino, download the app, and you're ready to go! If you have any questions, feel free to reach out to customer support for assistance.</p>

                </>,
                image: EGame10,
            },
            {
                title: "Get E Game Casino for iPhone",
                detail: <>
                    <p>The E Games Casino download for iPhone lets you enjoy a variety of games with ease. The app is simple to download and install, and if you encounter any issues, customer support is ready to help you.</p>
                    <p>By playing through the app, you can boost your chances of winning with special bonuses exclusive to mobile users. Join now and start enjoying numerous bonuses that increase your chances of winning cash prizes!</p>

                </>,
                image: EGame4,
            }
        ],
        faqs: [
            {
                title: "What is EGame Casino?",
                content: "EGame Casino is a sweepstakes platform that lets you enter and play a wide range of exciting casino games for the chance to win big instantly."
            },
            {
                title: "How do I play at EGame Casino?",
                content: "To start playing at EGame Casino, simply visit Cloud9, register an account, and select the EGame platform to begin enjoying the games."
            },
            {
                title: "How can I win on the EGame app?",
                content: "Winning strategies vary depending on the game. For example, if you're playing fish tables, learning techniques like the 'mustache' method can significantly improve your odds of winning."
            },
            {
                title: "How do I register on EGame?",
                content: "To register on EGame, first visit Cloud9, sign up, and select EGame as your platform. Afterward, customer support will create a gaming account for you, and you'll be able to enjoy the fish tables."
            },
            {
                title: "How do I add funds to my EGame account?",
                content: "To deposit money into your EGame account, visit Cloud9, click on the deposit button, select EGame, specify your desired deposit amount, and enter your credentials to complete the process."
            },
            {
                title: "How do I download EGame Casino for Android?",
                content: "To download EGame Casino for Android, first register on Cloud9. Then, choose EGame from the available platforms, deposit funds into your account, and contact customer support for the download link. Click the link to complete the installation."
            },
            {
                title: "How do I download EGame Casino for iPhone?",
                content: "Downloading EGame Casino for iPhone is simple. Register on Cloud9, deposit funds into your account, and contact customer support for the download link. Follow the link to complete the installation on your iPhone."
            },
            {
                title: "Is EGame Online Casino legal?",
                content: "Yes, EGame Casino is fully legal. The platform holds all necessary licenses to operate as a sweepstakes business, ensuring a safe and legitimate gaming experience."
            },
            {
                title: "Is EGame safe?",
                content: "Yes, EGame Casino is secure. The platform uses the latest encryption technology to protect users from scams and data breaches, ensuring a safe environment for all players."
            },
            {
                title: "Can I download EGame Casino for free?",
                content: "Yes, EGame Casino is free to download, and you can start playing immediately without any initial costs."
            }
        ]
        
    },
    {
        id: 2,
        path: "/ice8",
        name: "Ice 8",
        image: Ice8,
        sections: [
            {
                title: "Welcome to Ice 8",
                detail: <>
                    <p>Ice 8 is an online casino crafted for players who seek an exciting gaming adventure. The platform offers huge bonuses, top-tier games, and a user-friendly interface that both novices and seasoned players will appreciate.&nbsp;</p>
                    <p>With the Ice 8 download, you can enjoy the best games on your mobile device, featuring stunning graphics and immersive gameplay while winning real cash prizes.&nbsp;</p>
                    <p>If you're an avid player craving excitement and real rewards, don’t hesitate to explore this casino platform.<br />
                    &nbsp;</p>
                </>,
                image: ice81,
            },
            {
                title: "Outstanding Customer Support",
                detail: <>
                    <p>Ice 8 Online Casino stands out for its excellent support team, a key feature of the platform. You can rely on their dedicated support staff whenever you encounter an issue. Additionally, if you have any account-related questions or concerns, a customer service representative will assist you.&nbsp;</p>
                    <p>Customer service is always available through live chat, email, or phone, ensuring prompt responses across all channels.&nbsp;<br />
                    &nbsp;</p>
                </>,
                image: ice82,
            },
            {
                title: "All Ways Fruits Slot",
                detail: <>
                    <p>All Ways Fruits features five reels, three rows, and 243 paylines. It’s a colorful, classic pokie game filled with fruity symbols.&nbsp;</p>
                    <p>Though based on a vintage theme, this game includes modern elements like multipliers and free spins. It’s also optimized for mobile devices, allowing you to enjoy it on Android or iOS.&nbsp;</p>
                    <p>As with most fruit pokies, you can risk your winnings to double them. A successful guess on the next card's color can double your rewards.&nbsp;</p>
                    <p>Log in to your Ice 8 casino account and start playing now!&nbsp;<br />
                    &nbsp;</p>
                </>,
                image: ice83
            },
            {
                title: "Interactive Sweepstakes Games for Real Money",
                detail: <>
                    <p>Ice 8 offers a wide variety of interactive sweepstakes games, from online slots to arcade fish games that you can play at any time.&nbsp;</p>
                    <p>All games undergo fairness testing by third-party auditors before they’re added to the platform.</p>
                    <p>Enjoy a fantastic selection of sweepstakes games, including titles like&nbsp;<a href="#" target="_blank">Buffalo Slots</a>. You can also use the&nbsp;<strong>Ice 8 Casino no deposit bonus</strong> to enjoy additional games for free.&nbsp;</p>
                    <p>Games like All Ways Fruits, BlackJack, Book of Fortune, and Dolphin’s Pearl Deluxe are available. Remember, you can also play these games on your mobile with the Ice 8 download!&nbsp;</p>
                </>,
                image: ice84
            },
            {
                title: "A Safe and Secure Casino Experience",
                detail: <>
                    <p>Security is a top priority when selecting an online casino, and Ice 8 Casino offers a completely secure platform. Players can enjoy their experience without worrying about security issues.&nbsp;</p>
                    <p>The Ice 8 casino apk is entirely risk-free, ensuring a safe gaming environment for everyone.&nbsp;</p>
                    <p>With stringent security measures in place, you can play the top Ice 8 slots without fearing for your personal information. Player safety and fairness are the platform's primary concerns.&nbsp;</p>
                    <p>Enjoy the best games on Ice 8 without any worries about your data security.&nbsp;<br />
                    &nbsp;</p>
                </>,
                image: ice85
            },
            {
                title: "Download Ice 8 for On-the-Go Fun",
                detail: <>
                    <p>The Ice 8 download is perfect for those who want to enjoy their favorite games on the move. It’s a quick, lightweight download with minimal storage requirements on your mobile device.&nbsp;</p>
                    <p>By downloading the app, you’ll gain access to all bonuses, including exclusive&nbsp;<a href="#" target="_blank">no deposit bonus codes</a>&nbsp;to maximize your winning potential. It’s the easiest way to enjoy top-tier gaming from anywhere.</p>
                    <p>Sign up today, log in with the Ice 8 casino login, and start your gaming adventure!&nbsp;</p>
                </>,
                image: ice86
            },
            {
                title: "Book of Ra Deluxe Slot",
                detail: <>
                    <p>Book of Ra Deluxe is a slot machine game with three rows and five reels. At the beginning, you can activate any of the nine available paylines. The aesthetic evokes the mystery of ancient Egypt, enhancing the gaming experience.&nbsp;</p>
                    <p>While it lacks a fast spin feature, the AutoPlay option offers smooth gameplay. The free spins feature can be triggered multiple times for added rewards.&nbsp;</p>
                    <p>With the Gamble function, you can increase your winnings up to five times. When it comes to bonus games, Book of Ra Deluxe delivers the ultimate thrill.<br />
                    &nbsp;</p>
                </>,
                image: ice87
            },
            {
                title: "First Deposit Match Bonus",
                detail: <>
                    <p>At Ice 8 Online Casino, your gaming experience is enhanced by fantastic bonuses. After signing up, you’re eligible for the first deposit match bonus. To activate this offer, simply make a deposit, and you’ll receive 50% extra credits, depending on your deposit amount.<br />
                    &nbsp;</p>
                </>,
                image: ice88
            },
            {
                title: "Second and Third Deposit Bonuses",
                detail: <>
                    <p>After claiming your first deposit match bonus, you can earn even more with your second and third deposits. Each deposit will get you an additional 20% bonus on your wagered amount.</p>
                    <p>Even if you play on your mobile using the Ice 8 casino apk, you can still enjoy these great bonuses. Sign up today and enjoy the best gaming experience!&nbsp;<br />
                    &nbsp;</p>
                </>,
                image: ice89
            },
            {
                title: "Instant Withdrawals at Ice 8 Casino",
                detail: <>
                    <p>With Ice 8, you can withdraw your rewards instantly as soon as you win real cash prizes while playing the best slots. Simply click the withdrawal button, enter the amount you wish to withdraw, and complete your request.</p>
                    <p>You can easily withdraw your winnings through the Ice 8 casino apk while playing on your mobile device. If you need assistance, customer support is available to help at any time.&nbsp;<br />
                    &nbsp;</p>
                </>,
                image: ice83
            },
            {
                title: "Birthday Bonus – 50% Free Credits",
                detail: <>
                    <p>On your special day, Ice 8 Casino has a birthday surprise waiting for you. Simply log in on your birthday, make a deposit, and receive a 50% bonus.</p>
                    <p>There are no special requirements for the birthday bonus—just play Ice 8 on your birthday to claim this offer!&nbsp;<br />
                    &nbsp;</p>
                </>,
                image: ice85
            },
        ],
        faqs: [
            {
                title: "How do I get free credit on Ice 8?",
                content: "First, create an account if you're a new player on Ice 8 and choose it as your preferred platform. After registration, you can use your welcome bonus on any game, with no deposit bonus codes available to boost your chances of winning."
            },
            {
                title: "Is Ice 8 a legitimate casino?",
                content: "Yes, Ice 8 is fully legitimate, with valid gaming licenses and top-tier security to protect all user data and transactions. You can make deposits and withdrawals with complete confidence."
            },
            {
                title: "How can I increase my chances of winning on Ice 8?",
                content: "Begin by playing free games to practice your skills before risking real money. You can also use the Ice 8 no deposit bonus to try your luck with real prizes."
            },
            {
                title: "What are the best games on Ice 8 Casino?",
                content: "Ice 8 features great games like Book of Ra, God of Wealth, Buffalo Slots, and many more. Every game offers fantastic graphics and exciting bonus features."
            },
            {
                title: "What is Ice 8 Casino?",
                content: "Ice 8 is a premier online casino where you can play premium games with amazing bonuses and promotions. You can even enjoy real money prizes without depositing using the Ice 8 no deposit bonus."
            },
            {
                title: "How do I register at Ice 8?",
                content: "To sign up, visit the Ice 8 platform and click 'Sign Up.' Provide your personal details, choose your platform, verify your identity, and complete the registration. The support team is available to assist you if needed."
            },
            {
                title: "How do I deposit money into my Ice 8 Casino account?",
                content: "Log in to Ice 8, click on 'Transactions' and then 'Deposit.' Select your deposit amount, confirm, and follow the steps to complete the payment via your preferred method."
            },
        ]
    },
    
    {
        id: 3,
        path: "/ultra-power",
        name: "Ultra Power",
        image: UltraPower,
        sections: [
            {
                title: "About UltraPower",
                detail: <>
                    <p>UltraPower Casino is a well-established brand that has honed its expertise over time. Their experience has allowed them to craft a user-friendly and engaging online casino platform that caters to all players.</p>
                    <p>The platform utilizes one of the best casino software available to ensure a safe and secure gaming environment, allowing players to deposit funds and enjoy top-notch sweepstakes games without any concerns.</p>
                    <p>Additionally, UltraPower Casino offers round-the-clock customer support to assist with any challenges that may arise, ensuring quick resolutions when you need them most.</p>
                </>,
                image: ultraPower0,
            },
            {
                title: "Exciting Games at UltraPower Casino",
                detail: <>
                    <p>UltraPower Casino offers a variety of real money slots, including <a href="#" target="_blank">free slot games to play</a>. Additionally, they provide exclusive UltraPower free play options and bonuses, along with high-quality visuals and immersive sound effects.</p>
                    <p>Whether you prefer fish games or slot games, UltraPower Casino offers a wide selection of gaming genres to keep you entertained.</p>
                    <p>You can also download the UltraPower casino apk to enjoy your favorite games on mobile devices, compatible with both Android and iPhone. This way, you can play anywhere and anytime you wish!</p>
                    <p>Choose UltraPower Casino if you want to <a href="#" target="_blank">play casino games online for real money</a>!</p>
                </>,
                image: ultraPower1,
            },
            {
                title: "Cutting-Edge Technology",
                detail: <>
                    <p>UltraPower Games utilizes only the best and most up-to-date technology, tested by reputable organizations before being released to the public.</p>
                    <p>The software provides high-definition visuals and immersive audio that will captivate you for hours, with unique storylines and settings that add to the thrill.</p>
                    <p>When selecting a reliable online casino, the quality of software is crucial. Your gaming experience can be affected based on the platform, and ensuring your winnings are secure is essential.</p>
                    <p>At UltraPower Casino, you can be assured of top-tier service and a seamless experience every time you play.</p>
                </>,
                image: ultraPower2,
            },
            {
                title: "Reliable Customer Support",
                detail: <>
                    <p>One of the biggest challenges players face is the lack of support when issues arise. However, UltraPower’s customer support is always ready to assist you.</p>
                    <p>They quickly identify the source of any problems and provide effective solutions so you can get back to enjoying your games.</p>
                </>,
                image: ultraPower3,
            },
            {
                title: "Download UltraPower Casino for Mobile",
                detail: <>
                    <p>The UltraPower Casino download provides a thrilling mobile gaming experience. Play all your favorite UltraPower slots on the go, with a small app file size that won’t take up much storage.</p>
                    <p>The mobile app also offers access to exclusive bonuses and promotions, ensuring you never miss a chance to boost your winnings. If you encounter any issues, customer support is easily reachable through the app.</p>
                    <p>So, if you want the best place to enjoy fun and win cash rewards on the go, consider downloading the UltraPower casino app!</p>
                </>,
                image: ultraPower4,
            },
            {
                title: "Exclusive Bonuses Await You",
                detail: <>
                    <p>Don't settle for a casino experience without bonuses. At UltraPower, playing your favorite games earns you exclusive bonuses.</p>
                    <p>Ensure you follow the simple rules to qualify for these bonuses, designed to enhance your gaming experience and maximize your fun.</p>
                    <p>You can also take advantage of UltraPower’s free play to try different games and increase your chances of winning with the added bonuses.</p>
                </>,
                image: ultraPower5,
            },
            {
                title: "Smooth Gameplay Experience",
                detail: <>
                    <p>UltraPower Games delivers smooth and enjoyable gameplay, with eye-catching visuals and top-notch mechanics designed for your comfort.</p>
                    <p>Whether you're a beginner or an experienced player, the games are designed to provide endless entertainment while offering you the opportunity to win big.</p>
                    <p>With an intuitive interface and simple design, navigating the platform is easy. Each game is straightforward, and we’ve included rules that are simple to follow.</p>
                    <p>To enhance your experience, it’s recommended to read the rules before you start playing, as it improves your enjoyment and increases your chances of winning.</p>
                    <p>The UltraPower casino apk offers seamless mobile gameplay, ensuring a high-quality experience no matter where you are.</p>
                </>,
                image: ultraPower6,
            },
            {
                title: "Years of Expertise in Gaming",
                detail: <>
                    <p>UltraPower Casino has invested years of expertise and resources into refining its games, backed by research and professional specialists.</p>
                    <p>The platform has also conducted extensive research to understand players' preferences, ensuring that the games they offer meet the needs of a wide variety of players.</p>
                    <p>In addition to offering a wide selection of games, they provide a secure environment for all transactions, allowing for easy deposits, transfers, and withdrawals.</p>
                    <p>If you encounter any issues, their customer service team is always available to help, ensuring that your experience remains hassle-free.</p>
                </>,
                image: ultraPower7,
            },
            {
                title: "A Wide Range of Games to Choose From",
                detail: <>
                    <p>UltraPower Casino offers a vast selection of games, including slots, table games, and more. You can enjoy these games on various mobile devices without any hassle, and with UltraPower free play, you can try games without risking real money.</p>
                    <p>Meet up with friends and enjoy the best games in one place. The platform also focuses on offering great bonus potential to enhance your gaming experience.</p>
                    <p>The games are built with quality software, ensuring that your experience is both enjoyable and rewarding. You can also use the UltraPower casino apk to play on mobile, offering convenience while playing on the go.</p>
                </>,
                image: ultraPower8,
            },
            {
                title: "Safe and Secure Platform",
                detail: <>
                    <p>It’s essential to feel safe when gaming online. With UltraPower, you can be confident that all games are regulated by a legitimate gaming authority.</p>
                    <p>Playing without worry is crucial, and knowing that the platform is legally licensed offers peace of mind. Your deposits are safe, and withdrawing your winnings is hassle-free.</p>
                    <p>The platform ensures smooth gameplay with no lag, allowing you to enjoy uninterrupted sessions. Additionally, making deposits and withdrawals is a simple and secure process.</p>
                </>,
                image: ultraPower9,
            },
        ],
        faqs: [
            {
                title: "What is UltraPower Casino?",
                content: "UltraPower is a sweepstakes-based platform that offers casino-style games, including fish tables, slot machines, and other exciting genres available at UltraPower Games."
            },
            {
                title: "How do I Register at UltraPower?",
                content: "To start, register at Cloud9, then choose UltraPower as your gaming platform. Customer support will contact you with your account details and a download link for the app."
            },
            {
                title: "How do I Add Money to UltraPower Casino?",
                content: "After logging into UltraPower, click the deposit button, select UltraPower, and specify the amount. Complete the transaction details, and once approved, the funds will appear in your account."
            },
            {
                title: "How do I Play UltraPower Games?",
                content: "Playing UltraPower Games is easy. After logging in, you can choose from three categories: slots, table games, and fish tables. Select a category and enjoy playing."
            },
            {
                title: "Is UltraPower Legal?",
                content: "Yes, the UltraPower Casino apk is fully legal and licensed."
            },
            {
                title: "Which Mobile Apps Are Available for UltraPower?",
                content: "UltraPower offers mobile apps for both Android and iOS, available for direct download from their website. These apps allow you to access games with high-quality visuals and immersive audio."
            },
            {
                title: "Does UltraPower Have Lottery Games?",
                content: "While UltraPower does not offer traditional lottery games, you can enjoy lottery-like games such as Keno on their platform."
            },
            {
                title: "What Casino Games Does UltraPower Slots Offer?",
                content: "UltraPower offers a variety of exciting slots including God of Wealth, Bonus Bears, Dragon’s Riches, Magic Pearl, Nu Xia, Great Blue, Dolphin Reef, and 50 Dragons."
            },
            {
                title: "What Are the Withdrawal Times at UltraPower?",
                content: "To withdraw your winnings, specify the amount on the withdrawal page. The minimum withdrawal amount is $20, and it typically takes up to 48 hours for your request to be processed."
            },
            {
                title: "Can I Play for Free at UltraPower Slots?",
                content: "Yes, you can play free demo versions of UltraPower slots to practice and familiarize yourself with the platform before committing real money."
            },
            {
                title: "Can I Win Real Money at UltraPower Casino?",
                content: "Yes, you can win real money at UltraPower, but only in real money mode. In demo mode, you can’t win real money."
            },
        ],
    },

    {
        id: 4,
        path: "/panda-master",
        name: "Panda Master",
        image: PandaMaster,
        sections: [
            {
                title: "Panda Master",
                detail: <>
                    <p>Do you like fish table games? Would you like to play the best fish arcade titles in the
                        sweepstakes world? Then, don't waste any time; check out Panda Master right now.&nbsp;</p>
                    <p>Panda Master is a unique sweepstakes casino that incorporates a wide range of sweepstakes genres
                        and presents them in a way that players of all types have something to enjoy. Stick around to
                        learn more!<br />
                        &nbsp;</p>
                </>,
                image: pandaMaster0,
            },
            {
                title: "Wide Range of Games to Choose From",
                detail: <>
                    <p>Panda Master offers a collection of the best sweepstakes games available on the market. The
                        platform categorizes those games as: "Fish," "777", "Reel," "Other," and "Link." Under each
                        category, you will find exclusive sweepstakes games ranging from slots to<a
                            href="#" target="_blank"> fish arcade
                            titles.&nbsp;</a></p>
                    <p>The platform also incorporates a variety of games like Jet X, Fortune Keno, Touch Gold, Space
                        Jet, House of Blackjack, and more. So, if you are tired of spinning the reels, you might as well
                        give a shot at one of these unique games at Panda Master!</p>
                </>,
                image: pandaMaster1,
            },
            {
                title: "Seamless Compatibility",
                detail: <>
                    <p>Panda Master is compatible with both mobile and desktop devices. Regardless of whether you want
                        to play the games on the go or while chilling at home, you will be able to do that once you
                        become a Panda Master player.&nbsp;</p>
                    <p>All the games on the platform are optimized for both large and small-screen devices. So, you will
                        not feel any difference even if you switch from desktop to mobile.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: pandaMaster2,
            },
            {
                title: "Top Sweepstakes Games at Panda Master",
                detail: <>
                    <h3>Age of Cleopatra&nbsp;</h3>
                    <p>Age of Cleopatra transports players to ancient Egypt, where they can revel in the opulence and
                        intrigue of Cleopatra's reign. This slot game features 5 reels and 20 paylines, adorned with
                        symbols like the Eye of Horus, golden ankhs, and the majestic Queen Cleopatra herself.&nbsp;</p>
                    <p>Cleopatra acts as a wild, doubling any wins she's part of, while the Sphinx scatter triggers a
                        free spin round with tripled payouts. The game's graphics are richly detailed, complete with
                        hieroglyphics and Egyptian motifs, creating an immersive historical experience that is both
                        visually appealing and potentially lucrative.</p>

                </>,
                image: pandaMaster3,
            },
            {
                title: "Fortune Jungle",
                detail: <>
                    <p>Fortune Jungle is among the<a href="#"
                        target="_blank"> best RTP slots</a>. It is set in a dense,
                        unexplored jungle filled with exotic wildlife and hidden treasures. This game has 5 reels and 30
                        paylines, featuring symbols like ancient relics, tropical fruits, and wild animals.&nbsp;</p>
                    <p>The Jungle Wheel bonus is a highlight, where players spin a wheel to win free spins, cash prizes,
                        or a multiplier. The wild symbol, represented by a roaring tiger, enhances the chance for big
                        wins. Fortune Jungle's lush graphics and adventurous soundtrack make it a thrilling choice for
                        players seeking excitement and discovery in their slot gameplay.</p>
                </>,
                image: pandaMaster4,
            },
            {
                title: "Double Wheels",
                detail: <>
                    <p>Double Wheels brings a modern twist to the classic slot experience with its unique dual-reel
                        mechanism. This game features two sets of reels, each with 3 reels and 5 paylines, which operate
                        independently but can synchronize for increased winning opportunities.&nbsp;</p>
                    <p>Symbols include traditional sevens, bars, and fruits, with special double wheel symbols that
                        trigger a bonus spin on both wheels simultaneously. This can result in double payouts or
                        unlocking a special jackpot round. Double Wheels' retro style, combined with innovative
                        gameplay, appeals to both traditional slot fans and those looking for something different.<br />
                        &nbsp;</p>
                </>,
                image: pandaMaster5,
            },
        ],
        faqs: []
    },
    {
        id: 5,
        path: "/gold-hunter",
        name: "Gold Hunter",
        image: GoldHunter,
        sections: [
            {
                title: "Gold Hunter",
                detail: <>
                    <p>Gold Hunter Casino offers an immersive online gaming experience, blending captivating themes with
                        advanced features to create a thrilling environment for all types of players.&nbsp;</p>
                    <p>Gold Hunter Casino offers an immersive online gaming experience, blending captivating themes with
                        advanced features to create a thrilling environment for all types of players.&nbsp;</p>
                </>,
                image: goldHunter0,
            },
            {
                title: "Extensive Game Library",
                detail: <>
                    <p>The casino boasts a diverse selection of games, including slots,<a
                        href="#"
                        target="_blank"> fish arcade games</a>, table games, and<a
                            href="#" target="_blank"> casino card
                            game</a> options. Players can explore various themes and styles, ensuring that there is always
                        something new and exciting to try. The games are provided by top-tier developers, guaranteeing
                        high-quality gameplay.</p>
                </>,
                image: goldHunter1,
            },
            {
                title: "Robust Customer Support",
                detail: <>
                    <p>Gold Hunter Casino offers excellent customer support to address any issues players may encounter.
                        The support team is available during business hours via live chat and email, ensuring prompt and
                        efficient assistance for all queries related to withdrawals, deposits, or game-related problems.<br />
                        &nbsp;</p>
                </>,
                image: goldHunter2,
            },
            {
                title: "Secure and Fair Gaming",
                detail: <>
                    <p>Player security is a top priority at Gold Hunter Casino. The platform uses advanced SSL
                        encryption to protect all transactions and personal information. Additionally, the casino is
                        licensed and regulated by reputable authorities, ensuring fair play through regular audits and
                        compliance with industry standards.<br />
                        &nbsp;</p>
                </>,
                image: goldHunter3,
            },
            {
                title: "Mobile Compatibility",
                detail: <>
                    <p>Gold Hunter Casino is fully optimized for mobile devices, allowing players to enjoy their
                        favorite games on the go. The mobile platform offers the same high-quality graphics and smooth
                        gameplay as the desktop version, providing flexibility and convenience.<br />
                        &nbsp;</p>
                </>,
                image: goldHunter4,
            },
            {
                title: "Exciting Slot Games",
                detail: <>
                    <p>Gold Hunter Casino features a variety of<a href="#" target="_blank"> penny slots</a> with unique
                        themes and bonus features. Popular titles include "King's Sword," "Water Margin," "Magic Lamp,"
                        and "Fortune Frog." These games offer high RTPs, engaging gameplay, and the potential for
                        significant wins.</p>
                </>,
                image: goldHunter5,
            },
            {
                title: "Free Spins and Bonus Features",
                detail: <>
                    <p>The slot games at Gold Hunter Casino come with exciting bonus features such as free spins, wild
                        multipliers, and scatter symbols. These features enhance the gameplay by increasing the chances
                        of winning and adding an extra layer of excitement to each spin.<br />
                        &nbsp;</p>
                </>,
                image: goldHunter6,
            },
            {
                title: "Easy Registration Process",
                detail: <>
                    <p>Registering at Gold Hunter Casino is a straightforward process. New players can quickly create an
                        account by providing basic information and verifying their identity. This hassle-free
                        registration process allows players to start enjoying their favorite games without unnecessary
                        delays.<br />
                        &nbsp;</p>
                </>,
                image: goldHunter7,
            },
            {
                title: "High-Quality Visuals and Audio Effects",
                detail: <>
                    <p>Gold Hunter Casino ensures an immersive gaming experience with its high-quality visuals and
                        engaging audio effects. The detailed graphics and soundtracks transport players into a realistic
                        and entertaining environment, enhancing the overall gaming experience.<br />
                        &nbsp;</p>
                </>,
                image: goldHunter8,
            }
        ],
        faqs: []
    },
    {
        id: 6,
        path: "/riversweeps",
        name: "Riversweeps",
        image: Rivers,
        sections: [
            {
                title: "RiverSweeps",
                detail: <>
                    <p>Imagine a casino where you can play the best mobile slots, enjoy high-quality sweepstakes games,
                        and win cashback bonuses every time you play. Welcome to Riversweeps Casino, the ultimate
                        destination for online gambling enthusiasts. Let’s explore the unique features and benefits of
                        Riversweeps Casino, how to register, add money to your account, and maximize your chances of
                        winning. So, let's dive in and discover the exciting world of Riversweeps Fun Casino.</p>
                </>,
                image: riverSweeps0,
            },
            {
                title: "The Riversweeps Fun App: A Visual Masterpiece",
                detail: <>
                    <p>The Riversweeps Fun app is a visual masterpiece that will captivate you from the moment you start
                        playing. With its high-quality audio and visual experience, the app brings the games to life in
                        a way that will keep you entertained for hours. Each game on the platform has unique and
                        impressive visual and sound effects, making you feel like you're in a luxurious Vegas Casino.
                        Download the app now and immerse yourself in the world of the best riversweeps games.&nbsp;</p>
                </>,
                image: riverSweeps1,
            },
            {
                title: "The Latest Technology at Riversweeps Fun",
                detail: <>
                    <p>Riversweeps Fun is not just about games; it's also about cutting-edge technology. The casino
                        boasts a high-end software system with modern solutions, ensuring a seamless gaming experience.
                        No more difficulties, lags, or delays - Riversweeps Fun delivers top-notch quality. The platform
                        is also optimized for various screen sizes, making it accessible on both desktop and mobile
                        devices. For the best mobile gaming experience, download the Riversweeps Fun app and enjoy
                        gaming on the go.</p>
                </>,
                image: riverSweeps2,
            },
            {
                title: "High-Quality Sweepstakes Games",
                detail: <>
                    <p>If you're looking for the best mobile slots and sweepstakes games, look no further than
                        Riversweeps Fun. With over 100 popular online casino games available, there's something for
                        everyone. Games like Reel Rider, Riches of East, and Rich Life offer exciting gameplay and come
                        with bonus features that make your gaming experience even more enjoyable. Try out these games
                        and discover the thrill of winning at Riversweeps Casino.<br />
                        &nbsp;</p>
                </>,
                image: riverSweeps3,
            },
            {
                title: "Riversweeps Fun Download:",
                detail: <>
                    <p>Ready to play the most immersive games in the market? Start your Riversweeps Fun download now.
                        Once you've registered on the platform, you'll gain access to a wide range of exciting game
                        titles. To ensure the most immersive experience, we recommend playing from the Riversweeps Fun
                        app. Available for both Android and iOS devices, the app brings the games to life in a way that
                        will leave you wanting more.</p>
                </>,
                image: riverSweeps4,
            },
            {
                title: "Legal and Safe Gaming at Riversweeps Fun",
                detail: <>
                    <p>When it comes to online gambling, safety and legality are of utmost importance. Riversweeps Fun
                        Casino holds all the necessary licenses and gambling permissions, ensuring that your gaming
                        experience is legal and secure. You can rest assured that there will be no legal complications
                        when playing at Riversweeps Fun, and you can easily cash out your winnings without any
                        trouble.</p>
                </>,
                image: riverSweeps5,
            },
            {
                title: "Explore The Best games and Enjoy Interactive Gameplay",
                detail: <>
                    <p>Riversweeps Fun Casino offers a truly immersive gaming experience. With high-quality sweepstakes
                        games, cashback bonuses, and a variety of exciting promotions, Riversweeps Fun has something for
                        every player. Whether you're a seasoned gambler or new to online casinos, Riversweeps Fun is the
                        perfect destination for hours of entertainment and the chance to win big. So, why wait?&nbsp;<a
                            href="#" target="_blank">Register now</a>, download the app, and
                        embark on an unforgettable gaming journey with Riversweeps Casino.</p>
                </>,
                image: riverSweeps6,
            },
            {
                title: "How to Play at Riversweeps Fun",
                detail: <>
                    <p>Playing at Riversweeps Fun is a breeze. Once you've registered on the platform and claimed your
                        bonuses, it's time to start playing. With the free cash from the bonuses, you can try out
                        various games and find your favorites. If you want a more interactive gaming experience,
                        consider downloading the Riversweeps app on your mobile device. The app is available for
                        both&nbsp;<a href="#" target="_blank">Android&nbsp;</a>and<a href="#"
                            target="_blank">&nbsp;iOS</a>,
                        allowing you to play anytime, anywhere.</p>
                </>,
                image: riverSweeps7,
            },
            {
                title: "How to Win at Riversweeps Fun",
                detail: <>
                    <p>Winning at Riversweeps Fun is all about strategy and luck. To maximize your chances of winning,
                        we recommend playing on a device that is most comfortable for you. Some players like small
                        screens while playing casino games, while others want to try them out on the desktop.
                        Fortunately, the Riversweeps Fun app is compatible with various gadgets, so you can download it
                        on any device and enjoy the ultimate gaming experience. Additionally, take advantage of the
                        available bonuses right after registration. By gathering all the bonuses, you can play for free
                        and increase your chances of winning big.</p>
                </>,
                image: riverSweeps8,
            }
        ],
        faqs: [
            {
                title: "1. What is Riversweeps Fun Casino?",
                content: "Riversweeps Fun Casino is a modern gambling platform that offers a truly immersive experience for players looking to win real cash. With a team of professionals and a wealth of expertise, the Riversweeps Fun app ensures that you get the best games and a chance to win big. Whether you're a seasoned player or new to online gambling, Riversweeps Fun has something for everyone."
            },
            {
                title: "2. How to Register at Riversweeps Fun?",
                content: "Getting started at Riversweeps Fun Casino is quick and easy. Simply fill out the registration form on Cloud9 and choose Riversweeps Fun Casino as your main gaming platform. Once you've completed the registration process, wait for the customer service team to get back to you with your account credentials. With your account set up, you're ready to log in and start gaming. "
            },
            {
                title: "3. How to Add Money to Your Riversweeps Fun Account?",
                content: "To start playing for real cash at Riversweeps Fun, you'll need to add money to your account. First, make sure to add your cryptocurrency wallet address to your profile. Once that's done, simply enter the amount you want to deposit and finalize the process. We offer a wide range of payment methods for you at Cloud9. You can utilize either Bitcoin, Litecoin or Dogecoin to seamlessly deposit money to your gaming account. With your account funded, you're ready to take on the exciting games at Riversweeps Fun Casino."
            }
        ]
    },
    {
        id: 7,
        path: "/golden-treasures",
        name: "Golden Treasure",
        image: GoldenTressure,
        sections: [
            {
                title: "Golden Treasure",
                detail: <>
                    <p>Are you searching for the best sweepstakes games in the industry? Then you should check out
                        Golden Treasures casino, where all the best players are found. Thanks to the platform's generous
                        bonuses, you can significantly improve your chances of winning.&nbsp;</p>
                    <p>You can play the Golden Treasure slot by yourself or with other players. So we recommend inviting
                        your friends! The platform uses top-tier software to develop playable, engaging casino
                        games.&nbsp;</p>
                    <p>You can trust the platform and the Golden Treasure Casino download for iPhone as you make instant
                        deposits and withdrawals. That can help you peacefully enjoy your games.&nbsp;</p>
                </>,
                image: goldenTreasure0,
            },
            {
                title: "Various Promotions and Bonuses",
                detail: <>
                    <p>Not every player has the same success when playing at online casinos. You must use the best
                        casino bonuses the site offers to ensure you get all the benefits. Winning at Golden Treasures
                        Casino highly depends on your playing time and bankroll.&nbsp;</p>
                    <p>Signing up with Golden Treasures allows you to take advantage of various bonuses. Playing with
                        these bonuses increases your chances of earning real money while participating in various
                        thrilling casino games.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure1,
            },
            {
                title: "Golden Treasures Casino is Easy to Use",
                detail: <>
                    <p>Golden Treasures online casino is aware of the significance of ease when playing the top casino
                        games. So, the platform has been made as easy to use as possible so you can give all your
                        attention to the game.&nbsp;</p>
                    <p>This makes it easy to use, regardless of your experience with online casinos. Thanks to the
                        seamless gameplay and straightforward navigation bar, you can use the platform's best features
                        without problems.&nbsp;</p>
                    <p>In addition, this casino platform allows for quick deposits and withdrawals, preventing any
                        problems. The Golden Treasure Casino download on iPhone is also simple to use, making it easy to
                        play your favorite games.<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure2,
            },
            {
                title: "Golden Treasures Casino is Compatible with Numerous Devices",
                detail: <>
                    <p>Another advantage of Golden Treasures online casino is its compatibility. You can play on any
                        preferred device and enjoy your favorite games. This function helps you to use both iOS and
                        Android to gain access to the top games.&nbsp;</p>
                    <p>Golden Treasures 777 is available instantaneously on most devices, regardless of the operating
                        system. After you’ve signed up for a Cloud9 account, customer support will contact you to
                        give you your login details and other necessary information.&nbsp;</p>
                    <p>The mobile casino app gives you more convenience. However, you can still enjoy the games on your
                        computer without any issues. It all depends on your preference, nothing else!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure3,
            },
            {
                title: "Golden Treasures Offer Excellent Customer Support",
                detail: <>
                    <p>Golden Treasures 777 will stop at nothing to give you the best customer experience. That's why
                        they've built a solid platform that lets players focus on having a good time while making some
                        cash.&nbsp;</p>
                    <p>However, problems do occur, but Golden Treasures have a professional team ready to help you
                        answer any questions. They'll give you a swift response and guide you to solve any issues that
                        may arise. &nbsp;</p>
                    <p>If you need help making a deposit, withdrawal, or transfer of funds, contact customer support,
                        and they'll gladly assist you!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure4,
            },
            {
                title: "Golden Treasures Download",
                detail: <>
                    <p>If you are into mobile casino gaming, then Golden Treasures Download is what you need to learn
                        about. So start the download process instantly and access the best mobile casino games at your
                        fingertips.&nbsp;</p>
                    <p>The casino app allows you to enjoy high-quality games and unique coupon codes and bonuses that
                        can help you level up your gameplay.&nbsp;</p>
                    <p>After installing the mobile casino app, you can enjoy exclusive bonus offers, promotions,
                        fantastic games, and more! So consider the Golden Treasures download and boost your winning
                        cash!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure5,
            },
            {
                title: "Licensed by Certified Gaming Authorities",
                detail: <>
                    <p>The platform’s app has been reviewed and accepted by the appropriate authorities. That means you
                        can rest assured that you'll have a great time while increasing your chances of
                        winning.&nbsp;</p>
                    <p>You'll have a fantastic time by taking advantage of the platform's numerous features, such as its
                        outstanding Golden Treasure slot and generous cash prizes. In addition, due to the lack of lag,
                        you can play any game without any issues.&nbsp;</p>
                    <p>If you’re worried about getting bored, you’ll be surprised! The software used and the premium
                        games Golden Treasures Casino offers are trustworthy and always perform as intended.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure6,
            },
            {
                title: "Streamlined Gameplay Interface",
                detail: <>
                    <p>This casino platform offers a user-friendly layout with a single click. The interface (screens,
                        buttons, instructions, etc.) is simple. The casino app is designed to be accessible to
                        everybody, regardless of experience with casino platforms.&nbsp;</p>
                    <p>Also, if you have any issues registering, depositing, withdrawing money, etc., you can contact
                        their customer support via email, live chat, or phone. They’ll gladly assist you in no
                        time.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure7,
            },
            {
                title: "Golden Treasures Casino Offers Fast Withdrawals and Deposits",
                detail: <>
                    <p>The unreliability of a casino platform when it comes to processing deposits and withdrawals is a
                        major source of player dissatisfaction. When it comes to assistance, Golden Treasures Casino
                        knows how frustrating it can be when there’s no response to your solution.&nbsp;</p>
                    <p>That's why the casino platform employs security measures always to protect your deposits and
                        withdrawals. That eases the process of depositing money and cashing out wins. So sign up now and
                        enjoy the best Golden Treasure slot now!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure8,
            },
            {
                title: "Smooth Gameplay",
                detail: <>
                    <p>The games at Golden Treasures Casino are consistently cutting-edge and expertly executed. The
                        platform’s priority is comfort and enjoyment as you play your preferred casino games.&nbsp;</p>
                    <p>Whether you're a seasoned pro or just getting started, you'll feel right at home playing
                        top-notch games. Moreover, the platform is a great place to have fun with friends by
                        playing&nbsp;<a href="#" target="_blank">slots online for real money</a>.&nbsp;</p>
                    <p>Whether you play on a mobile device or desktop computer, the casino app will provide a terrific
                        and immersive experience. You'll always have an incredible time.&nbsp;</p>
                </>,
                image: goldenTreasure9,
            },

        ],
        faqs: [
            {
                title: "1. What is Golden Treasure Casino?",
                content: "Golden Treasure is a casino platform for players who wants to enjoy exciting games and increase their cash rewards. This platform has an extensive selection of sweepstakes games. So you’ll always find the best and latest games to play with your friends! You can also use the Golden Treasure Casino download for Android to play on your mobile devices. "
            },
            {
                title: "2. How to download Golden Treasures Casino on iPhone?",
                content: "You can use the Golden Treasure casino download on iPhone to play games on your mobile device. Visit Cloud9 and download the app. After that, you can sign in and start playing for real money. You can contact customer support if you need assistance. "
            },
            {
                title: "3. How to download Golden Treasures Casino on Android?",
                content: "You can play your favorite casino games anywhere with the Golden Treasure casino download on Android. You can install the casino app by visiting Cloud9. The installation process takes a few minutes, and you can contact the support team if you need any assistance. "
            },
            {
                title: "4. What is the RTP for Golden Treasures?",
                content: "Every game comes with its RTP. However, rest assured that you can get a higher RTP rate to increase your chances of winning. So sign up now, get many bonuses, and start playing for real money. "
            },
            {
                title: "5. Are there any free spins or bonuses on Golden Treasures Casino?",
                content: "Yes. You can get numerous bonuses and free spins on this casino platform. All you have to do is sign up and start playing"
            },
            {
                title: "6. Can I play Golden Treasure slots for free?",
                content: "Yes. You can play free slots on this casino platform. And you don’t have to do anything to get this free play. First, sign up, choose your preferred game, and start playing the free version. Then, after attaining the required skills, you can play for real money. "
            },
            {
                title: "7. Where can I play the Golden Treasure slot for free?",
                content: "Sign up on Cloud9 and choose Golden Treasure. You’ll have access to hundreds of casino games you can play for free. If you need any assistance, feel free to call customer support. They are always there to assist you. "
            },
            {
                title: "8. Can I play the Golden Treasure slot for real money?",
                content: "Yes. You can play the best games on this casino platform for real money. Furthermore, they also make it easy for you to withdraw your winnings. All you have to do is sign up and play your best games! "
            }
        ]
    },
    {
        id: 8,
        path: "/skillmine",
        name: "Skillmine",
        image: Skillmine,
        sections: [
            {
                title: "Skillmine",
                detail: <>
                    <p>The fantastic and unparalleled services you'll experience at Skillmine Casino will take you on an
                        adventure you've never been to! In addition, Skillmine has a wide variety of online slot
                        machines and other casino games, so you'll never get bored.&nbsp;</p>
                    <p>There is no better place to play sweepstakes games than Skillmine. In addition, you can easily
                        withdraw their funds whenever you choose, thanks to Skillmine's fast deposit and withdrawal
                        services.&nbsp;</p>
                    <p>You also get all the customer support you need, no matter the problem.&nbsp;</p>
                </>,
                image: skillmine0,
            },
            {
                title: "Top-Notch Graphics and Sound Effects",
                detail: <>
                    <p>Besides the numerous sweepstakes games you can play on Skillmine, that's not the only benefit you
                        get. The games have HD visuals and a thrilling sound theme that creates an immersive experience,
                        making the games more fun to play.&nbsp;</p>
                    <p>You'll get the authentic casino experience at any slot machine or table game on Skillmine. The
                        games have no lags, so it makes every moment you spend a memorable one. You can also get the
                        same fantastic visuals and immersive sound on Skillmine Android apk download.&nbsp;</p>
                </>,
                image: skillmine1,
            },
            {
                title: "Skillmine Casino Offers Wild Wager Bonuses",
                detail: <>
                    <p>The attractiveness of Skillmine Games is enhanced, in part, by the bonuses you can earn. You get
                        the chance to gain amazing bonuses to help you boost your chances of winning.&nbsp;</p>
                    <p>Sign up, confirm your account information, and you'll be ready. That's how simple it is! Don't
                        worry about how much you have.&nbsp;</p>
                    <p>The extra money can help you get back on your feet. In addition, the requirements are not strict,
                        so you can quickly get and cash out your winnings, even with bonuses. Also, you can get free
                        money on Skillmine games to play any game you want!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: skillmine2,
            },
            {
                title: "Skillmine Casino Offers Daily Bonuses",
                detail: <>
                    <p>Skillmine provides top-notch entertainment for you and your friends. In addition to the
                        high-quality visuals and audio, they also offer daily bonuses that can increase your chances of
                        winning.&nbsp;</p>
                    <p>Depending on the game you choose, you can anticipate some bonus. So play more to increase your
                        odds of winning cash prizes. That way, you can play Skillmine slots for free and take advantage
                        of the bonuses.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: skillmine3,
            },
            {
                title: "Skillmine Casino offers Excellent Customer Support",
                detail: <>
                    <p>Having accessible customer support at your disposal at all times is essential. For this, it plays
                        a crucial role in the Skillmine Casino platform.</p>
                    <p>Skillmine promises a smooth and trouble-free experience. But if you run into trouble, their
                        top-notch customer support will help you with any issues. In addition, you can contact the
                        support team whenever you like, and they'll address your concerns immediately.&nbsp;</p>
                </>,
                image: skillmine4,
            },
            {
                title: "Skillmine App Uses the Latest Technology",
                detail: <>
                    <p>Using cutting-edge software, Skillmine Casino provides a more enjoyable environment to play your
                        favorite casino games. Furthermore, this technology has been tested extensively by experts in
                        the field before being made public.&nbsp;</p>
                    <p>That'll allow you to play Skillmine slots without any issues. Even after playing for several
                        hours, this casino platform's stunning images and immersive soundtrack will captivate you. The
                        platform's success can be attributed, in part, to the various exciting plots that players choose
                        from.&nbsp;</p>
                    <p>That'll allow you to play Skillmine slots without any issues. Even after playing for several
                        hours, this casino platform's stunning images and immersive soundtrack will captivate you. The
                        platform's success can be attributed, in part, to the various exciting plots that players choose
                        from.&nbsp;</p>
                    <p>You also get the same cutting-edge technology with the Skillmine iPhone app download.</p>
                </>,
                image: skillmine5,
            },
            {
                title: "Best Games to Win on Skillmine Games",
                detail: <>
                    <p>As one of the most attractive ladies of her time, she helped spark the massive conflict that led
                        to the fall of Troy. Five reels and 10 paylines make up the Golden Goddess. Here, a temple
                        stands out against a reddish-gold sky, evoking the splendor of the ancient Greek
                        world.&nbsp;</p>
                    <p>Symbols of mythology, including vases and other objects from ancient Greece, are used as symbols,
                        continuing the ancient theme. Ten through Ace are the typical playing card symbols representing
                        the lower values.&nbsp;</p>
                    <p>They have a straightforward pattern in vivid hues with a thin gold border. Wild Symbol
                        substitutes for all other symbols to complete a winning combination.&nbsp;</p>
                </>,
                image: skillmine6,
            },
            {
                title: "Columbus Deluxe",
                detail: <>
                    <p>Across its five spinning reels, you can take advantage of features including scatters, wilds,
                        multipliers, and free games. Additionally, you can play for free or wager with various odds,
                        with the top payout being $50,000.&nbsp;</p>
                    <p>Except for the more vivid HD animated graphics, it’s almost similar to the original classic
                        release. The slots’ logo, set against a wooden texture, is complemented by an actual nautical
                        chart.&nbsp;</p>
                    <p>Columbus is back on the reels now that he's a wild symbol. Besides Queen Isabella, ships,
                        sextants, maps, and expensive Spanish jewelry are depicted on the reels as symbols. The standard
                        poker card values also appear.&nbsp;</p>
                </>,
                image: skillmine7,
            },
            {
                title: "Start Your Adventure Today",
                detail: <>
                    <p>&nbsp;If you've never tried Skillmine slots for free, now is the time to do so. Each of your
                        go-to slots, fish table games, etc., is given a modern update on this casino platform.&nbsp;</p>
                    <p>The idea is that you can play your favorite games whenever and wherever you want. Skillmine gives
                        you breathtaking visuals and sound design. Then, when your bankroll grows, you'll increase your
                        winning cash!&nbsp;</p>
                    <p>You can also get free money on Skillmine to boost your winning chances! Sign up today and get the
                        top&nbsp;<a href="#" target="_blank">slot bonus new member</a>&nbsp;offers and win more
                        cash!&nbsp;</p>

                </>,
                image: skillmine8,
            },
            {
                title: "Fruitilicious",
                detail: <>
                    <p>If you want to play the&nbsp;<a href="#" target="_blank">highest RTP slots</a>, consider
                        Fruitilicious! This game is a fascinating game with an RTP of 96,07% and high volatility. That
                        last part indicates that you may need to wait until the payout. However, when you land a prize,
                        it will be life-changing!</p>
                    <p>Fruitilicious has an extensive layout of 5 reels and 3 rows. And this wide greed, of course,
                        comes in numerous bet ways.&nbsp;</p>
                    <p>You will be amazed by all the unique features gathered in a single game when playing the game.
                        For example, you can activate impressive wins, multipliers, and free spins feature with maximum
                        luck. And at the end of each win, you can double your prize with a simple gaming feature!</p>
                </>,
                image: skillmine9,
            },

        ],
        faqs: [
            {
                title: "How to add money to Skillmine account?",
                content: "When you complete the skillmine casino login and enter your account, navigate to \"Transactions\" and \"Deposit\" to add funds. Then, select Skillmine and enter your desired amount. Next, after you're ready to pay, click the \"View Cart\" button. "
            },
            {
                title: "How to win on Skillmine?",
                content: "That can improve your familiarity with the game, sharpen your concentration, and lead to greater winning chances. In addition, you can get free money on Skillmine to play any game you want. That can help you play without risking your money. So, do not forget to utilize the available bonuses on the platform, including the skillmine casino no deposit bonus. "
            },
            {
                title: "What is Skillmine Casino?",
                content: "Skillmine Casino Real Money is an excellent casino platform to play exciting games, get exclusive bonuses and promo codes, and find many ways to win big. All the games are licensed. And the platform always updates its gaming library to provide the best for its players. "
            },
            {
                title: "How to download the Skillmine App?",
                content: "You can use the Skillmine iPhone app download to install the casino app on your iPhone device. Then, visit Cloud9 and download the app. If you have any issues, contact customer support. They’ll quickly help you solve whatever issues you might have. "
            },
            {
                title: "Can I make purchases using cryptocurrency at Skillmine Games?",
                content: "Yes. You can purchase Bitcoin to play games on Skillmine Games. In addition."
            },
            {
                title: "Can I get a no deposit sign-up bonus at Skillmine Games?",
                content: "Yes. You can get a skillmine casino no deposit bonus when registering as a new player. You can use these bonuses to play any game with low wagering requirements. Plus, if you make a deposit, you also get other bonuses to boost your chances of winning. "
            },
            {
                title: "How to create a Skillmine Games Account?",
                content: "Visit Cloud9 and click on “Sign Up.” You’ll be redirected to a page where you enter your personal details and choose your preferred platform. The registration takes a few minutes to complete. After that, you can start playing your favorite games. "
            },
            {
                title: "Is Skillmine Casino safe?",
                content: "Yes. Skillmine casino real money takes your safety and privacy seriously and uses cutting-edge security measures to ensure both. In addition, official gaming licenses and regulations are in place for all games. That way, cashing out your winnings won't be a hassle. "
            }
        ]
    },
    {
        id: 9,
        path: "/king-of-pop",
        name: "King Of Pop",
        image: kingofpop,
        sections: [
            {
                title: "King of Pop",
                detail: <>
                    <p>King of Pop Casino captures the essence of Michael Jackson’s unparalleled musical career by
                        integrating his greatest hits into an exciting gaming environment. This casino offers players a
                        chance to relive iconic moments from the King of Pop’s legacy while enjoying a wide variety of
                        thrilling casino games ranging from slots to<a
                            href="#" target="_blank"> casino card
                            games</a> and many more alternatives for the ultimate fun.&nbsp;</p>
                </>,
                image: kingpop0,
            },
            {
                title: "Best King Of Pop Games to Play",
                detail: <>
                    <p>Hexa Keno offers a modern twist on the classic game of keno. Players select numbers from a
                        hexagonal grid, hoping to match the randomly drawn numbers for big payouts. The game features
                        sleek graphics and an easy-to-use interface, making it accessible to both new and experienced
                        keno players.</p>
                </>,
                image: kingpop1,
            },
            {
                title: "Vegas Hits",
                detail: <>
                    <p>Vegas Hits Slot brings the glitz and glamour of Las Vegas to the players’ screens. This<a
                        href="#"
                        target="_blank"> casino slot game</a> features multiple paylines and a variety of Las
                        Vegas-themed symbols. Players can enjoy bonus rounds and special features like free spins and
                        multipliers, all set against the backdrop of the vibrant Vegas strip.</p>
                </>,
                image: kingpop2,
            },
            {
                title: "Innovative Features",
                detail: <>
                    <p>King of Pop Casino continually updates its platform with innovative features to enhance the
                        player experience. From unique in-game bonus rounds to interactive mini-games, these features
                        add an extra layer of excitement and engagement to the gaming sessions.<br />
                        &nbsp;</p>
                </>,
                image: kingpop3,
            },
            {
                title: "Detailed Game Information",
                detail: <>
                    <p>Each game at King of Pop Casino comes with detailed information on rules, payout structures, and
                        special features. This transparency helps players make informed decisions and maximizes their
                        enjoyment of the games.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: kingpop4,
            },
            {
                title: "Regular Updates and New Releases",
                detail: <>
                    <p>King of Pop Casino keeps the gaming experience fresh by regularly updating its game library and
                        introducing new releases. These updates include new slot themes, enhanced graphics, and
                        additional gameplay features, ensuring that players always have something new to look forward
                        to.</p>
                </>,
                image: kingpop5,
            },
            {
                title: "User-Friendly Interface",
                detail: <>
                    <p>The platform boasts a user-friendly interface that makes it easy for players to navigate and find
                        their favorite games. The intuitive design ensures a smooth and enjoyable gaming experience,
                        whether on desktop or mobile devices.<br />
                        &nbsp;</p>
                </>,
                image: kingpop6,
            },
            {
                title: "Lucky Duck",
                detail: <>
                    <p>Lucky Duck Slot is a fun and whimsical game featuring cartoonish graphics and a playful theme.
                        Players spin the reels to match symbols like ducks, ponds, and lily pads, with opportunities to
                        trigger bonus rounds and free spins. Its light-hearted design and rewarding features make it a
                        delightful option for slot enthusiasts.<br />
                        &nbsp;</p>
                </>,
                image: kingpop7,
            }
        ],
        faqs: []
    },
    {
        id: 10,
        path: "/vegas-sweeps",
        name: "Vegas Sweeps",
        image: vegassweeps,
        sections: [
            {
                title: "Vegas Sweeps",
                detail: <>
                    <p>Over the years, Vegas Sweeps has become one of the most popular places to play casino games with
                        friends and win real money. As a result, they've put in a lot of time and effort looking for
                        games that will appeal to current customers and attract new ones.&nbsp;</p>
                    <p>Furthermore, it's powered by trustworthy&nbsp;<a
                        href="#" target="_blank">online casino
                        software</a>, so players can feel at ease making deposits and playing the top games. In
                        addition, you can rely on this online casino to quickly arrive at your rescue if you ever
                        encounter any problems.&nbsp;</p>
                    <p>Using Vegas Sweeps online casino download, you can also play real money casino games on your
                        mobile device.&nbsp;</p>
                </>,
                image: vegasSweeps0,
            },
            {
                title: "Play Games Without Distractions at Vegas Sweeps Casino",
                detail: <>
                    <p>You can find the best games on this platform. Further, the gameplay is polished, and the visuals
                        are impressive. The platform's primary focus is your satisfaction while playing your preferred
                        casino games.</p>
                    <p>The thrills you seek can be found playing games on this casino platform, regardless of your
                        expertise. It will be the most exciting period of your life as you rack up more and more cash
                        prizes using the Vegas sweeps free play!&nbsp;</p>
                    <p>The interface is intuitive, so you won't have problems finding your way. And even if you've never
                        played, you'll have no trouble following the rules.&nbsp;</p>
                </>,
                image: vegasSweeps1,
            },
            {
                title: "Sign Up and Get a $20 Bonus for Free",
                detail: <>
                    <p>When you play at Vegas Sweeps 777, you can count on a wonderful experience. You're taking the
                        first step toward a fantastic experience when you sign up.&nbsp;</p>
                    <p>New players receive a free $20 bonus for registering with this platform. There are no
                        requirements that you must fulfill. Just signing up is enough to get your bonus and start
                        playing.&nbsp;</p>
                    <p>You must follow no rules; just sign up using the Vegas Sweeps online login and get a free $20
                        bonus.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: vegasSweeps2,
            },
            {
                title: "Vegas Sweeps Offers Excellent Visuals and Audio",
                detail: <>
                    <p>There are many Vegas Sweeps games on this platform, but that's not the only benefit you get. In
                        addition, the game's high-definition images and exciting music theme make for a more compelling
                        and entertaining experience.&nbsp;</p>
                    <p>You can play the best games in the casino industry with zero lags, enough bonuses to win you more
                        cash, and an immersive experience.&nbsp;</p>
                    <p>You can also enjoy the same quality visuals on Vegas Sweeps download for iPhone.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: vegasSweeps3,
            },
            {
                title: "Vegas Sweeps Offers Excellent Customer Support",
                detail: <>
                    <p>Having accessible customer support at your disposal at all times is essential. It plays such a
                        key role in the platform. If you have any problems with the platform or have any queries
                        regarding the games, you can get the help you need anytime.&nbsp;</p>
                    <p>If you have any issues concerning the Vegas Sweeps online login, contact customer support, and
                        they'll help you.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: vegasSweeps4,
            },
            {
                title: "Vegas Sweeps Download",
                detail: <>
                    <p>Vegas Sweeps apk is an excellent option to play your favorite casino games on the go. All the
                        best casino games are available right now on your mobile device.&nbsp;</p>
                    <p>The games in the Vegas Sweeps online casino download are similar to those in the desktop version.
                        The casino app is highly recommended for playing the best casino games.&nbsp;</p>
                    <p>Contact customer support if you have questions about the Vegas Sweeps casino download.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: vegasSweeps5,
            },
            {
                title: "Play Amazing Games on Vegas Sweeps",
                detail: <>
                    <p>The Vegas Sweeps app is home to the best online casino games you can find. Moreover, the games in
                        question include high-quality visuals, compelling soundtracks, and exciting narratives. Each one
                        gives you a shot at some cool bonuses, too.&nbsp;</p>
                    <p>In addition, it supports a wide variety of popular&nbsp;<a
                        href="#" target="_blank">speedy
                        casino slots</a>. It doesn't matter what kind of games you're into; you'll always be able to
                        find one that suits your tastes. Vegas Sweeps 777 features various games that can be played on
                        any computer, smartphone, or tablet.&nbsp;</p>
                    <p>So you can play outstanding games using the Vegas Sweeps download iPhone to win real
                        money.&nbsp;</p>
                </>,
                image: vegasSweeps6,
            },
            {
                title: "Vegas Sweeps Apk is Secure",
                detail: <>
                    <p>Your safety is the top priority, and the casino platform treats it as such. By playing on the
                        platform, you can be confident using a safe, legitimate, trustworthy casino platform.&nbsp;</p>
                    <p>Furthermore, only games authorized by proper gaming authorities are available on the Vegas Sweeps
                        apk. Any transactions that occur on this casino platform are highly secured.&nbsp;</p>
                    <p>Furthermore, you can use the Vegas Sweeps online casino download to enjoy your favorite games on
                        your mobile device without any issues.&nbsp;</p>

                </>,
                image: vegasSweeps7,
            },
            {
                title: "Vegas Sweeps Gives You a Smooth Gameplay",
                detail: <>
                    <p>This online casino has all the best games you wish to play! On top of that, you get outstanding
                        gameplay, and the visuals are incredible! Playing your favorite casino games in a relaxed
                        environment is the platform's primary goal.&nbsp;</p>
                    <p>If you're searching for a thrilling gaming experience, this casino platform has you covered,
                        regardless of your level of expertise. You can have the best time of your life when your
                        bankroll increases.&nbsp;</p>
                    <p>The interface is intuitive, so you won't have problems navigating the platform. And even if
                        you've never played on the platform, you'll have no issue understanding how the game
                        works.&nbsp;</p>
                    <p>You can use the Vegas Sweeps download to play on your mobile device. And you get the same
                        seamless gameplay.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: vegasSweeps8,
            },
            {
                title: "Receive a $10 Bonus for Every Friend You Refer",
                detail: <>
                    <p>Together with numerous bonuses, Vegas Sweeps has a fantastic referral program. If you share your
                        referral link with your friends and they sign up, you get $10 free.&nbsp;</p>
                    <p>For your friends to register, just forward them your referral link. If they sign up using the
                        Vegas Sweeps login and deposit at least $10, you and your friend will each receive a $10
                        bonus.&nbsp;</p>
                    <p>The process is quick and straightforward and doesn't require any complicated process. You can
                        contact customer support if you have any questions concerning the bonuses.&nbsp;</p>
                    <p>You can use the Vegas sweeps free play and the bonus to increase your chances
                        significantly.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: vegasSweeps9,
            },
        ],
        faqs: []
    },
    {
        id: 11,
        path: "/rivermonster",
        name: "Rivermoster",
        image: rivermonster,
        sections: [
            {
                title: "Rivermonster",
                detail: <>
                    <p>If you want to play the latest and greatest slot games on the market, give it a shot to
                        RiverMonster. This casino platform offers users the best gaming experience. You can find table
                        fish games and modern slot machines on this platform.</p>
                    <p>Over the years, River Monster Casino has gained experience and improved the quality of its games
                        and services. So if you’re searching for the best&nbsp;<a
                            href="#" target="_blank">casino games
                            with the best odds</a>&nbsp;to win real cash, consider this platform.</p>
                </>,
                image: riverMonster0,
            },
            {
                title: "Smooth Gameplay",
                detail: <>
                    <p><span><span>Check out the River Monster games if you want the best sweepstakes gaming experience. They designed the games so players can easily understand the system and enjoy the best features instantly.&nbsp;</span></span>
                    </p>
                    <p><span><span>The priority is providing exciting games for players to demonstrate their skillset and earn fantastic rewards. So, if you are one of those players sick of the boring themes and want to try something new, try River Monster slots.&nbsp;</span></span>
                    </p>
                </>,
                image: riverMonster1,
            },
            {
                title: "RiverMonster Is Licensed and Secured",
                detail: <>
                    <p>The platform is licensed and secured, so you have nothing to worry about depositing your money or
                        withdrawing your wins. You can play the games whenever it’s most convenient because everything
                        runs smoothly and quickly.</p>
                    <p>The platform is licensed and secured, so you have nothing to worry about depositing your money or
                        withdrawing your wins. You can play the games whenever it’s most convenient because everything
                        runs smoothly and quickly.</p>

                </>,
                image: riverMonster2,
            },
            {
                title: "50% Bonus on Your First Deposit",
                detail: <>
                    <p><span><span>RiverMonster values your time on their platform and will reward you with a 50% deposit on your first deposit. In addition, there are no requirements, which is the best part.</span></span>
                    </p>
                    <p><span><span>You don’t have to worry about any fine print. It’s easy to get a 50% bonus on your initial deposit of just $10 by signing up and making your first deposit. You can boost your chances of winning with this bonus.</span></span>
                    </p>
                    <p>
                        <span><span>So don’t think about it; choose your favorite game and get more bonuses!</span></span>
                    </p>
                </>,
                image: riverMonster3,
            },
            {
                title: "Best Games in the Industry",
                detail: <>
                    <p><span><span>Regarding modern slot machines, you get high-quality games with high-quality visuals and enjoyable gameplay. The main focus is on providing you with the easiest possible experience while playing the games.&nbsp;</span></span>
                    </p>
                    <p><span><span>In addition, there is a tournament within the game where skilled players can show off their moves and prove their worth. You can use and improve your gaming skills while you enjoy numerous engaging and entertaining games, even if you're starting.&nbsp;</span></span>
                    </p>
                    <p><span><span>A streamlined interface and intuitive controls further ensure your peace of mind. In a short time, you can learn the basics of the game.</span></span>
                    </p>
                </>,
                image: riverMonster4,
            },
            {
                title: "Excellent Customer Support",
                detail: <>
                    <p><span><span>You can play River Monster online without any issues. It’s a robust platform ensuring that players get the best service. But they know that issues can arise at any time. That’s why This casino platform has excellent customer support to help you out.</span></span>
                    </p>
                    <p><span><span>The platform offers access to qualified personnel willing to help with any issues you may have. For example, if the issue is related to transfers, withdrawals, or deposits, the customer support team can help you.&nbsp;</span></span>
                    </p>
                </>,
                image: riverMonster5,
            },
            {
                title: "Get a 20% Bonus on Your Second and Third Deposit",
                detail: <>
                    <p><span><span>In addition to the 20% bonus on your initial deposit, you also get an additional chance to succeed and win more cash. If you make a second and third deposit, you’ll get an extra 20% toward playing and winning on their platform.</span></span>
                    </p>
                    <p><span><span>RiverMonster recognizes and values your continued dedication to their casino games, as seen by your second and third deposits. So keep at it and rack up more wins!</span></span>
                    </p>
                    <p><span><span>This bonus can be yours with no further requirements on your part. The bonus is immediately added to your account when you make your second and third deposits.</span></span>
                    </p>

                </>,
                image: riverMonster6,
            },
            {
                title: "River Monster App Download",
                detail: <>
                    <p><span><span>River Monster app download is an excellent solution if you want to play your favorite games on the go. If you want to enjoy games and win big while traveling or waiting at the bus stop, you can use the River Monster 777 apk download.&nbsp;</span></span>
                    </p>
                    <p><span><span>The casino app is easy to install and only takes a few minutes. The River Monster app download only takes up a small amount of storage space on your device. So you can enjoy your favorite games without worrying about storage issues.&nbsp;</span></span>
                    </p>
                    <p><span><span>Furthermore, you can access numerous bonuses and exclusive promo codes when using the River Monster 777 apk download. These bonuses can keep the game more exciting while increasing your chances of winning.&nbsp;</span></span>
                    </p>
                    <p><span><span>If you have any questions about the River Monster 777 apk download, contact customer support, and they’ll quickly help you. Do download the app today and start winning!&nbsp;</span></span>
                    </p>
                </>,
                image: riverMonster7,
            }
        ],
        faqs: [
            {
                title: "What is Rivermonster Casino?",
                content: "Rivermonster casino is a sweepstakes platform that offers exceptional bonuses such as rivermonster sign up bonus,rivermonster free play,  free spins, and many more profitable features. You can play exceptional rivermonster 777 games once you complete the rivermonster login. ",
            },
            {
                title: "How to Register at Rivermonster?",
                content: "Rivermonster login is a simple process that even players who are not very tech-savvy can complete. Register on Cloud9 and pick Rivermonster apk. Then, make sure that you pick Rivermonster from the platform list. After that, customer support will contact you and share the details of your account. ",
            },
            {
                title: "How to win on Rivermonster 777?",
                content: "To win on rivermonster 777, you need to pick the games that have the highest payout rates. Moreover, you can learn about the winning strategies that can enhance your odds for a particular genre that you are playing. ",
            },
            {
                title: "How to play Rivermonster Casino?",
                content: "To play rivermonster app or any games that are available on them, you need to enter the platform first after registering on Cloud9. After completing the rivermonster download, you can pick any game that you want and start playing. Do not forget to enjoy the rivermonster free play and rivermonster sign up bonus to get free credits at the platform. ",
            },
            {
                title: "Is Rivermonster legal?",
                content: "Rivermonster 777 is completely legal in almost all the states. So, do not worry about legality and enjoy the best rivermonster slots right away. ",
            },
            {
                title: "Is Rivermonster safe?",
                content: "Yes, Rivermonster is built by keeping user safety in mind, and the platform utilizes cutting-edge fraud detection as well as data protection measures. ",
            },
            {
                title: "How to download River Monster apk for Android?",
                content: ". You can download and install the app in a few minutes and start playing. Playing your preferred River Monster games on the app gives you unique coupon codes to increase your chances of winning! Go ahead and complete rivermonster apk download for android. ",
            },
            {
                title: "How to add money to River Monster app?",
                content: "To add money to your river monster app account, first, log in to Cloud9 and click \"Transactions.\" Select River Monster and add the desired amount. Next, click \"View Cart\" and answer the following prompt. That's it! It should take a few minutes. ",
            },
            {
                title: "What casino games does RiverMonster casino offer?",
                content: "RiverMonster gives you access to various games, such as Book of Ra, God of Wealth, and&nbsp;<a href=\"#\" target=\"_blank\">Buffalo Slots</a>. You can also play Arc of Templars, Caribbean Pirates, and Baby Octopus. They have hundreds of quality games you can play!",
            },
            {
                title: "How do you get free money on River Monster?",
                content: "Getting free money on River Monster is easy. First, sign up on Cloud9 if you’re a new player. Then, choose your preferred platform. After the registration, you’ll instantly get a signup bonus to play any game you want. You can also get other bonuses like the first deposit bonus. It's a bonus where you get a 50% bonus on your first deposit. The more you play, the more bonuses you get! ",
            },
        ]
    },
    {
        id: 12,
        path: "/game-room",
        name: "Game Room",
        image: gameroom,
        sections: [
            {
                title: "Game Room",
                detail: <>
                    <p>GameRoom Casino stands out as a premier destination for sweepstakes gaming. With its robust game
                        library and innovative features, it’s the perfect place for those looking to enjoy top-tier
                        casino entertainment from the comfort of their homes.</p>
                </>,
                image: gameRoom0,
            },
            {
                title: "Easy Registration Process",
                detail: <>
                    <p>Signing up for GameRoom Casino is quick and straightforward. Players can create an account in
                        minutes, allowing them to jump into the action without delay​. Just click on<a
                            href="#" target="_blank"> Register at Cloud9</a>, verify your
                        account, pick Game Room, make a deposit, and start playing right away.&nbsp;</p>
                </>,
                image: gameRoom1,
            },
            {
                title: "Customer Support",
                detail: <>
                    <p>While GameRoom Casino itself does not handle customer support, players can<a
                        href="#" target="_blank"> reach out to the support</a> team of
                        Cloud9. Regardless of the issue that you are facing or the question that you have, we can
                        assist you in handling the issue right away.&nbsp;</p>
                </>,
                image: gameRoom2,
            },
            {
                title: "Compliance with Sweepstakes Laws",
                detail: <>
                    <p>GameRoom Casino operates in full compliance with U.S. sweepstakes laws, ensuring a legal and safe
                        gaming environment. This compliance provides players peace of mind, knowing they are engaging in
                        a legitimate gaming platform.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: gameRoom3,
            },
            {
                title: "Fruit Frenzy",
                detail: <>
                    <p>Fruit Frenzy is a bright and bubbly slot game that offers a whimsical take on the traditional
                        fruit machine. It features 5 reels and 25 paylines, with an assortment of fruit characters like
                        dancing pineapples and goofy bananas.&nbsp;</p>
                    <p>The Daredevil Feature, triggered by creating winning combinations, allows players to shoot the
                        daredevil strawberry through a rockmelon target to earn free spins and multipliers. Fruit Frenzy
                        combines classic slot appeal with lighthearted fun, perfect for those looking for a joyful
                        gaming experience.</p>
                </>,
                image: gameRoom4,
            },
            {
                title: "Clumsy Miner",
                detail: <>
                    <p>Clumsy Miner is an adventurous slot game where players follow a bumbling miner as he digs for
                        treasures. This game has 5 reels and 30 paylines, filled with mining gear, gems, and the clumsy
                        miner himself.&nbsp;</p>
                    <p>Clumsy Miner is an adventurous slot game where players follow a bumbling miner as he digs for
                        treasures. This game has 5 reels and 30 paylines, filled with mining gear, gems, and the clumsy
                        miner himself.&nbsp;</p>
                </>,
                image: gameRoom5,
            },
            {
                title: "Lady Hawk",
                detail: <>
                    <p>Lady Hawk is a mystical slot game set in a medieval fantasy world, featuring 5 reels and 40
                        paylines. The game centers around the enigmatic Lady Hawk, who transforms into a hawk under the
                        moonlight.&nbsp;</p>
                    <p>It offers stacked wilds, represented by Lady Hawk herself, and moon symbols that trigger free
                        spins with the potential for large multipliers. The ethereal atmosphere is enhanced by dramatic
                        graphics and a haunting soundtrack, appealing to players who love fantasy and adventure in their
                        slot gameplay.<br />
                        &nbsp;</p>
                </>,
                image: gameRoom6,
            },
            {
                title: "Mysterious Witch",
                detail: <>
                    <p>Mysterious Witch is a slot game that delves into the realm of magic and mysticism. With 5 reels
                        and 30 paylines, the game features symbols such as spell books, potions, and black
                        cats.&nbsp;</p>
                    <p>The highlight is the Witch’s Spell Bonus, where players choose ingredients to create spells for
                        bonuses and free spins. Scatter symbols activate the mysterious free spins round with expanding
                        wilds.&nbsp;</p>
                    <p>Mysterious Witch’s enchanting design and immersive sound effects create a spellbinding experience
                        suitable for fans of the supernatural genre.<br />
                        &nbsp;</p>
                </>,
                image: gameRoom7,
            },
            {
                title: "Enjoy an Exclusive Gaming Experience Now!",
                detail: <>
                    <p>For those seeking a reliable and entertaining online gaming platform, GameRoom Casino stands out
                        with its comprehensive game library and robust security measures.&nbsp;</p>
                    <p>The casino’s commitment to compliance with sweepstakes laws, coupled with its user-friendly
                        design and diverse payment options, provides a seamless and secure gaming experience.&nbsp;</p>
                    <p>Players can enjoy a wide range of games, from slots to table games, all enhanced by high-quality
                        graphics and catchy sound effects. GameRoom Casino’s regular promotions and engaging community
                        features make it an excellent choice for both new and experienced players.</p>
                </>,
                image: gameRoom8,
            }
        ],
        faqs: []
    },
    {
        id: 13,
        path: "/ultra-panda",
        name: "Ultra Panda",
        image: ultrapanda,
        sections: [
            {
                title: "Ultra Panda",
                detail: <>
                    <p>Ultra Panda Casino gives you everything you need. First, they put lots of effort into learning
                        what their players want. Then, they used that information to develop games that will blow your
                        mind!</p>
                    <p>The platform offers a secure payment system, a secure platform, and an incredible satisfaction
                        rate. Also, you can use the Ultra Panda apk to play the games on your mobile device.&nbsp;</p>
                    <p>But the real magic is the games they offer; it’s where the action is! Slot, fish, and&nbsp;<a
                        href="#" target="_blank">fish table
                        online</a>&nbsp;are just the beginning of what Ultra Panda Casino offers.&nbsp;</p>
                    <p>So, if you want to switch things up, you can always find something new to play. In addition, you
                        can enjoy games like Legendary Panda, Golden Tree Plus, and Fisherman's Wharf.</p>
                </>,
                image: ultraPanda0,
            },
            {
                title: "Ultra Panda Casino Download",
                detail: <>
                    <p>If you want to enjoy the best games at your convenience and on the go, consider the Ultra Panda
                        apk. The download process takes less than a minute, and you get access to the best Ultra Panda
                        free credits.&nbsp;</p>
                    <p>The Ultra Panda game download also comes with numerous bonuses, such as the&nbsp;<a
                        href="#" target="_blank">online casino no
                        deposit bonus</a>, which you can use to boost your winning chances. Furthermore, you'll be the
                        first to know about any bonus features on the casino app.&nbsp;</p>
                    <p>Contact customer support if you have questions about the Ultra Panda Casino app. They'll help you
                        solve whatever issue you might have.&nbsp;</p>
                </>,
                image: ultraPanda1,
            },
            {
                title: "Ultra Panda Casino Offers Word-Class Games to Enjoy",
                detail: <>
                    <p>This online casino is aesthetically one of the most impressive games available. In addition,
                        their games have mind-blowing visuals. Because of the immersive atmosphere, you'll feel like
                        you're in a real casino.&nbsp;</p>
                    <p>For instance, the player in Pagoda Tree must descend into the ocean depths in search of a
                        money-shaking tree.</p>
                    <p>For instance, the player in Pagoda Tree must descend into the ocean depths in search of a
                        money-shaking tree.</p>
                    <p>Don't like scuba diving? Join the ancient war of the Buffalo Thunder for a chance at great
                        benefits instead. So sign in with the Ultra Panda Casino login to access your account and start
                        playing.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: ultraPanda2,
            },
            {
                title: "Ultra Panda Casino Offers Full HD Visuals and Sound",
                detail: <>
                    <p>What Ultra Panda Sweepstakes offers you is almost the same as what you get at real slot machines.
                        That’s how high-quality their visuals and sound are. It perfectly simulates a real slot
                        machine.</p>
                    <p>The game's stunning visuals make it tough to take your eyes off the screen. The developers
                        collaborated with top-tier artists to create visually stunning games. So, if you&nbsp;<a
                            href="#" target="_blank">play
                            casino online for real money</a>, consider this casino platform.&nbsp;</p>
                </>,
                image: ultraPanda3,
            },
            {
                title: "Ultra Panda Casino Offers Daily Bonuses",
                detail: <>
                    <p>Get a 25% bonus when playing top-notch games on the platform daily. You don't need to fulfill any
                        requirements: all you have to do is sign up with Ultra Panda 777 and start playing your favorite
                        Ultra Panda games!</p>
                    <p>You can also get daily bonuses using the Ultra Panda Casino app!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: ultraPanda4,
            },
            {
                title: "Pool Party",
                detail: <>
                    <p>Pool Party is among the most exciting sweepstakes slots that you can try at Ultra Panda
                        Casino.&nbsp;</p>
                    <p><br />
                        The slot machine features five reels and three symbols per reel.&nbsp;</p>
                    <p>There are 20 paylines, all of which are constant. The total number of pay lines might reach 40
                        during one of the special bonus rounds. &nbsp;</p>
                    <p>A winning combination consists of three or more consecutively appearing similar symbols,
                        beginning on the leftmost reel. Cash awards are awarded only for winning combos on an enabled
                        payline.<br />
                        &nbsp;</p>
                </>,
                image: ultraPanda5,
            },
            {
                title: "Eyes of Fortune",
                detail: <>
                    <p>Eyes of Fortune is an exceptional online slot that features five rows and three reels. You can
                        play this amazing game on Ultra Panda and get a shot at the biggest jackpot. The theme of the
                        game is wild jungle, and the main character of the slot is the lion.&nbsp;</p>
                    <p>On top of that, there are other colorful symbols such as koi fish, lotus, Q, J, and K. All those
                        symbols have different coin values. So, make sure to check the pay table before you press the
                        spin button. Go ahead and try Eyes of Fortune on Ultra Panda Casino right now.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: ultraPanda6,
            },
            {
                title: "African Wildlife",
                detail: <>
                    <p>African Wildlife features one of the most attractive wildlife themes in the slot machine
                        industry. The game portrays the african wildlife, jungles and natural habitat. All the symbols
                        that you can find in African Wildlife are inspired by wild animals, as well as classic
                        icons.&nbsp;</p>
                    <p>You will encounter the king of the jungle -lion, giraffe, elephant, rhyno, K, Q, A, etc. Lion is
                        the wild icon in the game which can substitute for all the other symbols and activate the
                        multipliers.&nbsp;</p>
                    <p>There are five rows and three reels as well as 10 fixed pay lines in African Wildlife. You can
                        bet on all those win lines and start the game to earn the most lucrative rewards right
                        away.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: ultraPanda7,
            },
            {
                title: "Fascinating Sweepstakes in One Place",
                detail: <>
                    <p>Are you ready to play the most thrilling online casino games from one safe place? If so, let’s
                        talk about Ultra Panda Casino!</p>
                    <p>The Ultra Panda casino is definitely the platform of your dreams. It provides a flawless gaming
                        experience and allows you to enjoy fascinating gameplay without lags.</p>
                    <p>Moreover, from your very first step on this amazing website, you’ll be absolutely blown away by
                        the visuals. The cutting-edge graphics and outstanding visual effects bring real chill and
                        thrill of the luxurious Vegas casino.&nbsp;</p>
                    <p>So, whatever game you play from Ultra Panda’s rich game catalog, you will receive an authentic
                        gaming experience!</p>
                    <p>The security and simplicity of the platform make gaming even more fun. So, even if you play alone
                        or with your friends, you are going to love the environment!<br />
                        &nbsp;</p>
                </>,
                image: ultraPanda8,
            },
            {
                title: "A 50% Birthday Bonus is Yours to Keep!",
                detail: <>
                    <p>On your birthday, Ultra Panda Casino will give you a 50% bonus when you deposit. So why don't you
                        sign up and win more cash on your birthday? This bonus doesn't come with any requirements. It's
                        all yours to enjoy and make more money!</p>
                    <p>You don’t have to adhere to any requirements. And you can get this bonus when you play games
                        using the Ultra Panda game download.&nbsp;</p>
                </>,
                image: ultraPanda9,
            },
        ],
        faqs: [
            {
                title: "1. What is Ultra Panda Casino?",
                content: "It's a platform to enjoy the latest games, fantastic bonuses, and exclusive promotions. You can use the Ultra Panda free play to get these bonuses. You can also use the Ultra Panda Casino app to enjoy your favorite games on the go. All that is required of you is to sign up and start playing! ",
            },
            {
                title: "2. How do you create an Ultra Panda account?",
                content: "To create an account, Visit Cloud9 and click “Sign Up.” Then, enter all the relevant details and choose Ultra Panda as your preferred platform. After registration, you’ll receive a welcome bonus for playing any game you want and boosting your winning chances.   ",
            },
            {
                title: "3. How to get free money on Ultra Panda?",
                content: "You can get free money by signing up on the platform. Register on Cloud9, and choose Ultra Panda as your preferred platform if you're a new player. After registration, you'll get a signup bonus to play any game you want. You also get Ultra Panda free credits while playing on the platform.",
            },
            {
                title: "4. What kind of casino games can I play at Ultra Panda?",
                content: "You can play games like Book of Ra, Ocean Paradise, and Big Shot. Other games include Columbus Deluxe, Tiki Island, Rich Life, etc. Of course, there are hundreds of games available. So go through them and choose the ones you prefer. ",
            },
            {
                title: "5. Is Ultra Panda Casino safe?",
                content: "Yes. Look no further than this casino platform when looking for a gaming platform. Your personal information is always safe whether you play on a desktop computer or with the Ultra Panda apk. This gives you the peace of mind to focus on the game. ",
            },
            {
                title: "6. What types of payment methods does Ultra Panda Casino accept?",
                content: "Ultra Panda accepts cryptocurrencies like Bitcoin. So, if you're a new player, you'll have to learn&nbsp;<a href=\"#\" target=\"_blank\">how to buy Bitcoin</a>&nbsp;before depositing and playing your games. If you have questions, contact customer support for further assistance.",
            },
            {
                title: "7. How to add money to the Ultra Panda Casino account?",
                content: "Visit Cloud9 first, then select \"Transactions.\" You have three options to add money to your account. You can click \"Deposit\" and then select Ultra Panda. Enter the deposit amount, and click \"Add to Cart.\" ",
            },
        ]
    },
    {
        id: 14,
        path: "/admiral",
        name: "Admiral",
        image: admiral,
        sections: [
            {
                title: "Admiral",
                detail: <>
                    <p>Admiral Casino stands out from competitors due to its extensive sweepstakes library. In addition,
                        modern, High-quality games are available on the platform that will keep you occupied for a long
                        time.&nbsp;</p>
                    <p>Many games are available, and they all have great visuals and gameplay. You can play these games
                        in your browser without any issues. Or use the Admiral casino download to play on your mobile
                        device.&nbsp;</p>
                    <p>You can play various games, make deposits, and withdraw your winnings without worry on Admiral
                        because the platform is reliable and secure. This platform is the perfect place to relax and
                        play all the best Admiral slots you want while you win more cash.&nbsp;</p>
                </>,
                image: admiral0,
            },
            {
                title: "Amazing High-Definition Visuals and Audio",
                detail: <>
                    <p>If you know online casinos, Admiral Casino will feel just like home. And that’s because you’ll
                        get outstanding visuals and immersive sound that will create a memorable moment while you win
                        more cash rewards.&nbsp;</p>
                    <p>Developers invested a lot of time and dedication to ensure the games on the platform have the
                        best graphics and audio. So there's no doubt you'll enjoy Admiral slots and other games on the
                        platform.&nbsp;</p>
                </>,
                image: admiral1,
            },
            {
                title: "Excellent Customer Support",
                detail: <>
                    <p>It's common knowledge that Admiral casino is a top-notch platform that gives players the best
                        time. So Admiral has one of the best customer support to ensure your fun experience doesn't cut
                        short.&nbsp;</p>
                    <p>So if you have any issues, you can contact the support team whenever you want, and they'll assist
                        you. You can contact the team via live chat, email, and phone. It doesn't matter the nature of
                        the assistance you need; Admiral Casino is always ready to help you.&nbsp;</p>
                </>,
                image: admiral2,
            },
            {
                title: "All Your Winnings are Secured",
                detail: <>
                    <p>Having trouble making a withdrawal at an online casino is quite problematic. Because of the
                        possible downside size, players are wary of making wagers. Admiral has provided a safe
                        environment and a fully functional system to get your money instantly.&nbsp;</p>
                    <p>Having trouble making a withdrawal at an online casino is quite problematic. Because of the
                        possible downside size, players are wary of making wagers. Admiral has provided a safe
                        environment and a fully functional system to get your money instantly.&nbsp;</p>
                </>,
                image: admiral3,
            },
            {
                title: "Admiral Casino Uses the Latest and Innovative Technology",
                detail: <>
                    <p>Admiral casino only uses state-of-the-art technology to provide its players with the most modern
                        and reliable services possible. In addition, several reputable gaming authorities thoroughly
                        tested the safety of this technology before releasing it to the public.&nbsp;</p>
                    <p>Admiral’s high-definition visuals and immersive audio will engross you for a long time. Another
                        major selling point is the platform’s unique storylines, which make the game even more
                        captivating.&nbsp;</p>
                    <p>When searching for a reliable online casino, the software is crucial. Your game experience can be
                        hampered or enhanced depending on the conditions. It also becomes a serious issue if you don’t
                        know if you can cash out your winnings without any issues.&nbsp;</p>
                    <p>That’s why we recommend Admiral casino because you won’t face any of such issues. Customer
                        support is always there to assist you if you encounter any challenges. 1<br />
                        &nbsp;</p>
                </>,
                image: admiral4,
            },
            {
                title: "Admiral Casino Download",
                detail: <>
                    <p>Admiral casino download is necessary if you like Admiral slots on your mobile device. You can
                        download the casino app and access numerous casino games instantly. The Admiral casino download
                        doesn't take long, and you get numerous exclusive bonuses and promotions.&nbsp;</p>
                    <p>So playing your favorite Admiral slots on your casino app can help boost your winning chances
                        because of the bonuses you get!<br />
                        &nbsp;</p>
                </>,
                image: admiral5,
            },
            {
                title: "Incredible Bonuses and Promotions",
                detail: <>
                    <p>You can increase your chances of winning with Admiral's premium games by taking advantage of the
                        various bonus features and&nbsp;<a href="#" target="_blank">special
                            promotions</a>. You can play for fun, using the demo version, or for real money.&nbsp;</p>
                    <p>The bonuses Admiral online casino can help you get back in the game and boost your chances of
                        winning cash prizes. You can win bonuses like first deposit bonuses, welcome bonuses, referral
                        bonuses, etc.&nbsp;</p>
                    <p>The best part is that there are no requirements for receiving this bonus. Just create an account
                        with Admiral online casino, and you'll automatically get the bonus.&nbsp;</p>
                </>,
                image: admiral6,
            },
            {
                title: "All Your Winnings are Secured",
                detail: <>
                    <p>Having trouble making a withdrawal at an online casino is quite problematic. Because of the
                        possible downside size, players are wary of making wagers. Admiral has provided a safe
                        environment and a fully functional system to get your money instantly.&nbsp;</p>
                    <p>Because of encryption, your financial information is safe from prying eyes, and making
                        withdrawals is simple. In addition, get quick cash prizes when you play for free or real money
                        on the best games anytime, anywhere at Admiral online casino.&nbsp;</p>
                </>,
                image: admiral7,
            },
            {
                title: "Admiral Casino Offers a $10 Referral Bonus",
                detail: <>
                    <p>In addition to the numerous bonuses, the&nbsp;<a href="#"
                        target="_blank">referral program</a>&nbsp;is one
                        of the best offers on the Admiral online casino. Here’s how it works: if a friend uses your
                        referral link to sign up, you’ll get $10 each.&nbsp;</p>
                    <p>All you need to do is send the referral link to your friends, which is found on your account
                        page. A $10 bonus will be given to you and your friend if your friend signs up and makes a
                        minimum deposit of $10.&nbsp;</p>
                    <p>You can contact customer support if you have any questions. They are always ready to assist
                        you.&nbsp;</p>
                </>,
                image: admiral8,
            },
            {
                title: "Sign Up Today and Win More Cash Prizes at Admiral Casino",
                detail: <>
                    <p>Admiral Casino gives you the best gaming experience and a fantastic way to win more cash. This
                        casino platform offers you incredible bonuses, promos, exciting features, etc., to boost your
                        chances of winning.&nbsp;</p>
                    <p>Furthermore, your personal information is secured, providing you with the necessary privacy.
                        Also, there won't be anything to pull your attention away from the game. You can withdraw your
                        winnings any time you want without facing any problems.&nbsp;</p>
                    <p>If you have any concerns, you can contact the<a href="#"
                        target="_blank">&nbsp;customer
                        support</a>&nbsp;team, who'll quickly help you.&nbsp;</p>
                    <p>Register now at Admiral Casino and start your new adventure of fantastic gameplay and cash
                        rewards!&nbsp;</p>
                </>,
                image: admiral9,
            },
        ],
        faqs: []
    },
    {
        id: 15,
        path: "/ice7",
        name: "Ice7",
        image: ice7,
        sections: [
            {
                title: "ICE7",
                detail: <>
                    <p>Ice7 Casino is a fascinating online gaming platform with unique bonus characteristics. For the
                        most thrilling gaming experience, register on the website immediately and grant your place among
                        the winners.</p>
                    <p><br />
                        The Ice7 online casino is everything a player can wish for. The platform's bonus features,
                        alongside a comprehensive gaming catalog and impressive bonuses, are your ticket to the most
                        exciting playtime.&nbsp;</p>
                    <p><br />
                        If you are ready to try out the most immersive slots in the market, check out the site’s
                        essential characteristics below!</p>
                </>,
                image: ice70,
            },
            {
                title: "Ice7 Download",
                detail: <>
                    <p>To enjoy the Ice7 casino games to their fullest, you get numerous bonus features. And one of the
                        essential ones is the platform’s mobile compatibility.</p>
                    <p><br />
                        Right after registration and the Ice7 casino login, you are able to Ice7 download on various
                        devices. It means that whichever your comfort gadget is, you can play using them.&nbsp;</p>
                    <p><br />
                        And the experience while playing with your Android and iOS is as smooth as ever. Sign up
                        immediately, download the app, and enjoy stunning games right after Ice 7 Casino login.</p>
                </>,
                image: ice71,
            },
            {
                title: "Ice7 Online Casino: Secure Software",
                detail: <>
                    <p>Security is a must while gaming online. And that is well-known for the Ice 7 Casino. That’s why,
                        whenever you play here, you are guaranteed to receive a seamless experience that will be
                        memorable for years.</p>
                    <p>Still, the smooth gameplay is not the only aspect where the Ice7.com Casino excels. The&nbsp;<a
                        href="#" target="_blank">sweepstakes
                        software</a>&nbsp;system ensures that your information and the funds are absolutely safe from
                        leaking and hacker attacks. And with the safe gaming environment, you get a sense of security,
                        allowing you to focus on the gameplay and never anything else.</p>
                    <p>With all these features in mind, your can sit back, relax and enjoy the most exciting game
                        titles. So, sign up and start gaming right away!</p>
                </>,
                image: ice72,
            },
            {
                title: "Deposit Match Bonuses",
                detail: <>
                    <p>One of the most incredible types of promotions on the Ice7 casino is deposit match bonuses. This
                        particular bonus allows you to kick-start your gaming journey on the platform alongside a
                        welcome bonus.</p>
                    <p><br />
                        On your very first deposit, the Ice7 gives you an additional 50% deposit match-free credits.
                        However, the bonus doesn’t end here!</p>
                    <p><br />
                        On your second and third deposits, you will receive 20% more credits. So, to earn the maximum
                        out of these promotions, sign up immediately and deposit the amounts that will bring you the
                        most!</p>
                </>,
                image: ice73,
            },
            {
                title: "Ice7.com Casino: Activate Profitable Deals",
                detail: <>
                    <p>If you want to enjoy the most extreme gaming experience, then you must earn free credits. The
                        free money for the Ice7 Casino is available through many bonus offers.&nbsp;</p>
                    <p>These promotions include a welcome bonus to kick-start your gaming with a powerful force.
                        Moreover, you will love the referral programs allowing you to invite your friends on the
                        platform and enjoy playing with them while winning more.&nbsp;</p>
                    <p>Sign up right away and discover even more&nbsp;<a href="#"
                        target="_blank">promotions&nbsp;</a>along the
                        way to massive payouts!</p>
                </>,
                image: ice74,
            },
            {
                title: "Ice 7 Casino: Unique Sweepstakes To Play",
                detail: <>
                    <p>Are you just entering the gaming world? Or have you got a long and exciting experience playing
                        the most immersive casino games? Whichever your current state is, you will love the gaming
                        options you get after the Ice7 download.</p>
                    <p><br />
                        On this magnetic casino, you encounter the most stunning visual effects and graphics of the best
                        games in the market. On Ice7.com casino, you will discover titles from the best casino game
                        providers. So, try what you like. The choice is all yours!<br />
                        <br />
                        &nbsp;</p>
                </>,
                image: ice75,
            }
        ],
        faqs: [
            {
                title: "1. What is Ice 7 Casino?",
                content: "Ice7 Casino is an online sweepstakes platform that allows you to play the most amazing online casino slots on the seamless functioning platform. The casino has a mobile-friendly system ensures memorable playtime with various bonus offers.",
            },
            {
                title: "2. How to Register at Ice 7?",
                content: "Find the sign-up button in the upper right corner of Cloud9 and click on it. During the process, choose Ice7 as your main gaming platform. Finalize the process, download the Ice 7 app, and start playing right away.",
            },
            {
                title: "3. How to add money to Ice 7 Online Casino?",
                content: "Once you register on the Ice7 casino, it’s time to make the first deposit. For that, find the deposit button on your profile and click on it.<br/> To deposit money on your balance, you need to add your crypto wallet address to your account first.<br/>",
            },
            {
                title: "4. How to get free money on Ice 7?",
                content: "There are various ways to receive free money on Ice 7 casino. You can earn free cash with a welcome bonus, first three deposit match bonuses, referral programs, user role cashback, etc. Check all available promotions on Cloud9’s promotions page.",
            },
            {
                title: "5. How to play Ice 7 Games?",
                content: "To play Ice 7 games, you must register at the casino. After that, download the app and log in to your profile. For the best experience, gather bonuses and enter various impressive games with the free cash on your balance.",
            },
            {
                title: "6. How to win on Ice 7 Casino?",
                content: "To win on Ice7 Casino, make sure to choose the highest RTP games with high winning possibilities. Also, the more free credits you add to your account, the higher bettings you can make, and eventually, you will land higher prizes.",
            },
            {
                title: "7. How to download Ice 7 App for Android?",
                content: "To download the Ice7 app for Android, first register on the platform. Once you’re done, and your account is all set, you will receive a downloadable link from the platform’s customer service for your Android.",
            }
        ]
    },
    {
        id: 16,
        path: "/tai-chi-master",
        name: "Tai Chi Master",
        image: taichi,
        sections: [
            {
                title: "Tai Chi Master",
                detail: <>
                    <p>Tai Chi Master is a modern gaming platform with a plethora of perks to offer. The casino provides
                        a full-blown gaming experience online while offering a whole bunch of fascinating games in its
                        catalog.</p>
                    <p>What’s more exciting is that you can play them with free credits from the casino. The Tai Chi
                        combines a list of must-get bonuses with the easiest eligibility criteria.&nbsp;</p>
                    <p>All you have to do now is to sign up, get your bonuses, and start playing your favorite games for
                        free while winning cash!</p>
                </>,
                image: taichi0,
            },
            {
                title: "Tai Chi Master: Unique Visuals",
                detail: <>
                    <p>Tai Chi Master Casino offers a visual masterpiece. Whenever you enter this modern website, you
                        immediately realize how amazing online gaming can be.</p>
                    <p>Here, all the Tai Chi slot games are made with top-quality graphics and visual effects. In every
                        game, you are guaranteed to feel like you’re inside the game.</p>
                    <p>So, choose whichever title looks interesting and start gaming right away!</p>
                </>,
                image: taichi1,
            },
            {
                title: "Tai Chi Master Casino: Top-Notch Functionality",
                detail: <>
                    <p>Smooth gaming is a must when playing online. And Tai Chi Master understands that very well.
                        That’s why you can rest assured that you will receive the best while playing at the casino.</p>
                    <p><br />
                        Tai Chi uses HD-quality software to ensure your seamless gaming. And that quality becomes
                        visible the moment you register on the site.</p>
                    <p><br />
                        While playing here, you never encounter any lags or delays. Surfing on the website is
                        effortless. And the navigation doesn’t take much time either.&nbsp;</p>
                </>
                ,
                image: taichi2,
            },
            {
                title: "Tai Chi Free Play",
                detail: <>
                    <p>Tai Chi Master Casino loves to spoil you with surprises. And that becomes visible the moment you
                        enter the platform as you’re instantly bombarded with numerous bonuses!</p>
                    <p><br />
                        The Tai Chi free play combines numerous promotional opportunities. And most importantly, allows
                        you to play with these free cash and win real payouts.</p>
                    <p><br />
                        The eligibility criteria for the bonuses are the easiest. The first step to get them is to make
                        a gaming account. So, what are you waiting for? Register now for incredible opportunities!<br />
                        &nbsp;</p>
                </>,
                image: taichi3,
            },
            {
                title: "Deposit-Match Bonuses To Catch",
                detail: <>
                    <p>A welcome bonus is followed by the deposit match credits on the casino. These bonuses also help
                        you start and continue your gaming journey and ensure even more fun and fulfilling experiences
                        than you can ever get.</p>
                    <p><br />
                        On the first deposit at the casino, you get 50% additional cash. Besides, second and third
                        deposits add an extra 20% free cash to your balance.&nbsp;</p>
                    <p><br />
                        As you continue playing and depositing on the platform, more and more promotions become
                        available. So, register now, earn your bonuses, and start the most exciting gaming journey on
                        Tai Chi Master!</p>
                </>,
                image: taichi4,
            },
            {
                title: "Referral Program On Tai Chi Online Casino",
                detail: <>
                    <p>Another exceptional chance you get from the casino is a referral program. This unique promotion
                        allows you to invite your friends on the platform and play with them.<br />
                        And that’s not all!</p>
                    /> <p>Another exceptional chance you get from the casino is a referral program. This unique
                        promotion
                        allows you to invite your friends on the platform and play with them.<br />
                        And that’s not all!</p>
                </>,
                image: taichi5,
            },
            {
                title: "Tai Chi Slots APK",
                detail: <>
                    <p>One of the most exciting chances you get from this fantastic casino is mobile gaming. Thanks to
                        the platform’s modern software that we discussed above, you are able to download the Tai Chi
                        slots apk on your mobile device unproblematically.</p>
                    <p><br />
                        Register and start playing your favorite slot games from your comfort device. The Tai Chi app is
                        available on both - Android and iOS operating devices.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: taichi6,
            },
            {
                title: "Sign Up For Tai Chi Master",
                detail: <>
                    <p>The greatest adventure is yet to start. Waste no time and become a member of the Tai Chi Master
                        community by signing up for the platform right away!</p>
                    <p>Fill out the&nbsp;<a href="#" target="_blank">registration
                        form</a>&nbsp;on Cloud9 and Choose Tai Chi as your main gaming platform. Verify your account
                        and become eligible for non-stop bonuses and promotions from the casino.</p>
                    <p>Start playing now and ensure the most thrilling gaming experience!</p>
                </>,
                image: taichi7,
            }
        ],
        faqs: []
    },
    {
        id: 17,
        path: "/admiral",
        name: "Admiral",
        image: admiral,
        sections: [
            {
                title: "Admiral",
                detail: <>
                    <p>Admiral Casino stands out from competitors due to its extensive sweepstakes library. In addition,
                        modern, High-quality games are available on the platform that will keep you occupied for a long
                        time.&nbsp;</p>
                    <p>Many games are available, and they all have great visuals and gameplay. You can play these games
                        in your browser without any issues. Or use the Admiral casino download to play on your mobile
                        device.&nbsp;</p>
                    <p>You can play various games, make deposits, and withdraw your winnings without worry on Admiral
                        because the platform is reliable and secure. This platform is the perfect place to relax and
                        play all the best Admiral slots you want while you win more cash.&nbsp;</p>
                </>,
                image: admiral0,
            },
            {
                title: "Amazing High-Definition Visuals and Audio",
                detail: <>
                    <p>If you know online casinos, Admiral Casino will feel just like home. And that’s because you’ll
                        get outstanding visuals and immersive sound that will create a memorable moment while you win
                        more cash rewards.&nbsp;</p>
                    <p>Developers invested a lot of time and dedication to ensure the games on the platform have the
                        best graphics and audio. So there's no doubt you'll enjoy Admiral slots and other games on the
                        platform.&nbsp;</p>
                </>,
                image: admiral1,
            },
            {
                title: "Excellent Customer Support",
                detail: <>
                    <p>It's common knowledge that Admiral casino is a top-notch platform that gives players the best
                        time. So Admiral has one of the best customer support to ensure your fun experience doesn't cut
                        short.&nbsp;</p>
                    <p>So if you have any issues, you can contact the support team whenever you want, and they'll assist
                        you. You can contact the team via live chat, email, and phone. It doesn't matter the nature of
                        the assistance you need; Admiral Casino is always ready to help you.&nbsp;</p>

                </>,
                image: admiral2,
            },
            {
                title: "All Your Winnings are Secured",
                detail: <>
                    <p>Having trouble making a withdrawal at an online casino is quite problematic. Because of the
                        possible downside size, players are wary of making wagers. Admiral has provided a safe
                        environment and a fully functional system to get your money instantly.&nbsp;</p>
                    <p>Because of encryption, your financial information is safe from prying eyes, and making
                        withdrawals is simple. In addition, get quick cash prizes when you play for free or real money
                        on the best games anytime, anywhere at Admiral online casino.&nbsp;</p>

                </>,
                image: admiral3,
            },
            {
                title: "Admiral Casino Download",
                detail: <>
                    <p>Admiral casino download is necessary if you like Admiral slots on your mobile device. You can
                        download the casino app and access numerous casino games instantly. The Admiral casino download
                        doesn't take long, and you get numerous exclusive bonuses and promotions.&nbsp;</p>
                    <p>So playing your favorite Admiral slots on your casino app can help boost your winning chances
                        because of the bonuses you get!<br />
                        &nbsp;</p>

                </>,
                image: admiral4,
            },
            {
                title: "Incredible Bonuses and Promotions",
                detail: <>
                    <p>You can increase your chances of winning with Admiral's premium games by taking advantage of the
                        various bonus features and&nbsp;<a href="#" target="_blank">special
                            promotions</a>. You can play for fun, using the demo version, or for real money.&nbsp;</p>
                    <p>The bonuses Admiral online casino can help you get back in the game and boost your chances of
                        winning cash prizes. You can win bonuses like first deposit bonuses, welcome bonuses, referral
                        bonuses, etc.&nbsp;</p>
                    <p>The best part is that there are no requirements for receiving this bonus. Just create an account
                        with Admiral online casino, and you'll automatically get the bonus.&nbsp;</p>
                </>,
                image: admiral5,
            },
            {
                title: "Admiral Casino Offers Exceptional Gameplay",
                detail: <>
                    <p>You'll feel like playing in a real casino with Admiral online casino. You can play the Admiral
                        slots on your computer or mobile devices using the Admiral casino download without issues. It
                        takes less than a minute to set up the game and play.&nbsp;</p>
                    <p>After the initial setup, the process is easy to understand. After that, you can play any game,
                        from slots to blackjack. Admiral has developed the platform so you can have the best time of
                        your life.&nbsp;</p>
                </>,
                image: admiral6,
            },
            {
                title: "All Your Winnings are Secured",
                detail: <>
                    <p>Having trouble making a withdrawal at an online casino is quite problematic. Because of the
                        possible downside size, players are wary of making wagers. Admiral has provided a safe
                        environment and a fully functional system to get your money instantly.&nbsp;</p>
                    <p>Because of encryption, your financial information is safe from prying eyes, and making
                        withdrawals is simple. In addition, get quick cash prizes when you play for free or real money
                        on the best games anytime, anywhere at Admiral online casino.&nbsp;</p>
                </>,
                image: admiral7,
            },
            {
                title: "Admiral Casino Offers a $10 Referral Bonus",
                detail: <>
                    <p>In addition to the numerous bonuses, the&nbsp;<a href="#"
                        target="_blank">referral program</a>&nbsp;is one
                        of the best offers on the Admiral online casino. Here’s how it works: if a friend uses your
                        referral link to sign up, you’ll get $10 each.&nbsp;</p>
                    <p>All you need to do is send the referral link to your friends, which is found on your account
                        page. A $10 bonus will be given to you and your friend if your friend signs up and makes a
                        minimum deposit of $10.&nbsp;</p>
                    <p>You can contact customer support if you have any questions. They are always ready to assist
                        you.&nbsp;</p>
                </>,
                image: admiral8,
            },
        ],
        faqs: []
    },
    {
        id: 18,
        path: "/win-star",
        name: "Win Star",
        image: winstar,
        sections: [
            {
                title: "Win Star",
                detail: <>
                    <p>Winstar Casino offers an unmatched gaming experience, combining a comprehensive understanding of
                        player needs with exceptional casino games and exclusive features.&nbsp;</p>
                    <p>Enhance your bankroll while enjoying top-tier entertainment on a platform designed for optimal
                        player satisfaction.&nbsp;</p>
                    <p>Whether you're playing on a desktop or on the go, Winstar allows seamless gameplay. So, go ahead
                        and try it out to get firsthand experience!<br />
                        &nbsp;</p>
                </>,
                image: winStar0,
            },
            {
                title: "Immerse Yourself in a Variety of Sweepstakes Games",
                detail: <>
                    <p>Winstar online hosts a diverse range of premium casino games, each designed with unique
                        aesthetics and compelling gameplay. Utilize Winstar free play options to engage in these games
                        without risking your bankroll, enhancing your chances to win real money.</p>
                    <p>Even if you're new to online gaming, Winstar makes it easy to get started. Comprehensive
                        tutorials are available, which are especially convenient when playing on mobile through the<a
                            href="http://server.winstar99999.com:8009/" target="_blank"> Winstar download for
                            Android.</a></p>
                </>,
                image: winStar1,
            },
            {
                title: "Experience Superior Graphics, Animation, and Sound",
                detail: <>
                    <p>At Winstar 777, each game is crafted with the player in mind, featuring stunning graphics,
                        responsive controls, and minimal interruptions to keep you focused on winning. The intuitive
                        platform design ensures a smooth browsing experience, enriching your gameplay whether you're a
                        novice or a seasoned player.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: winStar2,
            },
            {
                title: "Secure and Accessible Gaming on Winstar 777",
                detail: <>
                    <p>Security is paramount at Winstar Casino, ensuring all player data and transactions are protected
                        with advanced security measures.&nbsp;</p>
                    <p>The platform is fully optimized for mobile play, allowing you to access your account and play
                        your favorite games using the Winstar download option for Android.&nbsp;</p>
                    <p>Winstar's commitment to security is matched by its dedication to accessibility, ensuring a
                        top-quality gaming experience regardless of device that you use to access the platform.&nbsp;
                        <br />
                        &nbsp;</p>
                </>,
                image: winStar3,
            },
            {
                title: "Streamlined Winstar Casino App Experience",
                detail: <>
                    <p>Downloading the Winstar app offers a gateway to premium gameplay on your mobile device.
                        Experience the best graphics and immersive sound just like the desktop version, enhanced with
                        exclusive features available through the Winstar download for Android.&nbsp;</p>
                    <p>If you encounter any issues with the app, the Cloud9 support team is ready to assist you
                        promptly.<br />
                        &nbsp;</p>
                </>,
                image: winStar4,
            },
            {
                title: "Play Safely and Win Big on Winstar Online",
                detail: <>
                    <p>Winstar ensures a safe gaming environment where you can confidently play without concerns about
                        data or financial security.&nbsp;</p>
                    <p>The platform supports various licensed and encrypted casino games, securing your funds and
                        personal information on this trustworthy online casino platform.<br />
                        &nbsp;</p>
                </>,
                image: winStar5,
            },
            {
                title: "Sign Up and Get Rewarded!",
                detail: <>
                    <p>Signing up at Cloud9 not only provides access to an exceptional range of games but also starts
                        you off with a generous<a href="#" target="_blank"> $25 welcome
                            bonus</a>. This bonus requires no specific conditions to start. So, go ahead and register at
                        Cloud9 to claim the sign up bonus which you can utilize to play the best sweepstakes games
                        available on Winstar.&nbsp;</p>
                </>,
                image: winStar6,
            },
        ],
        faqs: [
            {
                title: "What is Winstar Casino?",
                content: " offering an array of high-quality games like slots, fish table games, and classic table games. The platform is known for its stunning animations and sound, enhancing your gaming experience.",
            },
            {
                title: "How do I register at Winstar?",
                content: "Registering at Winstar is straightforward. Visit Cloud9. cc, click \"Register,\" fill in your details, select Winstar, and complete the verification process.",
            },
            {
                title: "How can I add money to my Winstar account?",
                content: <p>To add funds, visit Cloud9. cc click "Deposit," and follow the prompts to securely add
                    money via Bitcoin,<a href="#" target="_blank"> Litecoin</a> or<a
                        href="#" target="_blank"> Dogecoin</a>.</p>,
            },
            {
                title: "How can I play Winstar Casino?",
                content: "Simply log in using your Winstar login details, select your game, and start playing. For mobile access, download the Winstar app and check out the best sweepstakes games on your android device.",
            },
        ]
    },
    {
        id: 19,
        path: "/flamingo7",
        name: "Flamingo7",
        image: flamingo,
        sections: [
            {
                title: "Flamingo7",
                detail: <>
                    <p>The games on Flamingo 7 casino are some of the best available on any sweepstakes platform. It
                        offers cutting-edge games that are sure to keep you entertained for hours. Games like Jungle
                        Queen, Vegas Vacation, El Toro, etc., boast fantastic gameplay and stunning visuals.&nbsp;</p>
                    <p>These games are available for instant play within your browser, or you can download them from the
                        official website and play them instantly. This casino platform is solid, reliable, and
                        safe.&nbsp;</p>
                    <p>That means you won't have to worry about any problems making deposits or cashing out your
                        winnings while playing your favorite games. Instead, you can rest assured knowing they've got
                        your back when you play.&nbsp;</p>
                </>,
                image: flamingo0,
            },
            {
                title: "Flamingo 7 Sweepstakes Games offers Stunning Visuals",
                detail: <>
                    <p>Flamingo 7 is the place to go if you want to play the best games with the smoothest gameplay and
                        most stunning visuals. We prioritize making playing your favorite casino games as easy as
                        possible.&nbsp;</p>
                    <p>Whether you're a seasoned pro or starting, you'll feel right at home with Flamingo 7's selection
                        of high-quality games. It's meant for your enjoyment, whether alone or with your
                        friends.&nbsp;</p>
                    <p>This casino platform is committed to providing an enjoyable game experience with your
                        friends.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: flamingo1,
            },
            {
                title: "Play the Best Casino Games at Flamingo 7 Casino",
                detail: <>
                    <p>Flamingo 7 online casino provides access to the most cutting-edge games available today. Several
                        games are available for your consideration, each with unique features. Plus, there are no
                        interruptions to the flow of the game.&nbsp;</p>
                    <p>You and your friends can play&nbsp;<a
                        href="#" target="_blank">online
                        slot games for real money</a>&nbsp;without anyone interrupting your fun. Also, if you ever run
                        into trouble, you can always contact the helpful customer support staff for assistance.&nbsp;
                    </p>
                </>,
                image: flamingo2,
            },
            {
                title: "Try New Innovative Games on Flamingo7",
                detail: <>
                    <p>Flamingo7 is more than excited to announce the introduction of two new innovative games on the
                        platform. Flamingo 7 players are now able to enjoy the famous Aviator and Galactic Hunter
                        sweepstakes titles! Want to try out them immediately? Set up a Cloud9 account, pick
                        Flamingo7, enter the new games, and start winning big!<br />
                        &nbsp;</p>
                </>,
                image: flamingo3,
            },
            {
                title: "Flamingo 7 Casino Sets you Up for Success with Daily Bonuses",
                detail: <>
                    <p>When you sign up with Flamingo 7, you get a 25% daily bonus to keep you playing longer. That can
                        boost your winning chances as you continue having fun.&nbsp;</p>
                    <p>The best part is that you don't have to adhere to any requirements. So if you want to receive the
                        bonus, sign up with Flamingo 7, and you'll get the bonus.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: flamingo4,
            },
            {
                title: "Get a 50% Bonus on Your First Deposit.",
                detail: <>
                    <p>An instant 50% bonus is available to all new Flamingo 7 online casino account holders. So you'll
                        have the chance to play for longer, leading to more wins.&nbsp;</p>
                    <p>Fortunately, there are no eligibility requirements. So sign up, make your initial deposit, and
                        you'll automatically get the bonus.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: flamingo5,
            },
        ],
        faqs: []
    },
    {
        id: 20,
        path: "/mafia",
        name: "Mafia",
        image: mafia,
        sections: [
            {
                title: "Mafia",
                detail: <>
                    <p>Mafia is among the best sweepstakes platforms available on Cloud9. The platform offers an
                        exclusive gaming experience for players. When you enter Mafia Casino, you will be shocked by
                        engaging sweepstakes games from different genres, including slots, fish arcades, and<a
                            href="#" target="_blank"> casino card
                            games</a>.&nbsp;</p>
                    <p>Each game offers the best real money rewards that you can take advantage of once you become a
                        Mafia casino player. So, go ahead and register on BitBetWin to check out Mafia Sweepstakes!</p>
                </>,
                image: mafia0,
            },
            {
                title: "Pick Your Custom Avatar",
                detail: <>
                    <p>At Mafia Casino, we believe in making your gaming experience truly unique. Choose from a wide
                        range of custom avatars to represent your player profile.&nbsp;</p>
                    <p>Whether you want to be a suave mob boss or a daring gambler, our selection allows you to showcase
                        your style and personality as you navigate through our casino.<br />
                        &nbsp;</p>
                </>,
                image: mafia1,
            },
            {
                title: "Wheel of Fortune",
                detail: <>
                    <p>Spin the Wheel of Fortune for a chance to win big! This exciting feature resets every 8 hours,
                        giving you multiple opportunities throughout the day to test your luck and score incredible
                        prizes. Don't miss out on your chance to hit the jackpot with just a single spin.<br />
                        &nbsp;</p>
                </>,
                image: mafia2,
            },
            {
                title: "Access Favorite Games Instantly",
                detail: <>
                    <p>Keep your go-to games at your fingertips with the Favorite Tab. Add your favourite games to this
                        personalized section for quick and easy access, so you can jump right into the action without
                        searching. Your top picks are always just a click away.<br />
                        &nbsp;</p>
                </>,
                image: mafia3,
            },
            {
                title: "Colosseum",
                detail: <>
                    <p>Colosseum is a gladiator-themed slot game that transports players to ancient Rome's grand arena.
                        This game features 5 reels and 25 paylines, with symbols representing Roman warriors, shields,
                        and helmets.&nbsp;</p>
                    <p>The main feature, the Gladiator Duel, triggers a bonus round where players choose a fighter to
                        battle for coins and multipliers. Free spins are awarded with the fall of the Colosseum scatter
                        symbol, adding depth and excitement to the gameplay.&nbsp;</p>
                    <p>The Colosseum’s historical theme and engaging features make it a standout choice for those
                        fascinated by Roman history.</p>

                </>,
                image: mafia4,
            },
            {
                title: "Lucky Fortune",
                detail: <>
                    <p>Lucky Fortune is a slot game filled with symbols of good luck and prosperity, including golden
                        cats, lucky coins, and fortune cookies.&nbsp;</p>
                    <p>This game has 5 reels and 20 paylines, with a Wheel of Fortune feature that players can spin to
                        win bonus credits, multipliers, or free spins.&nbsp;</p>
                    <p>The Lucky Fortune feature offers a progressive jackpot when aligning special symbols. Its
                        colorful design and optimistic soundtracks contribute to an uplifting and enjoyable gaming
                        atmosphere, perfect for players looking to attract good fortune in their gameplay.<br />
                        &nbsp;</p>
                </>,
                image: mafia5,
            },
            {
                title: "Lion Dance",
                detail: <>
                    <p>Lion Dance is an exhilarating slot game that celebrates the traditional Chinese lion dance
                        believed to bring good luck and fortune. Set against the backdrop of a festive street
                        celebration, this game features 5 reels and 40 paylines.&nbsp;</p>
                    <p>Players can unlock various features like the Fireworks Festival, where random symbols explode to
                        turn into wilds, and the Lantern Bonus, offering free spins and stacked wilds.&nbsp;</p>
                    <p><br />
                        Lion Dance’s vibrant graphics and energetic soundtrack perfectly capture the essence of Chinese
                        celebrations, providing a culturally rich and engaging slot experience.<br />
                        &nbsp;</p>
                </>,
                image: mafia6,
            },

        ],
        faqs: []
    },
    {
        id: 21,
        path: "/golden-dragon",
        name: "Golden Dragon",
        image: goldenDragon,
        sections: [
            {
                title: "Golden Dragon",
                detail: <>
                    <p>Golden Dragon is among the best sweepstakes platforms in the gaming market. Choose the casino for
                        an unforgettable gaming experience and real money prizes!</p>
                    <p><br />
                        You can play the Golden Dragon game on desktop computers and mobile devices running a wide range
                        of operating systems, including Apple, Google, Microsoft, and BlackBerry. As a result, you are
                        guaranteed to enjoy every moment on the platform. &nbsp;</p>
                    <p><br />
                        Your gaming experience when entering any Golden Dragon game online will be absolutely magical.
                        You’ll be blown away by the games’ visual and sound effects, unique characteristics, and bonus
                        features.</p>
                    <p><br />
                        To play Golden Dragon, you can download Golden Dragon app immediately. Here are steps on how to
                        access the Golden Dragon mobile game:</p>
                </>,
                image: goldenDragon0,
            },
            {
                title: "Excellent Customer Support",
                detail: <>
                    <p>It's no secret that the Golden Dragon Casino app is a fantastic platform providing the best
                        gaming experience possible. However, as it is an online gambling platform, you may still
                        encounter some issues from time to time. That's why the platform has top-tier&nbsp;<a
                            href="#" target="_blank">customer support</a>&nbsp;to solve any
                        trouble right away.&nbsp;</p>
                    <p>You can reach the support staff anytime if you encounter problems, and they will respond quickly
                        to help you. You can contact them via email, chat, or phone. Assistance with account creation,
                        fund transfers, withdrawals, etc., is available across all platforms.</p>

                </>,
                image: goldenDragon1,
            },
            {
                title: "Golden Dragon App Download iPhone",
                detail: <>
                    <p>If you're an iPhone user eager to experience thrilling gambling on the platform, it’s time to use
                        the Golden Dragon sweepstakes add money feature and download Golden Dragon on your
                        iOS.&nbsp;</p>
                    <p><br />
                        By following the necessary steps to install the Golden Dragon online casino on your mobile
                        device, you'll have the exceptional features of the platform right at your fingertips. There's
                        no need to wait; initiate the Golden Dragon casino download now and dive into the Golden
                        Dragon’s immersive universe.<br />
                        &nbsp;</p>
                </>,
                image: goldenDragon2,
            },
            {
                title: "Golden Dragon Free Credits",
                detail: <>
                    <p>While savoring exceptional Golden Dragon fish games, don't miss the opportunity to make the most
                        of the Golden Dragon free credits. Golden Dragon offers casino bonuses, an excellent way to
                        enhance your enjoyment at online casinos.</p>
                    <p><br />
                        New players are greeted with a welcome gift worth up to $230, and there are additional bonuses
                        such as Quest Achievement and Referral bonuses.</p>
                    <p><br />
                        These Golden Dragon free credits can enhance your winning prospects as you engage in your
                        favorite Golden Dragon games online. These bonus funds are yours to use as you see fit.<br />
                        &nbsp;</p>
                </>,
                image: goldenDragon3,
            },
            {
                title: "How To Play Golden Dragon Mobile:",
                detail: <>
                    <p>To play the GoldenDragon game, you have to have a gaming account on the platform. To do so, you
                        must register via Cloud9, proceed with Golden Dragon Sign Up, and finalize your account.</p>
                    <p>Once your profile is ready, it’s time for the Golden Dragon app download for Android free
                        download. Then, you can enter any Golden Dragon arcade or slot games you want to play.
                        Both&nbsp;<a href="#"
                            target="_blank">fish arcade games</a>&nbsp;and slots have simple outlines, and all
                        their features are explained in paytables.&nbsp;</p>
                    <p>Keep in mind that you should learn about each game’s characteristics and bonus features. Utilize
                        all the free playing opportunities and turn your Golden Dragon mobile gaming experience into a
                        real celebration.</p>
                </>,
                image: goldenDragon4,
            },
            {
                title: "Safe and Secure Platform",
                detail: <>
                    <p>If you're searching for a trustworthy online casino, the Golden Dragon game online platform is an
                        excellent option. It's completely safe to make deposits and cash out your earnings. In addition,
                        this online platform has legal gambling licenses and employs strong safety standards, turning
                        itself into a risk-free and reliable option.</p>
                    <p><br />
                        As a result, you can unwind while enjoying your preferred Golden Dragon games and increase your
                        chances of winning big.<br />
                        &nbsp;</p>

                </>,
                image: goldenDragon5,
            },
            {
                title: "Why Choose Golden Dragon?",
                detail: <>
                    <p>Choosing a mobile Golden Dragon platform for your gaming has many benefits. The combination of
                        some of the platform’s core features makes the Golden Dragon game app the best destination for
                        playing online casino games. Let’s review some of these features in detail.<br />
                        &nbsp;</p>
                </>,
                image: goldenDragon6,
            },
            {
                title: "Golden Dragon App Download for Android",
                detail: <>
                    <p>Golden Dragon's online casino is readily accessible on Android devices. You can dive right in,
                        complete your Golden Dragon casino login, and immerse yourself in the world of Golden Dragon
                        games.</p>
                    <p><br />
                        For those seeking a remarkable mobile gaming experience, fasten your seatbelts and download
                        Golden Dragon app for Android directly from the Cloud9 website. The platform’s ready to
                        deliver top-quality sweepstakes to you.<br />
                        &nbsp;</p>
                </>,
                image: goldenDragon12,
            },
        ],
        faqs: []
    },
    {
        id: 22,
        path: "/game-vault",
        name: "Game Vault",
        image: gameVault,
        sections: [
            {
                title: "Game Vault",
                detail: <>
                    <p>To play the latest cutting-edge casino games, you should check out Game Vault. You'll find
                        nothing but the best in this casino platform.&nbsp;In addition, the platform has upgraded its
                        offerings to provide the optimal gaming experience for its players.&nbsp;&nbsp;</p>
                    <p>This casino platform is the best site to play exciting games and win cash. You can also use the
                        Game Vault login free play, so give this opportunity some thought.&nbsp;&nbsp;</p>
                    <p>You can also play on your mobile device and get exclusive promo offers after the Game Vault 777
                        APK download iOS. Read more info about the platform, start the Game Vault sign up process, and
                        enjoy unique offers while having fun!</p>
                </>,
                image: gameVault0,
            },
            {
                title: "Enjoy Lots Of Bonuses And Promotions From The Game Vault Sweepstakes",
                detail: <>
                    <p>Bonus opportunities are overflowing when starting the GameVault 777 play online login via
                        Cloud9 Casino. This simple step makes you eligible for countless exclusive bonuses that will
                        make your gaming experience absolutely thrilling.</p>
                    <p>The Game Vault free play no deposit bonus offers will amaze you. The Game Vault casino sign up
                        bonus is the first amazing promotion you receive from the platform. It allows you to kick-start
                        your gaming journey and explore fascinating titles while playing the Game Vault 777 online.</p>
                    <p>Other exciting promotions the Game Vault online login bonus offers incorporate include
                        deposit-match bonuses, referral programs, daily bonus codes, and loyalty cashback. Join the Game
                        Vault 777 casino right away and receive exclusive deals to enjoy playing for free!</p>
                </>,
                image: gameVault1,
            },
            {
                title: "Game Vault 777 Uses Simple-to-Use Design",
                detail: <>
                    <p>You won't have issues navigating the Game Vault online casino. In addition, you can easily
                        understand the game, especially if you're a new player.&nbsp;&nbsp;</p>
                    <p><br />
                        You don't have to be a veteran player to navigate the platform. Everything is easy and upfront,
                        so you won't need any assistance.&nbsp;&nbsp;</p>
                    <p><br />
                        The casino app also has a seamless interface. So, you can easily search for games, deposit
                        funds, withdraw cash, etc.&nbsp;&nbsp;Register now and check it out yourself!</p>
                </>,
                image: gameVault2,
            },
            {
                title: "Game Vault Download for Mobile Devices",
                detail: <>
                    <p>You can use the Game Vault casino download page to download the casino app and play your favorite
                        online slot games for real money on your mobile device.&nbsp;&nbsp;</p>
                    <p><br />
                        You'll get instant access to all the top casino games on your mobile device. Don't worry; the
                        games on the Game Vault casino download have the same HD quality as on the desktop
                        casino.&nbsp;&nbsp;</p>
                    <p><br />
                        All you have to do is use the Game Vault 999 play online login to access your account and start
                        playing!&nbsp;&nbsp;</p>
                </>,
                image: gameVault3,
            },
            {
                title: "Game Vault Casino Uses the Latest Technology",
                detail: <>
                    <p>Game Vault APK uses the latest innovative systems to provide customers with an exceptional gaming
                        experience.&nbsp;The app's cutting-edge technology elevates the gaming experience to new heights
                        of difficulty and excitement.&nbsp;&nbsp;Online casino software and games have been optimized
                        for optimal performance over many years.&nbsp;&nbsp;</p>
                    <p><br />
                        As a result, the GameVault 777 APK download is a fantastic way to combine amusement and cash
                        rewards.&nbsp;In players' eyes, this system is superior to others since the game's high quality
                        extends to every facet of the experience, from graphics to the storyline.&nbsp;&nbsp;</p>
                    <p><br />
                        All of the casino software used by the Game Vault app is of the highest quality and is most
                        recently released. As a result, you can put your worries to rest and start enjoying yourself
                        because the gameplay is swift and secure.&nbsp;&nbsp;</p>
                </>,
                image: gameVault4,
            },
            {
                title: "Game Vault Online Casino Offers Excellent Customer Support",
                detail: <>
                    <p>The quality of the service provided to players is one of this platform's best points. If you ever
                        run into trouble, their helpful <a href="#"
                            target="_blank">support </a>staff is always there to assist
                        you.&nbsp;For example, if you're having trouble with registration, withdrawals, deposits, or the
                        Game Vault apk download feature, ask customer support for help, and they will effectively solve
                        your issues.&nbsp;&nbsp;</p>
                    <p>Furthermore, you can always reach customer support through email, phone, or live chat. The
                        response time is the same regardless of the medium you choose.&nbsp;</p>
                    <p>If you have any issues with the Game Vault apk download link, the support team can help you
                        resolve them.&nbsp;&nbsp;</p>
                </>,
                image: gameVault5,
            },

        ],
        faqs: [
            {
                title: "What is Game Vault Casino?",
                content: "Game Vault is an online gaming platform that provides you with the best sweepstakes games, numerous bonuses, and promotions. You can play the games on your mobile devices using the Game Vault apk download, and you can cash out your winnings quickly.",
            },
            {
                title: "Is Game Vault legal?",
                content: "Yes. This casino platform is legal. They have the necessary licenses from reputable gaming authorities to operate. So your personal information is safe, including your activities. Your deposits and withdrawals are also secure.",
            },
            {
                title: "Is the Game Vault app safe?",
                content: "Yes. This online casino is safe because they have all the necessary security measures to ensure your time is fun and goes smoothly without any issues. They use the latest encryption to ensure your transactions, deposits, and withdrawals are always safe.",
            },
            {
                title: "How to get free money on Game Vault Casino?",
                content: "You must select Game Vault Casino as your chosen platform while registering for Cloud9 as a new player. When you sign up, the platform will provide you with a bonus to use on any game. Then, as you continue to play, you can take advantage of the best no-deposit bonus codes to further increase your earning potential.",
            },
            {
                title: "How can I win on Game Vault Casino?",
                content: "Using free casino games as a practice tool is the best approach to improve your chances of winning when playing for real money. After getting comfortable with the game, you can start betting real money on it. That can improve your familiarity with the game and boost your winning chances.",
            },
            {
                title: "How can I play Game Vault Sweepstakes?",
                content: "Use the GameVault online login to sign in to your account. After signing in, select and play any sweepstakes games you want. You can use the casino's bonuses and promotions to increase your chances of winning. Also, you can play on your mobile device using the Game Vault apk.",
            },
        ]
    },
    {
        id: 23,
        path: "/lucky-stars",
        name: "Lucky Stars",
        image: luckyStar,
        sections: [
            {
                title: "Lucky Stars",
                detail: <>
                    <p>Discover the magic of online gaming at Lucky Stars Casino, where every player is treated to a
                        world-class gaming experience. The casino offers a rich selection of games, top-tier security,
                        and exceptional customer service, making it a preferred choice for many online gaming
                        enthusiasts. With its user-centric design and innovative features, Lucky Stars Casino ensures
                        that every gaming session is memorable and enjoyable.</p>
                </>,
                image: luckyStar0,
            },
            {
                title: "Wide Range of Slots",
                detail: <>
                    <p>Lucky Stars Casino is particularly known for its extensive range of slot games. Players can
                        explore hundreds of slot titles, each offering unique themes, bonus features, and payout
                        potentials. From classic fruit machines to modern<a
                            href="#"
                            target="_blank"> progressive slots</a> with immersive storylines, the casino's slot
                        collection caters to all tastes and preferences.</p>
                </>,
                image: luckyStar1,
            },
            {
                title: "Robust Security Protocols",
                detail: <>
                    <p>Security is paramount at Lucky Stars Casino. The platform uses advanced encryption technologies
                        to safeguard all transactions and personal data. Regular audits by independent agencies ensure
                        that the games are fair and the outcomes are random, providing players with a safe and secure
                        gaming environment.<br />
                        &nbsp;</p>
                </>,
                image: luckyStar2,
            },
            {
                title: "User-Centric Design",
                detail: <>
                    <p>The design of Lucky Stars Casino is focused on user convenience. The website's layout is clean
                        and organized, making it easy for players to navigate through different sections. The search and
                        filter options allow players to quickly find their favorite games, enhancing the overall user
                        experience.</p>
                </>,
                image: luckyStar3,
            },
            {
                title: "Reliable Payment Methods Lucky Stars Casino supports a",
                detail: <>
                    <p>Lucky Stars Casino supports a variety of reliable payment methods, including<a
                        href="#" target="_blank"> cryptocurrencies</a>.
                        This flexibility ensures that players can easily deposit and withdraw funds in a manner that
                        suits them best. The casino's commitment to fast and secure transactions further adds to its
                        appeal.</p>
                </>,
                image: luckyStar4,
            },
            {
                title: "Retro Reels",
                detail: <>
                    <p>Retro Reels transports players back to the golden age of slot machines with its classic design
                        and straightforward gameplay. This game features five reels and 20 paylines adorned with
                        traditional slot symbols like bars, bells, and sevens.&nbsp;</p>
                    <p>It offers a re-spin feature, allowing players to re-spin any reel for a chance to complete
                        winning combinations. With a nostalgic theme and potential for substantial payouts, Retro Reels
                        is perfect for those who appreciate vintage-style slots with modern enhancements.<br />
                        &nbsp;</p>
                </>,
                image: luckyStar5,
            },
            {
                title: "Wolf Run",
                detail: <>
                    <p>Wolf Run is a popular slot game featuring a wildlife theme centered around wolves. The game has
                        five reels and 40 paylines, with symbols including wolves, totems, and traditional card
                        values.&nbsp;</p>
                    <p>Key features include free spins and stacked wilds, which can significantly increase the chances
                        of winning. The game's captivating graphics and immersive sound effects create an engaging
                        gaming experience that appeals to both novice and seasoned players.</p>
                </>,
                image: luckyStar6,
            },
        ],
        faqs: []
    },
    {
        id: 24,
        path: "/vegas7",
        name: "Vegas7",
        image: vegas7,
        sections: [
            {
                title: "Vegas7",
                detail: <>
                    <p>Vegas 7 is an online casino platform offering the most thrilling games. There are over 270
                        interactive Vegas 7 Casino games ranging from video poker, online slots, blackjack, and
                        roulette, which you can find after the Vegas7 login.</p>
                    <p>Vegas Games 7 is highly secure and keeps perfecting its games. So, you always have the best
                        games, bonuses, and promos. On top of that, you get to play with your friends and have the best
                        time of your life! Just sit back, relax, and enjoy the best online casino gaming experience of
                        your life after Vegas7 online login. The Vegas7.online key features include:</p>
                    <ul>
                        <li>Up-to-date gaming library with unique titles;</li>
                        <li>HD encryption system for guaranteed safety;</li>
                        <li>Fast and safe transaction method;</li>
                        <li>Vegas 7 Casino no deposit bonus to kick-start gaming;</li>
                        <li>Vegas7 download apps for different operating system mobiles;</li>
                        <li>Smooth, lag-free gaming environment;</li>
                        <li>Top-notch visual and sound effects for an authentic casino experience;</li>
                        <li>Active customer service 24/7;</li>
                    </ul>
                </>,
                image: vegas70,
            },
            {
                title: "Impressive Bonuses & Promotions",
                detail: <>
                    <p>You can earn lots of online casino bonuses and promotions from Vegas7 slots online. Of course,
                        you can play the games for fun with your friends. But the bonuses, rewards, and promos can add
                        to the fun. So you shouldn’t ignore it.</p>
                    <p>For example, you can get a daily bonus, making the game fun. In addition, you can get a 25% bonus
                        to increase your odds of winning MORE CASH!&nbsp; Other exciting Vegas7 com casino promotions
                        include:</p>
                    <ul>
                        <li>Vegas 7 Casino no deposit bonus;</li>
                        <li>50% deposit match free credits;</li>
                        <li>20% deposit match for 2nd and 3rd deposits;</li>
                        <li>50% deposit-match birthday bonus for your special day;</li>
                        <li>Referral program to claim extra credits for each invited friend;</li>
                    </ul>
                </>,
                image: vegas71,
            },
            {
                title: "Vegas 7 Casino Download",
                detail: <>
                    <p>One of the best ways to enjoy Vegas 7 Casino is to use the Vegas 7 Casino download. This allows
                        you to play your favorite games on your mobile device. The download and installation process
                        takes less than a minute.</p>
                    <p>So you can quickly sign in, get Vegas7 com free credits, and enjoy more wins! Using the Vegas 7
                        casino download gives you access to exclusive promo codes. You can use these bonuses to play any
                        game and boost your chances of winning. If you have any questions, contact customer support, and
                        they’ll assist you.</p>
                </>,
                image: vegas72,
            },
            {
                title: "Innovative & Latest Technology",
                detail: <>
                    <p>Regarding technology, Vegas 7 online casino only provides the latest. Experts have thoroughly
                        examined it before releasing it to the public to ensure the safety of this innovative
                        technology. So you can expect no lags or issues when playing your favorite games.</p>
                    <p><br />
                        The high-definition visuals and immersive audio of Vegas 7 software will immerse you in the game
                        for a long time.<br />
                        &nbsp;</p>
                </>,
                image: vegas73,
            },
            {
                title: "Safety And Security",
                detail: <>
                    <p>Security is a crucial factor you must consider while picking an online casino to play in.
                        Fortunately, you do not have to worry about security issues while playing on this platform.</p>
                    <p><br />
                        Vegas7 Games takes necessary security measures so players can come, play the best sweepstakes
                        games, and seamlessly navigate the site without any privacy concerns. Maintaining fairness and
                        user safety is a top priority for Vegas7 Games.</p>
                </>,
                image: vegas74,
            },
            {
                title: "Excellent Customer Support On Vegas7 Com",
                detail: <>
                    <p>Vegas Seven Games is all about offering a seamless experience. Therefore, it’s not surprising
                        that their Vegas7 online login app is refined to perfection.&nbsp;</p>
                    <p>However, the platform still considers the possibility of you having misunderstandings on minor
                        issues during gaming. That’s why they have active customer service ready to help 24/7.</p>
                    <p>Vegas7.online provides timely solutions for any type of trouble you may have. So, don’t hesitate
                        to <a href="#" target="_blank">contact us</a> from the live chat on
                        the website.</p>
                </>,
                image: vegas75,
            },
        ],
        faqs: []
    },
    {
        id: 25,
        path: "/vegas-volts",
        name: "Vegas Volts",
        image: vegasVolts,
        sections: [
            {
                title: "Vegas Volts",
                detail: <>
                    <p>Welcome to Vegas Volts, your ultimate destination for an unparalleled online casino experience.
                        At Vegas Volts, we bring the electrifying energy of Las Vegas straight to your fingertips,
                        offering a vast array of games, exciting bonuses, and top-notch customer service. Whether you’re
                        a seasoned player or new to the world of online casinos, Vegas Volts provides a secure and
                        thrilling platform to enjoy your favorite games.</p>
                </>,
                image: vegasVolts0,
            },
            {
                title: "Vegas Volts Has an Extensive Gaming Library",
                detail: <>
                    <p>At Vegas Volts casino, the game library is a treasure trove for casino enthusiasts. From timeless
                        classics like blackjack and roulette to the latest video slots with stunning graphics and
                        immersive soundtracks, the variety is endless. Top game providers like NetEnt, Microgaming, and
                        Play’n GO ensure that players have access to high-quality and innovative games​. So, by
                        partnering with those brands, Vegas Volts managed to add hundreds of unique games to its
                        arsenal.&nbsp;</p>
                </>,
                image: vegasVolts1,
            },
            {
                title: "Mobile Compatibility",
                detail: <>
                    <p>In today’s fast-paced world, the ability to play on the go is crucial. VegasVolts’
                        mobile-friendly website ensures that players can enjoy their favorite <a
                            href="#" target="_blank">sweepstakes casino
                            games</a> anywhere, anytime. The mobile platform maintains the same high-quality graphics
                        and functionality as the desktop version, providing a smooth gaming experience​.</p>
                </>,
                image: vegasVolts2,
            },
            {
                title: "Dedicated Customer Support",
                detail: <>
                    <p>Vegas Volts prides itself on <a href="#" target="_blank">excellent
                        customer service.</a> The support team is available around the clock to assist with any issues
                        or questions. Whether you prefer live chat, email, or phone support, help is always just a click
                        or call away​.</p>
                </>,
                image: vegasVolts3,
            },
            {
                title: "Sign Up Now at Vegas Volts!",
                detail: <>
                    <p>Explore the vibrant world of Vegas Volts casino today and experience the thrill of top-tier
                        online casino gaming. With its extensive game selection, generous bonuses, and commitment to
                        player satisfaction, Vegas Volts is your go-to destination for an unforgettable gaming
                        adventure.</p>
                </>,
                image: vegasVolts4,
            },
            {
                title: "Get Progressive Jackpots at Vegas Volts Casino",
                detail: <>
                    <p>For those dreaming of huge wins, progressive jackpot slots like Mega Moolah are a must-play.
                        These games pool a portion of each bet into a cumulative jackpot, which can be won randomly.
                        Mega Moolah, for instance, holds the record for the largest online jackpot payout, making it a
                        perennial favorite among players​​.</p>
                </>,
                image: vegasVolts5,
            },
            {
                title: "Vegas Volts Offers High RTP Slots",
                detail: <>
                    <p>Players looking for better odds will appreciate Vegas Volts' selection of <a
                        href="#" target="_blank">high-RTP slots</a>.
                        Games like Starburst (96.09% RTP) and Book of Dead (96.21% RTP) offer higher chances of returns,
                        making them popular choices among players seeking frequent wins​. If you want to play the latest
                        as well as the most profitable speedy casino slots, go ahead and check out Vegas Volts.&nbsp;
                    </p>
                </>,
                image: vegasVolts6,
            },
            {
                title: "Vegas Volts Offers Secure Payment Options",
                detail: <>
                    <p>Safety and convenience in transactions are paramount at VegasVolts. The casino offers a variety
                        of payment methods, including traditional options like as well as modern alternatives like
                        cryptocurrencies. All transactions are secured with advanced encryption technology, ensuring
                        your financial data is protected​.&nbsp;</p>
                </>,
                image: vegasVolts7,
            },
        ],
        faqs: []
    },
    {
        id: 26,
        path: "/ace-book",
        name: "Ace Book",
        image: aceBook,
        sections: [
            {
                title: "Ace Book",
                detail: <>
                    <p>Step into Ace Book Casino, where every player is treated to an exceptional gaming paradise. With
                        a wide variety of games, seamless user experience, and top-notch customer support, we ensure
                        that your time with us is nothing short of spectacular. Register today and start your journey
                        toward incredible wins and unforgettable moments.</p>
                </>,
                image: aceBook0,
            },
            {
                title: "Extensive Game Library",
                detail: <>
                    <p>Ace Book Casino offers a vast selection of<a
                        href="#" target="_blank"> high payout casino
                        games</a>, including popular slots, immersive fish games, and classic table games like roulette
                        and keno. Our game library is constantly updated to provide fresh and exciting options for every
                        type of player.&nbsp;</p>
                    <p>Whether you enjoy high-stakes challenges or casual gameplay, our diverse collection ensures
                        there's something for everyone, keeping the experience engaging and fun.&nbsp;</p>
                </>,
                image: aceBook1,
            },
            {
                title: "High-Quality Graphics",
                detail: <>
                    <p>Our games feature high-quality graphics and captivating animations, ensuring an immersive gaming
                        experience that rivals any physical casino. The visual and auditory elements are meticulously
                        crafted to provide a realistic and engaging environment.&nbsp;</p>
                    <p>This attention to detail enhances the overall player experience, making every spin or bet feel
                        more exciting and rewarding. Players can expect smooth animations and visually stunning effects
                        that bring each game to life.&nbsp;</p>
                </>,
                image: aceBook2,
            },
            {
                title: "Mobile Compatibility",
                detail: <>
                    <p>Play your favorite games on the go with Ace Book Casino's fully optimized mobile app. Whether
                        you're using an iOS or Android device, enjoy seamless gameplay anytime, anywhere.&nbsp;</p>
                    <p>The app is designed to provide a smooth and intuitive user experience, allowing you to access all
                        the features and<a href="#"
                            target="_blank"> high rtp slots</a> without any compromises on performance.
                        This flexibility ensures that you can enjoy the thrill of gaming no matter where you are.&nbsp;
                    </p>
                </>,
                image: aceBook3,
            },
            {
                title: "User-Friendly Interface",
                detail: <>
                    <p>Navigate through our platform with ease thanks to our intuitive interface. Quickly find and play
                        your favorite games without any hassle. The interface is designed to be straightforward and
                        accessible, ensuring that even new players can get started quickly and easily.&nbsp;</p>
                    <p>Clear menus, easy-to-read icons, and a logical layout contribute to a stress-free gaming
                        experience, making it enjoyable to explore all that Ace Book Casino has to offer.&nbsp;</p>
                </>,
                image: aceBook4,
            },
            {
                title: "Panda Magic",
                detail: <>
                    <p>Enter a mystical bamboo forest with Panda Magic, a game that features adorable pandas and magical
                        symbols leading to incredible rewards. The game's enchanting graphics and soothing music create
                        a serene and captivating atmosphere.&nbsp;</p>
                    <p>Spin the reels and watch as the pandas' magic brings you luck and fortune. With exciting bonus
                        rounds and charming animations, Panda Magic offers a delightful and rewarding gaming experience.
                        Discover the wonders of the bamboo forest, and let the magic of the pandas guide you to big
                        wins!<br />
                        &nbsp;</p>
                </>,
                image: aceBook5,
            },
            {
                title: "Jungle Rush",
                detail: <>
                    <p>Feel the adrenaline of the wild in Jungle Rush, a game that takes you deep into the jungle where
                        untold treasures await. The game features lush foliage and vibrant wildlife that bring the
                        jungle to life. Spin through the dense forest and encounter thrilling bonus features that can
                        lead to massive rewards.&nbsp;</p>
                    <p>With captivating sound effects and dynamic gameplay, Jungle Rush offers a pulse-pounding
                        adventure that keeps you on the edge of your seat. Can you handle the rush and uncover the
                        hidden treasures of the jungle?<br />
                        &nbsp;</p>
                </>,
                image: aceBook6,
            },
            {
                title: "Sea World",
                detail: <>
                    <p>Dive into an aquatic adventure with Sea World, a game that explores the vibrant underwater
                        kingdom filled with colorful marine life and hidden treasures.&nbsp;</p>
                    <p>The game's stunning visuals and serene music create an immersive underwater experience. Spin the
                        reels and uncover exciting bonus features that bring the wonders of the sea to life.&nbsp;</p>
                    <p>Encounter dolphins, turtles, and other sea creatures as you play for big wins and thrilling
                        rewards. Sea World offers a relaxing yet exhilarating gaming experience that transports you to
                        the depths of the ocean. Dive in and discover the magic of the sea!<br />
                        &nbsp;</p>
                </>,
                image: aceBook7,
            },

        ],
        faqs: []
    },
    {
        id: 27,
        path: "/fire-kirin",
        name: "Fire Kirin",
        image: fireKirin,
        sections: [
            {
                title: "Fire Kirin",
                detail: <>
                    <p>Fire Kirin Casino is an exceptional online casino platform offering the best Bitcoin casino
                        adventure. With years of experience in the online casino business, the platform offers a wide
                        range of thrilling casino game genres, from slot games to fish games.</p>
                    <p>Plus, you can enjoy these games on the Fire Kirin download for Android. It’s a reliable
                        sweepstakes platform that employs SSL encryption. You can use the Fire Kirin free credits to
                        enjoy bonuses and exclusive promos!&nbsp;</p>
                    <p>So, you don’t have to worry about anything while playing the best casino games and seamlessly
                        deposit and withdraw your funds from the system.</p>

                </>,
                image: fireKirin0,
            },
            {
                title: "Enjoy Numerous Bonuses and Promotions",
                detail: <>
                    <p>On top of the exceptional gaming titles, special bonuses await you at Fire Kirin Online Casino.
                        You can check out the most exciting online sweepstakes slots and earn cash while playing them.
                        All you need to do is deposit funds to your account and compete for big prizes.</p>
                    <p><span><span>This casino platform is licensed and secured, so you have no worries about depositing your money and withdrawing your winnings. Everything is smooth and swift so that you can enjoy the games conveniently.</span></span>
                    </p>
                    <p><span><span>Aside from the wonderful games, you get to play fantastic wager bonuses. You can also get fantastic bonuses when you use the Fire Kirin app. So consider the Fire Kirin Play online and earn lots of bonus rewards.&nbsp;</span></span>
                    </p>
                </>,
                image: fireKirin1,
            },
            {
                title: "Get Excellent Customer Support 24/7",
                detail: <>
                    <p><span><span>Fire Kirin Casino is a fantastic online casino that provides the best gaming services to players. They understand that some issues might arise while using the platform or playing the games. So you can get the support you need, regardless of the issue.&nbsp;</span></span>
                    </p>
                    <p><span><span>If you encounter a problem with the platform or use the Fire Kirin download for iPhone, you can contact customer support and resolve your problem instantly.</span></span><br />
                        &nbsp;</p>
                </>,
                image: fireKirin2,
            },
            {
                title: "Enjoy Fantastic Sweepstakes Games on Fire Kirin Casino",
                detail: <>
                    <p>On this platform, you’ll find a wide range of Fire Kirin games ready to take your casino
                        experience to the next level. They all have impressive visuals and sound effects, which enhance
                        the game’s entertainment value.</p>
                    <p>The quality will not be diminished when you use the Fire Kirin apk to access these games. On top
                        of that, if you have any questions, you can contact customer support. They’ll help you solve the
                        issue right away.</p>
                    <p>You can use the Fire Kirin free credits to enjoy bonuses and&nbsp;<a
                        href="#" target="_blank">casino promotions</a>&nbsp;to increase
                        your winning odds.&nbsp;</p>
                </>,
                image: fireKirin3,
            },
            {
                title: "Fire Kirin Casino is Compatible with Numerous Devices",
                detail: <>
                    <p><span><span>Compatibility is another perk of signing on to this casino platform. As you register at Fire Kirin Online Casino as the main platform, you can choose which device you would like to play.</span></span>
                    </p>
                    <p><span><span>In that case, this feature helps players using Android and iPhone access the best casino games. Through the Fire Kirin 777 download, you can access all games instantly.&nbsp;</span></span>
                    </p>
                    <p><span><span>After registration, customer support will contact you and share your account details and links. You can then use that to get the Fire Kirin app download.&nbsp;</span></span>
                    </p>
                    <p>
                        <span><span>However, if you want to play on your PC browser, you can do that too.&nbsp;</span></span><br />
                        &nbsp;</p>
                </>,
                image: fireKirin4,
            },
            {
                title: "Fire Kirin Casino Offers Customizable Option",
                detail: <>
                    <p><span><span>You can customize the Fire Kirin 777 download based on your needs and preferences. It’s one of the exceptional features of this casino platform that you can’t find in other casino platforms.</span></span>
                    </p>
                    <p><span><span>If you want to change the color, theme, or place of the shoot button and a few other control buttons, you are free to do so.</span></span>
                    </p>
                    <p><span><span>It's an excellent opportunity for players to design the best possible setting where they are comfortable playing and win real cash rewards. So, choose Fire Kirin Play Online to access this feature and many more exclusive deals.</span></span>
                    </p>
                </>,
                image: fireKirin5,
            },
            {
                title: "Fire Kirin Casino Download",
                detail: <>
                    <p>The Fire Kirin download is one of the best ways to enjoy your favorite games on your mobile
                        devices. In addition, you can install the casino app, which takes less space on your device,
                        like a smartphone.&nbsp;</p>
                    <p>The casino app gives you access to all the games on the platform, including the&nbsp;<a
                        href="#" target="_blank">best no deposit
                        bonus codes</a>. Plus, you can instantly contact customer support if you have any issues.&nbsp;
                    </p>
                    <p>The mobile app is the perfect place to play your favorite games in the comfort of your home or on
                        the go. You have everything you need at your fingertips. So sign up now, download the casino
                        app, and start winning!&nbsp;</p>
                </>,
                image: fireKirin6,
            },
            {
                title: "Fire Kirin Casino Offers Ease Of Play",
                detail: <>
                    <p>Fire Kirin Online Casino allows you to play the best&nbsp;<a
                        href="#" target="_blank">casino games with
                        the best odds</a>&nbsp;at your convenience. Therefore, the platform is designed most
                        simplistically to keep your focus on the game.&nbsp;</p>
                    <p>As a result, it is straightforward to use regardless of your experience level in online casinos.
                        Seamless gameplay and a simplistic navigation bar allow you to utilize the platform's best
                        features without worrying about anything.&nbsp;</p>
                    <p>On top of that, the online casino provides instant withdrawals and deposits so that there would
                        not be any transaction delays. You also get the same ease of play on your mobile device using
                        the Fire Kirin apk.</p>
                </>,
                image: fireKirin7,
            },
            {
                title: "Seamless interface",
                detail: <>
                    <p>This online casino offers a seamless interface and an immersive gaming experience. In addition,
                        the manuals, controls, and overall interface are straightforward.</p>
                    <p>You'll love what this casino platform offers regardless of your experience playing&nbsp;<a
                        href="#" target="_blank">fish
                        arcade games</a>! However, contact customer support if you need help with deposits, withdrawals,
                        or the registration process, and they'll gladly assist you.&nbsp;</p>
                    <p>You can enjoy the seamless interface when you play on your mobile device using the Fire Kirin
                        apk. Just use the Fire Kirin login to access your account and start playing.&nbsp;</p>
                </>,
                image: fireKirin8,
            },
        ],
        faqs: [
            {
                title: "1. What is Fire Kirin Casino?",
                content: "If you want an excellent online casino to play the best crypto games, check out Fire KirinCasino. You can use the fantastic bonuses and promotions to play and win massive cash. You can use the Fire Kirin download to enjoy the games on your mobile device. The casino is highly secured and ensures that all your transactions are safe. ",
            },
            {
                title: "2. How to get free money on Fire Kirin?",
                content: "Getting free money on Fire Kirin is simple. Just sign up on Cloud9, choose this casino platform, and you’ll automatically get your bonus. The best part is that you can use the bonus for Fire Kirin Play online without adhering to any requirements. ",
            },
            {
                title: "3. How do you win on Fire Kirin games?",
                content: "The best way to boost your chances of winning is to learn the strategies of slot games, especially if you’re a new player.",
            },
            {
                title: "4. How to win Jackpot on Fire Kirin?",
                content: "You must practice regularly to improve your skills using the demo versions. That will help you familiarize yourself with the game and increase your winning opportunities. Also, always keep yourself comfortable while playing the games. ",
            },
            {
                title: "5. How to install Fire Kirin on iPhone?",
                content: "You can use the Fire Kirin download for iPhone to get the app on your mobile device. Just visit Cloud9, download, and install the casino app. It only takes a few minutes for the Fire Kirin 777 download to work. After, you can play your favorite games without any issues. ",
            },
            {
                title: "6. Why can’t I download Fire Kirin on my iPhone?",
                content: "The Fire Kirin download for iPhone should be straightforward. However, if you're having issues with the download, contact customer support; they'll gladly assist you. ",
            },
        ]
    },
    {
        id: 28,
        path: "/juwa",
        name: "Juwa",
        image: juwa,
        sections: [
            {
                title: "Juwa",
                detail: <>
                    <p>Juwa is one of the most experienced and well-developed platforms in the online gaming industry.
                        Juwa Casino Real Money offers the highest quality slot games that feature fun fish table games.
                        Here are the core features that create Juwa.777:</p>
                </>,
                image: juwa0,
            },
            {
                title: "Juwa 777 Casino Download On Various Devices",
                detail: <>
                    <p>If you want to enjoy your favorite games on the go while you win more cash, consider the Juwa apk
                        download. The installation takes a few minutes, and the apk takes up only a little space on your
                        mobile.</p>
                    <p>On top of that, you get all the bonuses, including exclusive bonus codes, which you can use to
                        boost your chances of winning. It’s the best way to have a good time in the comfort of your
                        home. The casino apk download is accessible for various operating system devices. <a
                            href="#" target="_blank">Juwa Download</a> Android and Juwa
                        Download iOS processes go the same way as installing any other app on your mobile.</p>
                    <p>To get the app, click the Cloud9 Android app on the website and start downloading. Access
                        impressive games on the go and receive the smoothest experience when you play Juwa online no
                        download iOS.</p>
                </>,
                image: juwa1,
            },
            {
                title: "Play Juwa Slots with Advanced Casino Technology",
                detail: <>
                    <p>While you play Juwa online, you use one of the most well-designed platforms in the gaming market.
                        Juwa online app has an easy navigation process and offers the smoothest gaming experience. Most
                        importantly, the depositing and withdrawal process is effortless and can be done without delays
                        with cryptocurrencies. <a href="#"
                            target="_blank">Don’t have a crypto</a>? Check out the guide here.</p>
                    <p>Security is another perk that you get when you choose Juwa Sweepstakes. This platform ensures
                        your transactions are completely safe and secure. That way, you can enjoy Juwa sign up and never
                        worry about the security of your account or the funds.</p>
                    <p>All you need to do for fun and exciting gaming is to register, claim free credits, and play Juwa
                        online no download games right away.</p>
                </>,
                image: juwa2,
            },
            {
                title: "Juwa Has an Easy-to-Use Interface to Play Games",
                detail: <>
                    <p>These days, convenience is highly valued when it comes to playing sweepstakes. You can play Juwa
                        online without any hassle or complex registration.</p>
                    <p><br />
                        The Juwa.777 platform offers a streamlined process to join and play exciting games. The signup
                        process is user-friendly, and you can start playing on the app immediately.</p>
                    <p><br />
                        The platform allows you to complete quick withdrawals and reloads, making playing fun and easy.
                        So, regardless of your prior experience in gaming, you can jump right in and start playing your
                        favorite games.<br />
                        &nbsp;</p>
                </>,
                image: juwa3,
            },
            {
                title: "Play More Games and Win with Juwa Free Credits",
                detail: <>
                    <p>One of the best advantages you should not miss is the free credits. This allows you to play games
                        without depositing any money. So you can win real cash prizes.</p>
                    <p><br />
                        With the Juwa free credits, you can enjoy playing your favorite games and win big!</p>
                    <p><br />
                        For example, you can try new games and take promotional plays downloaded for free. Moreover, you
                        can play on the platform and win big bonuses.</p>
                    <p><br />
                        The best part is that the Juwa 777 online slots give you the chance to win, no matter your level
                        of experience.</p>
                </>,
                image: juwa4,
            },
            {
                title: "Deposits and Withdrawals are Simple on Juwa 777",
                detail: <>
                    <p>Fast deposits and withdrawals are among the best aspects of playing in an online casino. So, it
                        can be frustrating when you don’t get that on a casino platform.</p>
                    <p>But Juwa is different from other platforms. This platform uses the latest technology to secure
                        all deposits and withdrawals.</p>
                    <p>So you can quickly transfer your funds or cash out without any issues. You can even contact
                        customer support for quick assistance!</p>

                </>,
                image: juwa5,
            },
            {
                title: "Juwa Casino Offers Excellent Customer Support",
                detail: <>
                    <p>Juwa will always meet your needs. This is where their customer <a
                        href="#" target="_blank">support </a>comes in to help you every step
                        of the way.</p>
                    <p>You can reach them through email, live chat, or phone if you have any questions. No matter the
                        complexity of your issue, Juwa Casino has a credible support team ready to help you 24/7.</p>
                </>,
                image: juwa6,
            },
            {
                title: "Juwa Online Casino Offers Exceptional Bonuses",
                detail: <>
                    <p>Juwa online login allows you to claim numerous online casino bonuses. Not to mention that inside
                        each game, you can win free spins or other bonus games. You receive countless promotions from
                        the start.</p>
                    <p>There is a signup bonus you claim right away when you sign up. You can also get the first,
                        second, and third deposit bonuses you claim after making those wagers. Juwa casino apk download
                        allows you to receive referrals and birthday bonuses for your loyalty. When you receive a higher
                        user role status, get your money back through cashback.</p>
                    <p>With all these casino promotions, this platform gives you the best gaming experience
                        possible!</p>
                </>,
                image: juwa7,
            },
        ],
        faqs: []
    },
    {
        id: 29,
        path: "/cash-machine",
        name: "Cash Machine",
        image: cashMachine,
        sections: [
            {
                title: "Cash Machine",
                detail: <>
                    <p>Consider Cash Machine Casino if you're searching for the best platform with the best experience
                        possible.&nbsp;</p>
                    <p>You can win massive bonuses, promotions, and more while enjoying the top Cash Machine slots.</p>
                    <p>This platform makes it convenient to play your favorite&nbsp;<a
                        href="#" target="_blank">casino games with
                        best odds</a>&nbsp;conveniently and enjoyably.&nbsp;</p>
                    <p>Because of this, the platform works hard to ensure you have access to the best games in a secure
                        setting.</p>
                    <p>This platform provides online slots, fish games, and table games. Additionally, they never stop
                        updating their games. So, you can play fun games and increase your winnings anytime.&nbsp;</p>
                    <p>Additionally, you can use the casino app on your smartphone to play these games. You also have
                        the option to play online.&nbsp;</p>
                </>,
                image: cashMachine0,
            },
            {
                title: "Cash Machine Online Casino Offers Ease of Play",
                detail: <>
                    <p>Using the Cash Machine 777 online casino download link, you can install the casino app and enjoy
                        the best games on your iOS or Android mobile device.&nbsp;</p>
                    <p>The games the casino Cash Machine offers are well-optimized for small and large screens since
                        it’s configured for every sweepstakes game.</p>
                    <p>You’ll still have a fantastic time. This platform's goal is to make gaming easy for you to play,
                        and it constantly updates its library of Cash Machine slots.</p>
                    <p>So, if you want to play the best&nbsp;<a
                        href="#" target="_blank">fish
                        arcade games</a>&nbsp;easily, check out this platform.&nbsp;</p>
                </>,
                image: cashMachine1,
            },
            {
                title: "Cash Machine Casino Offers Excellent Customer Support",
                detail: <>
                    <p>A reliable platform should have an excellent customer service department to help you with any
                        problems. And with this platform, you'll find precisely that.</p>
                    <p>The platform's support team will assist if you need help with the Cash Machine 777 online casino
                        download.&nbsp;</p>
                    <p>Of course, that also includes depositing, withdrawing, registration, etc. The best part is that
                        the customer support is highly responsive, usually responding within a few minutes.&nbsp;</p>
                    <p>The support team can resolve any issues you may run into. You can contact us by email, phone, or
                        live chat.<br />
                        &nbsp;</p>
                </>,
                image: cashMachine2,
            },
            {
                title: "Casino Cash Machine Ensures Your Security While Playing",
                detail: <>
                    <p>It’s crucial to consider the platform you choose, especially regarding security. And that’s what
                        this platform gives you. You get the best security available whether you play on desktop or on
                        your mobile device.&nbsp;</p>
                    <p>With years of experience, the platform has obtained all the licenses and permissions required to
                        ensure security.&nbsp;</p>
                    <p>This means that you can ensure your money and personal information are safe when you play.</p>
                    <p>Thanks to this safe platform, you can focus on playing your favorite games even when using the
                        casino app. So, sign up now, choose the best games, and start playing!&nbsp;</p>
                </>,
                image: cashMachine3,
            },
            {
                title: "Easily Deposit and Withdraw Your Winnings",
                detail: <>
                    <p>Players usually move to other platforms if they have trouble making deposits or withdrawals. And
                        that’s something that no casino platform wants to do.&nbsp;</p>
                    <p>Cash Machine Casino is aware of the issue and has made it impossible for its players to encounter
                        such issues.&nbsp;</p>
                    <p>Furthermore, the platform uses advanced security measures that closely monitor your withdrawals
                        and deposits around the clock to guarantee seamless transactions.</p>
                    <p>This means that depositing money and cashing out will be easy for you. You can also easily
                        withdraw your winnings even if you play on your mobile device.&nbsp;</p>
                </>,
                image: cashMachine4,
            },
        ],
        faqs: [
            {
                title: "What is Cash Machine Casino?",
                content: "Cash Machine online casino is a sweepstakes platform where you can play various casino games like fish arcade games, online slots, roulette, blackjack, etc. You can play these games online on your desktop or download the casino app for Android or iOS. The platform also offers numerous bonuses to enjoy free games for real money. ",
            },
            {
                title: "Can I play the Cash Machine casino game online?",
                content: "Yes. You can play your favorite Cash Machine casino game online. This casino allows you to play on your PC or mobile device. Just create an account, and you’ll get access to the platform’s page and start playing. ",
            },
            {
                title: "How can I win at Cash Machine Casino?",
                content: "The best way to win is to use the demo version to play and familiarize yourself with the game before playing for real money. This gives you the chance to perfect your gaming strategies and improve your skills. You can also use the bonuses the platform offers to play for free without using your bankroll. ",
            },
            {
                title: "Is the Cash Machine safe?",
                content: "Yes. The platform uses advanced casino software to ensure that your deposits, withdrawals, registrations, etc., run smoothly. Cash Machine Casino also uses advanced encryption to secure personal information and gaming activities. ",
            },
            {
                title: "Is Cash Machine legal?",
                content: "Yes. The licenses necessary for this platform to operate are in place. They’ve received the go-ahead from reputable gaming authorities. So, you can play your favorite games, knowing that they have been proven to be fair. ",
            },
        ]
    },
    {
        id: 30,
        path: "/milky-way",
        name: "Milky Way",
        image: milkyWay,
        sections: [
            {
                title: "Milky Way",
                detail: <>
                    <p>Milky Way is an online gaming platform that can turn you from a first-time visitor to a loyal
                        customer in a heartbeat. The key is the exceptional and unmatched services you'll see
                        here.&nbsp;</p>
                    <p><br />
                        With so many exciting casino game genres available, you’ll never run out of options when you
                        play on the Milky Way app. The quality of their sweepstakes games is as good as it gets.</p>
                    <p><br />
                        In addition, MilkyWay casino offers fast deposits and withdrawals so that clients can cash out
                        their winnings anytime they want. So, use the Milky Way slots app and play your favorite games
                        on the go!&nbsp;</p>
                    <p><br />
                        The MilkyWay online casino allows you to claim impressive bonuses for playing MilkyWay games.
                        There are unique promotions for all players, regardless of their gaming experience.</p>
                    <p><br />
                        Additionally, the platform’s got your back if you need any help regarding your gameplay. So sign
                        up now to have the best time of your life!</p>
                </>,
                image: milkyWay0,
            },
            {
                title: "Excellent Customer Support",
                detail: <>
                    <p><span><span>MilkyWay casino ensures that players are never distracted from gaming. That way, it ensures the best experience possible.&nbsp;</span></span>
                    </p>
                    <p><br />
                        <span><span>To anticipate all possible issues and solve them without appearing, the platform has a professional team dedicated to answering any problems you might have. Customer support is effective, active 24/7, and never delayed.</span></span>
                    </p>
                    <p><br />
                        <span><span>So, ask away if you have any issues relating to transfers, deposits, withdrawals, or even registration.</span></span>
                    </p>
                </>,
                image: milkyWay1,
            },
            {
                title: "Milky Way Online Provides Unlimited Bonuses",
                detail: <>
                    <p>One of the main advantages online casinos have over their land-based counterparts is the<a
                        href="#"
                        target="_blank">&nbsp;online casino bonuses.</a>&nbsp;Milky Way is no exception to that rule, as
                        the platform provides you with various profitable bonus offers and promotional programs.</p>
                    <p>Your journey to an impressive promotional world starts with the first deposit match bonus that
                        brings you an extra 100% match plus $20 worth of free play. And that’s just the beginning.</p>
                    <p>You also take advantage of your second deposit by earning an extra 20% deposit match credits and
                        $10 worth of free play. Moreover, your third deposit brings an additional 50% deposit match and
                        $15 worth of free play. By triggering those offers, you can earn free cash and play the best
                        sweepstakes games after the MilkyWay download.&nbsp;</p>
                    <p>In addition to beginner’s bonuses, you will come across more bonus packages with eligibility
                        criteria. However, you can still claim them unproblematically regardless if you play on your
                        desktop browser or use the MilkyWay apk download for Android.</p>
                </>,
                image: milkyWay2,
            },
            {
                title: "Play Your Favorite Games in One Place",
                detail: <>
                    <p>After the MilkyWay login, you can play numerous unique games. All the available MilkyWay games on
                        the platform are compatible with multiple devices and operating systems, so it doesn’t matter
                        what your favorite gaming device is, you will receive the same thrilling experience!</p>
                    <p>You can access the Milky Way slots seamlessly through your smartphone, tablet, or desktop
                        computer. Furthermore, the quality of the Milky Way games remains the same when you switch
                        devices because they are all optimized for small and big touchscreen use.</p>
                    <p>The platform is an excellent place to try your luck on Milky Way fish game titles. The most
                        notable games include Triple Sevens, Fire Kirin Plus, Ocean King 5, Crab Avengers, King of
                        Treasure Plus, Golden Ship Plus, Eagle Strike, etc.</p>
                    <p>You can&nbsp;<a href="#"
                        target="_blank">play slots online for real money</a>, such as King of Olympus,
                        Super Joker, Pirates Gold, Super Red Hot 7s, Penguin Riches, Lucky Duck, Wild Fruit Party, and
                        many others available on our platform.</p>
                </>,
                image: milkyWay3,
            },
            {
                title: "Advantages Of Milky Way Casino Online",
                detail: <>
                    <p>We’ve already mentioned the quantity of the MilkyWay casino game titles that are available on the
                        platform. However, their quality is equally fascinating.&nbsp;</p>
                    <p>When you enter any MilkyWay online game, you’ll immediately feel the vibe of a real casino.
                        High-quality graphics and engaging sound effects add more entertainment to the games and make
                        them even more enjoyable for players. Want to experience it yourself? Start the Milky Way apk
                        download and immediately feel the thrill of real casino gaming.</p>
                </>,
                image: milkyWay4,
            },
            {
                title: "Milky Way Uses the Latest Technology",
                detail: <>
                    <p><span><span>Milky Way gaming platform uses the latest cutting-edge technology, which makes it easier to use. The system was examined rigorously before launch. That means you can play and never worry about anything getting in the way.&nbsp;</span></span>
                    </p>
                    <p><br />
                        <span><span>The platform’s software provides stunning visual and sound effects that keep you engrossed for hours. The varied and exciting plots available on the platform add to its appeal.</span></span>
                    </p>
                    <p><br />
                        <span><span>By signing up here, you will receive the Milky Way Play online feature alongside the MilkyWay app. The software functions flawlessly on big and smaller screens when playing online or via the Milky Way online game app.</span></span>
                    </p>
                    <p><br />
                        <span><span>If you want to experience the chill and thrill from your mobile anytime, here’s a Milky Way 777 download guide.</span></span>
                    </p>
                </>,
                image: milkyWay5,
            },
        ],
        faqs: [
            {
                title: "What is Milky Way Casino?",
                content: "Milky Way is one of the best platforms for playing your favorite sweepstakes games on your desktop or mobile device. In addition, the platform offers numerous online casino bonuses to increase your potential winnings and excellent customer support to answer any questions. With all these features combined, you can receive the most authentic gaming experience after the Milky Way online game download. ",
            },
            {
                title: "Why choose Milky Way?",
                content: "The Milky Way app is licensed and authorized by reputable gaming authorities. The platform utilizes a state-of-the-art encryption system to ensure your security and provide a relaxing gaming atmosphere. Besides, the platform’s software is accessible via various devices, allowing users to play whenever they like and unproblematically redeem their prizes via different e-wallets.",
            },
            {
                title: "What are the advantages of Milky Way Casino?",
                content: "The Milky Way gaming Platform has lots of perks. The core benefits include the ability to play on different operational system devices with the same HD quality. Plus, you can access an impressive number of games from different genres and enjoy them fully with fascinating visual and sound effects. After all, you can redeem your prize using different payment options.",
            },
            {
                title: "How to Create a Milky Way Casino Account?",
                content: "Visit Cloud9 and click on “Sign Up.” You’ll be redirected to a page where you fill out a form with all the necessary information. During registration, you’ll be given the option to choose your preferred gaming platform. Pick Milky Way online casino and finalize the process. Once your account is verified, you can download the app, enter your profile, choose any Milky Way game online you like, and start playing.",
            },
            {
                title: "How to get Milky Way Online Casino Coins?",
                content: "Milky Way offers new and experienced players various free-play opportunities when they register via Cloud9 casino. For instance, you receive a number of sweep coins on sign-up and the first three deposits. Check the promotions page on the website for detailed information.",
            },
            {
                title: "Is Milky Way Casino Legit?",
                content: "Yes. This platform is licensed and regulated by certified gaming authorities, so you can rest assured that all your transactions, withdrawals, and gameplay are secured. Your anonymity and funds are secured by the cutting-edge encryption system the Milky Way utilizes. So, sit back and enjoy the gaming process to the fullest!",
            },
        ]
    },
    {
        id: 31,
        path: "/golden-treasures",
        name: "Golden Treasure",
        image: GoldenTressure,
        sections: [
            {
                title: "Golden Treasure",
                detail: <>
                    <p>Are you searching for the best sweepstakes games in the industry? Then you should check out
                        Golden Treasures casino, where all the best players are found. Thanks to the platform's generous
                        bonuses, you can significantly improve your chances of winning.&nbsp;</p>
                    <p>You can play the Golden Treasure slot by yourself or with other players. So we recommend inviting
                        your friends! The platform uses top-tier software to develop playable, engaging casino
                        games.&nbsp;</p>
                    <p>You can trust the platform and the Golden Treasure Casino download for iPhone as you make instant
                        deposits and withdrawals. That can help you peacefully enjoy your games.&nbsp;</p>
                </>,
                image: goldenTreasure0,
            },
            {
                title: "Various Promotions and Bonuses",
                detail: <>
                    <p>Not every player has the same success when playing at online casinos. You must use the best
                        casino bonuses the site offers to ensure you get all the benefits. Winning at Golden Treasures
                        Casino highly depends on your playing time and bankroll.&nbsp;</p>
                    <p>Signing up with Golden Treasures allows you to take advantage of various bonuses. Playing with
                        these bonuses increases your chances of earning real money while participating in various
                        thrilling casino games.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure1,
            },
            {
                title: "Golden Treasures Casino is Easy to Use",
                detail: <>
                    <p>Golden Treasures online casino is aware of the significance of ease when playing the top casino
                        games. So, the platform has been made as easy to use as possible so you can give all your
                        attention to the game.&nbsp;</p>
                    <p>This makes it easy to use, regardless of your experience with online casinos. Thanks to the
                        seamless gameplay and straightforward navigation bar, you can use the platform's best features
                        without problems.&nbsp;</p>
                    <p>In addition, this casino platform allows for quick deposits and withdrawals, preventing any
                        problems. The Golden Treasure Casino download on iPhone is also simple to use, making it easy to
                        play your favorite games.<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure2,
            },
            {
                title: "Golden Treasures Casino is Compatible with Numerous Devices",
                detail: <>
                    <p>Another advantage of Golden Treasures online casino is its compatibility. You can play on any
                        preferred device and enjoy your favorite games. This function helps you to use both iOS and
                        Android to gain access to the top games.&nbsp;</p>
                    <p>Golden Treasures 777 is available instantaneously on most devices, regardless of the operating
                        system. After you’ve signed up for a Cloud9 account, customer support will contact you to
                        give you your login details and other necessary information.&nbsp;</p>
                    <p>The mobile casino app gives you more convenience. However, you can still enjoy the games on your
                        computer without any issues. It all depends on your preference, nothing else!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure3,
            },
            {
                title: "Golden Treasures Offer Excellent Customer Support",
                detail: <>
                    <p>Golden Treasures 777 will stop at nothing to give you the best customer experience. That's why
                        they've built a solid platform that lets players focus on having a good time while making some
                        cash.&nbsp;</p>
                    <p>However, problems do occur, but Golden Treasures have a professional team ready to help you
                        answer any questions. They'll give you a swift response and guide you to solve any issues that
                        may arise. &nbsp;</p>
                    <p>If you need help making a deposit, withdrawal, or transfer of funds, contact customer support,
                        and they'll gladly assist you!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure4,
            },
            {
                title: "Golden Treasures Download",
                detail: <>
                    <p>If you are into mobile casino gaming, then Golden Treasures Download is what you need to learn
                        about. So start the download process instantly and access the best mobile casino games at your
                        fingertips.&nbsp;</p>
                    <p>The casino app allows you to enjoy high-quality games and unique coupon codes and bonuses that
                        can help you level up your gameplay.&nbsp;</p>
                    <p>After installing the mobile casino app, you can enjoy exclusive bonus offers, promotions,
                        fantastic games, and more! So consider the Golden Treasures download and boost your winning
                        cash!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure5,
            },
            {
                title: "Streamlined Gameplay Interface",
                detail: <>
                    <p>This casino platform offers a user-friendly layout with a single click. The interface (screens,
                        buttons, instructions, etc.) is simple. The casino app is designed to be accessible to
                        everybody, regardless of experience with casino platforms.&nbsp;</p>
                    <p>Also, if you have any issues registering, depositing, withdrawing money, etc., you can contact
                        their customer support via email, live chat, or phone. They’ll gladly assist you in no
                        time.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure6,
            },
            {
                title: "Golden Treasures Casino Offers Fast Withdrawals and Deposits",
                detail: <>
                    <p>The unreliability of a casino platform when it comes to processing deposits and withdrawals is a
                        major source of player dissatisfaction. When it comes to assistance, Golden Treasures Casino
                        knows how frustrating it can be when there’s no response to your solution.&nbsp;</p>
                    <p>That's why the casino platform employs security measures always to protect your deposits and
                        withdrawals. That eases the process of depositing money and cashing out wins. So sign up now and
                        enjoy the best Golden Treasure slot now!&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure7,
            },
            {
                title: "Smooth Gameplay",
                detail: <>
                    <p>The games at Golden Treasures Casino are consistently cutting-edge and expertly executed. The
                        platform’s priority is comfort and enjoyment as you play your preferred casino games.&nbsp;</p>
                    <p>Whether you're a seasoned pro or just getting started, you'll feel right at home playing
                        top-notch games. Moreover, the platform is a great place to have fun with friends by
                        playing&nbsp;<a href="#"
                            target="_blank">slots online for real money</a>.&nbsp;</p>
                    <p>Whether you play on a mobile device or desktop computer, the casino app will provide a terrific
                        and immersive experience. You'll always have an incredible time.&nbsp;</p>
                </>,
                image: goldenTreasure8,
            },
            {
                title: "Fantastic Games to Play",
                detail: <>
                    <p>Various types of sweepstakes games are available on Golden Treasures, all of which have the
                        potential to enhance your time spent gaming greatly. In addition, they all include high-quality
                        graphics and audio that add to the game's fun.&nbsp;</p>
                    <p>These games will maintain their high standard regardless of the device used to access them,
                        whether PC, tablet, or smartphone. Furthermore, you can contact customer assistance if you have
                        any platform issues.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: goldenTreasure9,
            },
        ],
        faqs: [
            {
                title: "1. What is Golden Treasure Casino?",
                content: "Golden Treasure is a casino platform for players who wants to enjoy exciting games and increase their cash rewards. This platform has an extensive selection of sweepstakes games. So you’ll always find the best and latest games to play with your friends! You can also use the Golden Treasure Casino download for Android to play on your mobile devices. ",
            },
            {
                title: "2. How to download Golden Treasures Casino on iPhone?",
                content: "You can use the Golden Treasure casino download on iPhone to play games on your mobile device. Visit Cloud9 and download the app. After that, you can sign in and start playing for real money. You can contact customer support if you need assistance. ",
            },
            {
                title: "3. How to download Golden Treasures Casino on Android?",
                content: "You can play your favorite casino games anywhere with the Golden Treasure casino download on Android. You can install the casino app by visiting Cloud9. The installation process takes a few minutes, and you can contact the support team if you need any assistance. ",
            },
            {
                title: "4. What is the RTP for Golden Treasures?",
                content: "Every game comes with its RTP. However, rest assured that you can get a higher RTP rate to increase your chances of winning. So sign up now, get many bonuses, and start playing for real money. ",
            },
            {
                title: "5. Are there any free spins or bonuses on Golden Treasures Casino?",
                content: "Yes. You can get numerous bonuses and free spins on this casino platform. All you have to do is sign up and start playing.",
            },
        ]
    },
    {
        id: 32,
        path: "/fire-master",
        name: "Fire Master",
        image: fireMaster,
        sections: [
            {
                title: "Fire Master",
                detail: <>
                    <p>Exciting gaming opportunities are just around the corner. Get ready to receive the ultimate
                        gaming experience with the Fire Master Casino.</p>
                    <p><br />
                        The modernity of this impressive gaming app will blow your mind. Here are all the necessary
                        attributes with numerous bonus features you will love to see and experience.&nbsp;</p>
                    <p>The Fire Master casino has just entered the gaming market and is already bringing the greatest
                        joy to players worldwide. With its special characteristics, you can be sure to have the most
                        memorable playtime while winning cash and having the most fun.</p>
                    <p>So, let’s discover the platform’s most intriguing features and instantly enroll in the Fire
                        Master online!<br />
                        &nbsp;</p>
                </>,
                image: fireMaster0,
            },
            {
                title: "Fire Master Casino Has Stunning Visuals",
                detail: <>
                    <p>For a majestic online gaming experience, check out the Fire Master online casino immediately. And
                        for that, get ready to meet the most exquisite visual effects on the market!</p>
                    <p>To start, when you visit the Fire Master, you will be blown away by the simplicity and the easy
                        accessibility of the platform. However, that’s not all!</p>
                    <p>With the smooth website comes the most jaw-dropping visual effects. With them all, you will
                        receive the most memorable gaming experience, as you will feel inside a game.</p>
                    <p>So, sign up now, choose your favorite title, and go on an incredible journey to win lots of
                        treasures!</p>
                    <p>The graphics of each Fire Master casino game is top-notch. With them, the sound effects bring
                        even more appeal to each game. So, check out the platform’s comprehensive gaming catalog right
                        after the Fire Master download!</p>
                </>,
                image: fireMaster1,
            },
            {
                title: "Welcome Bonuses",
                detail: <>
                    <p>A sign-up bonus is the first promotion you get rights after registering on the Fire Master
                        online. This is a 5$ worth of free credits that are given to you for free after making an
                        account.&nbsp;</p>
                    <p><br />
                        To use these credits and kick-start your gaming journey, roll into your account immediately,
                        verify it, and start playing the most immersive games.&nbsp;</p>
                    <p><br />
                        And if you think it’s the only bonus from the Fire Master, know it is just a start!</p>
                    <p><br />
                        With the sign-up bonus after downloading the Fire Master for iPhone and Android comes deposit
                        match bonuses. On your first deposit, you receive 50% deposit match credits, while the second
                        and third bring you an additional 20%.</p>
                    <p><br />
                        So, register immediately and receive an exclusive welcome bonus package to kick-start your
                        gaming journey on the Fire Master!<br />
                        &nbsp;</p>
                </>,
                image: fireMaster2,
            },
            {
                title: "Referral Program",
                detail: <>
                    <p>Another exciting bonus opportunity you can get from the Fire Master is a referral program. With
                        this promotion, you are able to invite your friends over to the platform and play with them.</p>
                    <p><br />
                        With the referral program, you and your friend can receive $10 worth of free credits. And you
                        can invite as many friends as you like. That way, you can always receive a referral bonus on
                        your balance.</p>
                    <p><br />
                        So, register on the Fire Master online casino and gather incredible bonuses on the way!<br />
                        &nbsp;</p>
                </>,
                image: fireMaster3,
            },
            {
                title: "Mobile Compatibility Of Fire Master",
                detail: <>
                    <p>Playing at the Fire Master casino is the most thrilling experience. That’s because the platform
                        is stacked with impressive and fascinating bonus opportunities.&nbsp;</p>
                    <p>However, one more exciting feature turns your gaming into the smoothest playtime!</p>
                    <p>The Fire Master 8888 download is one of the greatest offers of the casino. You can easily
                        download Fire Master for Android or iPhone and enjoy the smoothest experience.</p>
                    <p>The casino works seamlessly on big and small screen devices. So, register now and play from any
                        gadget you feel comfortable with!</p>
                </>,
                image: fireMaster4,
            },
            {
                title: "Exciting Games On Fire Master Online Casino",
                detail: <>
                    <p>Ready to play the best games on Fire Master online? Sign up immediately and try out the most
                        immersive game titles on the platform.&nbsp;</p>
                    <p>Gather up all the available free credits and receive the best online gaming experience. Win cash
                        while playing for free!</p>
                </>,
                image: fireMaster5,
            },
            {
                title: "Fire Master Online Has Exclusive Bonuses",
                detail: <>
                    <p>To receive even more excitement while gaming, you must gather free credits. The Fire Master
                        online casino offers exclusive bonuses to fire up your gaming journey. So, buckle up, and let’s
                        dive into the casino’s exciting&nbsp;<a href="#"
                            target="_blank">promotions</a>.</p>
                    <p>On the Fire Master online, you will be spoiled with free credits all around the platform. The
                        bonuses fly in your direction, allowing you to have the most extreme gaming
                        experience.&nbsp;</p>
                    <p>With the Fire Master download, the number of free credits on your balance will peak at the top.
                        And with that amount, you will be able to play various interactive games and win the highest
                        amounts of cash effortlessly!</p>
                    <p>Here are a few types of bonus opportunities you can get after you download the Fire Master for
                        Android or iPhone:</p>
                </>,
                image: fireMaster6,
            },
        ],
        faqs: [
            {
                title: "How to play Fire Master slots?",
                content: "To play Fire Master slots, make a gaming account from Cloud9 immediately. With the profile, you will be able to make real money deposits and cash out your prizes whenever you want.",
            },
            {
                title: "What is Fire Master Casino?",
                content: "Fire Master is a modern online gaming app with many bonus offers to players. The app works flawlessly on various devices, so you can play your favorite games from any gadget you like.",
            },
            {
                title: "How to win on Fire Master Casino Online?",
                content: "To win the maximum payouts and enjoy the most playing Fire Master, you must register on the platform right away. Gather all the available bonuses and put them on your balance. With the free cash, you can discover the best games and bet the biggest amounts, leading to the top payouts.",
            },
            {
                title: "Is Fire Master legal?",
                content: "Yes. Fire Master Casino has all the necessary permissions to ensure your legal gaming. So, whenever you play and want to cash out your prize, know you will do it without legal complications.",
            },
            {
                title: "Is Fire Master App safe?",
                content: "Yes. The Fire Master uses a modern software system that is 100% safe and secure. That way, you can focus on the gameplay and never worry about leaking your information or hacker attacks.",
            },
            {
                title: "Can I download Fire Master  Sweepstakes for free?",
                content: "The Fire Master app downloading process is free of charge. However, to play for real money, you must have a gaming account. Sign up right away, download the app, and enjoy gaming with real payouts!",
            },
        ]
    },
    {
        id: 33,
        path: "/v-blink",
        name: "V blink",
        image: vblink,
        sections: [
            {
                title: "V blink",
                detail: <>
                    <p>Vblink is ready to bring you the ultimate gaming satisfaction. When you first visit the platform,
                        you will be blown away by the stunning visuals. The platform is refined to perfection, ensuring
                        you never encounter any trouble while playing.</p>
                </>,
                image: vBlink0,
            },
            {
                title: "God of Wealth",
                detail: <>
                    <p>God of Wealth is a five-reel, 20-pay line slot game from Red Tiger Gaming that returns to China.
                        You can win up to 138 times your wager and enjoy a free spins bonus on this adventure to the
                        Orient. You can wager anywhere from 20 pence to $500 per spin.</p>
                    <p><br />
                        The reels of God of Wealth are housed in a red and gold temple-style frame. Their symbols range
                        from ace to tens to three spheres of increasing value (white, yellow, and green).<br />
                        &nbsp;</p>
                </>,
                image: vBlink1,
            },
            {
                title: "Hai Di Lao",
                detail: <>
                    <p>There it is! One of the most searched games by fish table lovers worldwide is in the palm of your
                        hand. The Hai Di Lao is a new game but has already conquered players’ hearts.</p>
                    <p><br />
                        The Hai Di Lao has unique colors and a 3D design like any other fish game. In addition, all the
                        characters are made with cutting-edge technology, turning the playing into the ultimate
                        satisfaction.</p>
                    <p><br />
                        You can enjoy the game on the go using the casino app.<br />
                        &nbsp;</p>
                </>,
                image: vBlink2,
            },
            {
                title: "Fascinating Graphics and Stunning Visuals from VBlink Casino",
                detail: <>
                    <p>The visuals will make your jaw drop! When you first visit the website, it instantly becomes
                        visible what you should expect from the casino. The interface is magnificent.</p>
                    <p><br />
                        The cutting-edge graphics on every corner of the screen promise the same HD visuals in each
                        game. And when you move to the VBlink play online games, get ready because you will face
                        breathtaking visual and sound effects!</p>
                    <p><br />
                        Whether you play on a desktop computer or the V Blink download iOS, you’ll get the same quality.
                        You are destined to be satisfied for the rest of your life!<br />
                        &nbsp;</p>
                </>,
                image: vBlink3,
            },
            {
                title: "Promotions for Absolute Fun",
                detail: <>
                    <p>You can get a daily VBlink bonus 777 to play any game and many ways to win cash rewards! While
                        registering on this casino platform, first, you will receive a welcome bonus. These free tools
                        help you kick-start your gaming by trying various games and choosing your favorite.</p>
                    <p>Moreover, on your first 3 deposits, you get additional bonuses. The platform believes that each
                        deposit is worth celebrating. So, enjoy your daily bonus while playing. Besides, you get an
                        incredible opportunity to play with your friends and receive even more bonuses for their VBlink
                        login. Using a referral program, you invite your friends to play V Blink Casino real money
                        games.</p>
                </>,
                image: vBlink4,
            },
            {
                title: "Latest Technology Employed by VBlink Casino Online",
                detail: <>
                    <p>VBlink 777 online casino offers the best sweepstakes platform you can find. In addition, the
                        casino offers a top-notch software system, ensuring you will never have trouble. So, from your
                        first visit, you can surely receive the ultimate gaming experience.</p>
                    <p>Every process is straightforward and effortless. Register on the platform with a simple sign-up
                        process and start the Vblink 777 apk download instantly! Depositing and withdrawing are pretty
                        much the same as registration. And to make gaming even better, Cloud9 offers detailed
                        tutorials on <a href="#" target="_blank">how to
                            buy crypto</a> via various e-wallets. You can also use bonuses to play any game and increase
                        your winning chances.</p>
                    <p>And the cherry on top, the online casino is compatible with any mobile device you use. So,
                        whether you prefer the Vblink 777 login app or desktop, you are guaranteed a flawless gaming
                        experience!</p>
                </>,
                image: vBlink5,
            },
            {
                title: "VBlink 777 Casino Ensures Secure Gaming",
                detail: <>
                    <p>An essential aspect of gaming in any online casino is security. And, of course, the V-blink
                        sweepstakes ensures you won’t be disturbed by worrying while playing the most impressive
                        games!</p>
                    <p><br />
                        The gaming authorities have approved the casino platform. It has all the licenses and
                        permissions to create a safe gaming environment. So, when playing, remember that your details
                        and funds are safe in your wallet.</p>
                    <p><br />
                        Your credentials are also secured when you use the V blink 777 download for Android. So you have
                        nothing to worry about.<br />
                        &nbsp;</p>
                </>,
                image: vBlink6,
            },
            {
                title: "Effective Customer Support 24/7",
                detail: <>
                    <p>The VBlink Casino is a technological masterpiece. While gaming, you will enjoy the fascinating
                        platform that functions smoothly without lags. The same goes for the games; you will never
                        encounter any trouble while switching through games.</p>
                    <p>However, you can <a href="#" target="_blank">contact customer
                        support </a>if you ever misunderstand something, need help clarifying, or face any issue. The
                        support is one of the best in the gaming industry. They are active 24/7 and find practical
                        solutions in seconds.</p>
                    <p>So, if you have issues with the V Blink download app, don’t hesitate to contact them!</p>
                </>,
                image: vBlink7,
            },
            {
                title: "Wide Range of Interactive Casino Games to Choose From",
                detail: <>
                    <p>Now it's time to discuss the main features of the VBlink casino real money platform. And that's
                        the unique <a href="#" target="_blank">sweepstakes
                            casino games</a>.&nbsp;VBlink Casino 777 is superior to any other online platform with
                        games. The platform offers various game genres in one place. Whatever your favorite genre is,
                        you’ll find exclusive titles here.</p>
                    <p>With stunning visuals and exceptional games, you will add lots of cash to your wallet.&nbsp;You
                        can also enjoy quality games after the Vblink apk download on your device. But before that,
                        check the top V-Blink sweepstakes.</p>
                </>,
                image: vBlink8,
            },
        ],
        faqs: []
    },
    {
        id: 34,
        path: "/redplay",
        name: "Red Play",
        image: redPlay,
        sections: [
            {
                title: "Red Play",
                detail: <>
                    <p><span><span>If you like exciting bonuses and exceptional online casino games, you must try out RedPlay Casino. It's an online casino with countless exciting features players can utilize. With a wide range of fish games, slot games, and many more gaming genres, you can find the most suitable titles for your playing style.</span></span>
                    </p>
                    <p><span><span>This casino platform employs the latest technology for its gaming software and creates a platform where you can enjoy the best sweepstakes titles without worrying about any other issues.</span></span>
                    </p>
                    <p>
                        <span><span>You can enjoy the best games on your smartphone devices using the RedPlay app.&nbsp;</span></span><br />
                        &nbsp;</p>
                </>,
                image: redPlay0,
            },
            {
                title: "Receive a 20% Bonus on Your Second and Third Deposit.",
                detail: <>
                    <p><span><span>Every second you spend playing your favorite games on this platform is highly valued. As a result, you get a 20% bonus on your second and third deposits. The good news is that there are no restrictions attached to this bonus.</span></span>
                    </p>
                    <p><span><span>Just keep having fun playing the games you like. Your bonus money will be available when you make your second and third deposits.&nbsp;</span></span><br />
                        &nbsp;</p>
                </>,
                image: redPlay1,
            },
            {
                title: "A 50% Birthday Bonus Is Yours.",
                detail: <>
                    <p><span><span>RedPlay Sweepstakes appreciates you by giving you a 50% bonus on your birthday. You can use that bonus to play any game, increasing your potential winnings. In other words, on your special day, you can double your odds of winning.</span></span>
                    </p>
                    <p><span><span>There are no stipulations attached to receiving this bonus. So, keep playing the games, and you’ll get a birthday bonus on your birthday.</span></span><br />
                        &nbsp;</p>
                </>,
                image: redPlay2,
            },
            {
                title: "Play the Best Games in the Industry",
                detail: <>
                    <p><span><span>This online casino allows players to play only the best titles. Therefore, the games, especially their massive bonus opportunities, were developed carefully with players in mind.</span></span>
                    </p>
                    <p><span><span>It’s common knowledge that the quality of game software affects the ease with which you place bets. It can also affect how you make deposits, earn bonuses, and play other casino games.&nbsp;</span></span>
                    </p>
                    <p><span><span>However, the platform is dedicated to providing its customers with only the best games possible.</span></span>
                    </p>
                    <p><span><span>You can have lots of fun playing the games for a long time and even win cash.</span></span><br />
                        &nbsp;</p>
                </>,
                image: redPlay3,
            },
            {
                title: "Your Bonuses Are Safely Guaranteed",
                detail: <>
                    <p><span><span>The inability to reliably withdraw and deposit funds is a significant source of frustration for players. Having no one to turn to can be annoying, and the casino platform gets that. That's why they have trained professionals to ensure your deposits and withdrawals are always secured.</span></span>
                    </p>
                    <p><span><span>That means you won't have trouble making deposits or, more importantly, cashing out your winnings. You can also withdraw your winnings quickly using the casino app.&nbsp;</span></span><br />
                        &nbsp;</p>
                </>,
                image: redPlay4,
            },
            {
                title: "RedPlay Apk Download",
                detail: <>
                    <p>If you want to play sweepstakes games on your mobile device, the RedPlay download is the place to
                        start. You can get incredible bonuses as you play the latest games! Contact customer support if
                        you have any questions; they’ll quickly assist you.&nbsp;</p>
                    <p>Furthermore, you get the latest updates and upcoming promotions directly on the app.&nbsp;</p>
                    <p>So you get nothing but the best. Download the casino app now, and have the best time!&nbsp;</p>
                </>,
                image: redPlay5,
            },
            {
                title: "RedPlay App",
                detail: <>
                    <p><span><span>One perk that makes playing on RedPlay exciting is the RedPlay app. The games you play on your mobile device come with high-quality visuals and immersive audio. Furthermore, you get the latest updates and upcoming promotions directly on the app.&nbsp;</span></span>
                    </p>
                    <p>
                        <span><span>So you get nothing but the best. Download the casino app now, and have the best time! </span></span>
                    </p>
                </>,
                image: redPlay6,
            },
            {
                title: "Stunning Visuals and Audio",
                detail: <>
                    <p><span><span>If you've ever played any casino game at a casino, you'll know what to expect from them. In other words, you expect the audio quality to be superb. In addition, the game's stunning visuals will keep you captivated by the screen.</span></span>
                    </p>
                    <p><span><span>Developers invested significant time and effort to produce the best visuals and audio for sweepstakes games. Undoubtedly, the best visuals in the history of games result from collaboration between game designers and top-tier graphic artists.</span></span>
                    </p>
                    <p><span><span>Get ready to be blown away by the incredible in-game artwork you'll see and interact with. And the casino app has the same quality visuals and audio as its desktop counterpart.&nbsp;</span></span><br />
                        &nbsp;</p>
                </>,
                image: redPlay7,
            },
            {
                title: "Get a 25% Daily Bonus to Play Every Day.",
                detail: <>
                    <p><span><span>You'll be rewarded with an extra 25% bonus for every day you spend playing your favorite games on this casino platform. To top it all off, there are zero requirements to claim this bonus. It's free to sign up; all you have to do is play games and collect your daily bonus.</span></span>
                    </p>
                    <p><span><span>You can play on your mobile device using the RedPlay apk download and still get the bonus.&nbsp;</span></span>
                    </p>
                </>,
                image: redPlay8,
            },
            {
                title: "New Players Get a $20 Bonus.",
                detail: <>
                    <p>Did you know you automatically get a $20 bonus when you sign up? Of course, you have requirements
                        to fulfill, so you can play any game you want using the bonus.</p>
                    <p>So sign up now to play the best games with stunning visuals, exciting&nbsp;<a
                        href="#" target="_blank">casino promotions</a>, and special
                        offers. You can also get a bonus when you use the casino app to play on your mobile
                        device.&nbsp;</p>
                </>,
                image: redPlay9,
            },
        ],
        faqs: []
    },
    {
        id: 35,
        path: "/orion-stars",
        name: "Orion Stars",
        image: orionStar,
        sections: [
            {
                title: "Orion Stars Casino",
                detail: <>
                    <p>Orion Stars casino is a place to be if you want interactive online casino gaming action and
                        profitable bonuses to boost your account balance.&nbsp;It is a one-stop shop for online casino
                        players where they can pick their favourite genre and have a wide range of gaming
                        options.&nbsp;</p>
                    <p>Apart from the high quantity of games, the quality is also awe-inspiring. So, whether you are
                        looking for online slots or table games, you can find the latest sweepstakes titles for those
                        genres at this online casino.&nbsp;The Orion Star download lets you enjoy the games on your
                        mobile device. You can also use the Orion Stars free play to enjoy real money games without
                        using your bankroll.&nbsp;&nbsp;</p>
                    <p>Check out other essential features of this magnifying provider below, sign up, and enjoy ultimate
                        fun while winning money.</p>
                </>,
                image: orionStar0,
            },
            {
                title: "OrionStars Online Offers A Secure & Reliable Platform",
                detail: <>
                    <p>Orion Star online has seamlessly integrated fast and comfortable payment options so that you will
                        not have any issues while depositing or withdrawing funds from your account. The transactional
                        process is done via e-wallets by using various cryptocurrencies.</p>
                    <p>This option not only makes the payment much faster but also ensures the security of each
                        transaction. Don't know <a href="#"
                            target="_blank">how these payments work</a>? Check out the link and
                        see the guide. You can make easy deposits if you play on your browser or use the Orion Stars 777
                        app download to play on mobile devices. However, you can also enjoy Orion Stars login online
                        play without downloading.</p>
                    <p>If you have some questions or concerns about payment options, you can always contact customer
                        support. Their team of professionals will be happy to help you and guide you through the
                        process.&nbsp;</p>
                </>,
                image: orionStar1,
            },
            {
                title: "Receive Free Credits When You Sign Up",
                detail: <>
                    <p>As mentioned above, the Orion Casino has fantastic bonuses and promotions. And here's how you
                        start earning free money on the platform.&nbsp;First, when you register on the platform, you
                        receive a $20 worth of Orion Stars sign up bonus for playing and trying different games. This
                        allows you to find and start betting on games you particularly enjoy!&nbsp;&nbsp;</p>
                </>,
                image: orionStar2,
            },
            {
                title: "Orion Stars Casino Offers Generous Bonuses and Promotions",
                detail: <>
                    <p>You can use the Orion Casino free play new account no deposit bonus to enjoy numerous games
                        without risking your money. Besides that, you can use the numerous bonuses you get on this
                        platform to increase your winning chances.&nbsp;</p>
                    <p>Using the bonus packages on <a href="#" target="_blank">Orion
                        Stars 777</a> login, you can substantially increase your account balance and get more chances of
                        winning as you play longer.&nbsp;Signing up with this casino platform will have many bonus
                        opportunities. Use those bonuses in exciting sweepstakes games on the platform and create a
                        winning combo that can lead you to successful results.&nbsp;</p>
                    <p>You can also get bonuses when you play Orion Stars online no download for Android to play on your
                        mobile devices. So, sign up now and explore a fascinating number of promotions on the
                        platform!</p>
                </>,
                image: orionStar3,
            },
            {
                title: "Play Fantastic Games on Orion Casino",
                detail: <>
                    <p>You can use the free Play OrionStars to try many games on the platform. It's easy to test the
                        games if you're a new player. Furthermore, the games are optimized for the Orion sweepstakes
                        app. So you can play on your mobile device without losing quality.&nbsp;</p>
                    <p><br />
                        Regardless of your device choice, you will manage to enjoy each game to the fullest using the
                        Orion Stars sweepstakes apk. If you are an online slot machine fan, you will have options like
                        Buffalo Slots, Diamonds &amp; Riches, Wild Jokers, Fortune Gods, etc.&nbsp;</p>
                    <p><br />
                        On the other hand, if you are leaning more toward the table or arcade OrionStars games, you
                        might check titles like Ocean King Strike, Octopus Ling's Revenge, and Super Keno.&nbsp;</p>
                    <p><br />
                        The OrionStar online is full of exceptionally well-designed games. There are various slot and
                        fish table games you can choose from. You can enjoy these games and experience fantastic
                        gameplay.&nbsp;<br />
                        &nbsp;</p>
                </>,
                image: orionStar4,
            },
            {
                title: "Orion Casino Offers Mind-Blowing Graphics",
                detail: <>
                    <p><span><span>The quality of sweepstakes games is a crucial factor that can positively or negatively impact your gaming action. Therefore, they've added only the best quality online slots and fishing slot 777 to choose from.&nbsp;&nbsp;</span></span>
                    </p>
                    <p><br />
                        <span><span>We are surely colorful and interactive graphics and unique sound effects that will tremendously improve games' quality. Regardless of what your favorite genre is to play, you can always find the latest and most incredible options for that specific genre.&nbsp;&nbsp;</span></span>
                    </p>
                    <p><br />
                        <span><span>You get the same fantastic graphics and immersive audio when you use the Orion Stars app as when you use Orion Stars play on web. So, regardless of your choice, you still get the best!&nbsp;</span></span>
                    </p>
                    <p><br />
                        <span><span>Just sign into your account using the Orion Stars Casino login and start playing.&nbsp;&nbsp;</span></span>
                    </p>
                </>,
                image: orionStar5,
            },
            {
                title: "Orion Stars Game Download",
                detail: <>
                    <p><span><span>The OrionStars 777 app download allows you to play your favourite games on the go, wherever and whenever! It's the best way to win more cash rewards while sitting comfortably at a park. The download process is simple and only takes a few minutes.&nbsp;</span></span>
                    </p>
                    <p><br />
                        <span><span>You can contact customer support for more inquiries about the Orion Stars download. The support team is always available to assist you regardless of the issue. So, use the casino app to play your favorite games and win more cash!&nbsp;</span></span>
                    </p>
                </>,
                image: orionStar6,
            },
        ],
        faqs: [
            {
                title: "What is Orion Stars Casino?",
                content: "OrionStars is one of the best online casino platforms with innovative and immersive sweepstakes games that keep players returning for more! In addition, the casino has a wide selection of online fish tables and slot machines. So sign up using the Orion Casino login and start winning more cash rewards.",
            },
            {
                title: "How to get free money on Orion Stars?",
                content: "You can get free money by signing up for Orion Stars Online. First, create an account on Cloud9, choose this platform, and you'll automatically get free money. You can use this bonus money to play many games on the platform and win more cash rewards!",
            },
            {
                title: "How to cash out from Orion Stars Casino?",
                content: "To cash out, click \"Withdrawal\" and choose the amount you want to withdraw. The minimum amount you can take out from the platform is $20. Remember that the withdrawal process can take about 48 hours to complete. The team will review your request and immediately send the amount to your account.",
            },
            {
                title: "How to win on Orion Stars?",
                content: "The best way to win is to keep practising and learning as you play. You can also check out our blogs to learn about the winning strategies for various sweepstakes games. This will boost your winning chances and eventually give you a more fulfilling experience.",
            },
            {
                title: "How to win the jackpot on Orion Stars?",
                content: "The best way to win the jackpot with an Orion Star online account is to be consistent. Most players who have won the progressive jackpot were consistent throughout. You should also wager wisely and play the games you like. If you're a beginner, we recommend trying the free slot games before trying them out for real money.",
            },
            {
                title: "Is Orion Stars 777 Legit?",
                content: "Yes. This casino platform is one of the best platforms licensed by reputable gaming authorities. Your activities are secured whether you use the OrionStars app or a desktop computer. So you can enjoy your games without any worries.",
            },
            {
                title: "How to create an Orion Stars Play Online account?",
                content: "To sign up, simply submit the contact form. Then, wait for a response from customer support after that. They will assist you in completing the registration process and provide any extra information you may require.",
            },
            {
                title: "How do you get Orion Stars Online coins?",
                content: "You need to sign up first before you can receive free coins. After signing up, you'll access various benefits, including a percentage of your initial deposit matched, weekly promotions, free gold and sweeps coins, and more.",
            }
        ]
    },
]
export default GameData;