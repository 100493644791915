import GameData from "../games/GameData";
import {Link, NavLink} from "react-router-dom";
import {AGE_21, BITCOIN_ICON} from "../../assets/images/Images";
import {BsAndroid, BsAndroid2, BsFacebook, BsInstagram, BsTelegram, BsTwitterX} from "react-icons/bs";
import PromotoinData from "../promotions/PromotoinData";

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-menu">
                    <ul>
                        <li className="menu-title">Resources</li>
                        <li className="menu-item">
                            <NavLink to={'/deposit'}>Instant Deposit</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to={'/support'}>Support</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to={'/faq'}>FAQ</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to={'/how-it-works'}>How To Play</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to={'/privacy-policy'}>Privacy Policy</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to={'/terms-of-use'}>Terms Of Use</NavLink>
                        </li>
                    </ul>
                    <ul className="css-lspql7">
                        <li className="menu-title">Platforms</li>
                        {
                            GameData.map((g, index) => {
                                return <li key={index} className="menu-item">
                                    <Link to={`${g.path}`}>{g.name}</Link>
                                </li>
                            })
                        }
                    </ul>
                    <ul>
                        <li className="menu-title">Pages</li>
                        <li className="menu-item">
                            <NavLink to={'/about'}>About</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to={'/referral-program'}>Referral Program</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to={'/promotions'}>Promotions</NavLink>
                        </li>
                    </ul>
                    <ul>
                        <li className="menu-title">Promotions</li>
                        <li className="menu-item">
                            <a href="#">User Role Bonuses</a>
                        </li>
                        <li className="menu-item">
                            <a href="#">Sign Up Bonus</a>
                        </li>
                        <li className="menu-item">
                            <a href="#">First Deposit Bonus</a>
                        </li>
                        <li className="menu-item">
                            <a href="#">Second Deposit Bonus</a>
                        </li>
                        <li className="menu-item">
                            <a href="#">Lucky Charm</a>
                        </li>
                    </ul>
                </div>
                <div className="footer-detail">
                    <h4 className="detail-title">Cloud9 - The Best Online Sweepstakes Platform</h4>
                    <p>Cloud9 offers you the best slot games that are guaranteed to give you a memorable experience. We
                        give you the latest and great online slots right to your doorstep with zero financial risks and
                        privacy.</p>
                    <div className="footer-detail-row">
                        <img src={AGE_21} alt=""/>
                        <img src={BITCOIN_ICON} alt=""/>
                    </div>
                    <div className="footer-detail-row">
                        <ul>
                            <li>
                                <a href="#">
                                    <BsFacebook/>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <BsInstagram/>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <BsTwitterX/>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <BsTelegram/>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <BsAndroid2/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
