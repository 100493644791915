import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

function Logout() {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.setItem("UserId", "")
        localStorage.setItem("FirstName", "")
        localStorage.setItem("LastName", "")
        localStorage.setItem("UserName", "")
        localStorage.setItem("Email", "")
        localStorage.setItem("Phone", "")
        localStorage.setItem("DOB", "")
        localStorage.setItem("IDCard", "")
        localStorage.setItem("Password", "")
        localStorage.setItem("Status", "")
        localStorage.setItem("Role", "")
        navigate("/", {replace: true});
    }, []);

}

export default Logout;