export const PromotionCard = ({data,title}) => {
    return (
        <div className="promotion-card-container" data-aos={'fade-up'}>
            <div className="promotion-card">
                <img src={data.image} alt="Promotional Image"/>
                {
                    title && <h4>{data.title}</h4>
                }
            </div>
        </div>
    )
}
