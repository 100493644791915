import React, {useEffect, useState} from "react";
import {Steps, theme} from 'antd';
import PhoneInput from 'react-phone-input-2'
import {IDCARD_1, IDCARD_2} from "../../../../assets/images/Images";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {toast} from "react-toastify";
import API_LINK from "../../../../components/link/Link";
import $ from 'jquery';
import {useNavigate} from "react-router-dom";

export const Register = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('');
    const [userName, setUserName] = useState('');
    const [DOB, setDOB] = useState('');
    const [idCard, setIdCard] = useState('');
    const [idCardFront, setIdCardFront] = useState('');
    const [idCardBack, setIdCardBack] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPassword, setIsPassword] = useState(false);
    const [isConfirmPassword, setIsConfirmPassword] = useState(false);
    const [current, setCurrent] = useState(0);

    // Function to handle file input change
    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (type === "front")
                    setIdCardFront(reader.result);
                else
                    setIdCardBack(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const next = async () => {
        setCurrent(current + 1);
    };
    const prev = async () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Personal Info',
            content: <>
                <div className="form-group-row" style={{marginTop: '30px'}}>
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="text" autoFocus value={firstName}
                               onChange={event => setFirstName(event.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" value={lastName} onChange={event => setLastName(event.target.value)}/>
                    </div>
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" value={email} onChange={event => setEmail(event.target.value)}/>
                </div>
                <div className="form-group">
                    <label>Username</label>
                    <input type="text" value={userName} onChange={event => setUserName(event.target.value)}/>
                </div>
                <div className="form-group">
                    <label>Phone</label>
                    <PhoneInput specialLabel={''} autocompleteSearch={true} country={'us'} value={phone}
                                onChange={event => setPhone(event)}/>
                </div>
                <div className="form-group">
                    <label>Date Of Birth</label>
                    <input type="date" value={DOB} onChange={event => setDOB(event.target.value)}/>
                </div>
                <div className="form-group">
                    <label>Promo Code <small>(optional)</small></label>
                    <input type="text"/>
                </div>
                <div className="form-group">
                    <label>Referral Code <small>(optional)</small></label>
                    <input type="text"/>
                </div>
            </>,
        },
        {
            title: 'Verify Your Identity',
            content: <>
                <div className={'form-group-images'} style={{marginTop: '30px'}}>
                    <img src={IDCARD_1} alt=""/>
                    <img src={IDCARD_2} alt=""/>
                </div>
                <div className="form-group">
                    <label>ID card number</label>
                    <input type="text" value={idCard} onChange={event => setIdCard(event.target.value)}/>
                </div>
                <div className="form-group-row">
                    <div className="form-group">
                        <label>ID card front</label>
                        <input type="file" onChange={(event) => {
                            handleFileChange(event, 'front');
                        }}/>
                    </div>
                    <div className="form-group">
                        <label>ID card back</label>
                        <input type="file" onChange={(event) => {
                            handleFileChange(event, 'back');
                        }}/>
                    </div>
                </div>
            </>,
        },
        {
            title: 'Choose Password',
            content: <>
                <div className="form-group-row" style={{marginTop: '30px'}}>
                    <div className="form-group">
                        <label>Password</label>
                        <input type={isPassword ? "text" : "password"} value={password}
                               onChange={event => setPassword(event.target.value)}/>
                        <button className='btn_password' onClick={() => setIsPassword(!isPassword)}>
                            {
                                isPassword ?
                                    <EyeInvisibleOutlined/> :
                                    <EyeOutlined/>
                            }
                        </button>
                    </div>
                    <div className="form-group">
                        <label>Confirm Password</label>
                        <input type={isConfirmPassword ? "text" : "password"} value={confirmPassword}
                               onChange={event => setConfirmPassword(event.target.value)}/>
                        <button className='btn_password' onClick={() => setIsConfirmPassword(!isConfirmPassword)}>
                            {
                                isConfirmPassword ?
                                    <EyeInvisibleOutlined/> :
                                    <EyeOutlined/>
                            }
                        </button>
                    </div>
                </div>
            </>,
        },
    ];
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));


    const handleSubmit = async () => {
        try {
            if (!password && !confirmPassword) {
                toast.warning("All the fields are required! (Step 3)");
                return;
            } else if (password !== confirmPassword) {
                toast.warning("Password and Confirm Password must be same.");
                return;
            }
            if (password.length < 6) {
                toast.warning("Password length should be greater than 6 digits.");
                return;
            }
            let toaster;
            var form = $('.auth-form');
            let formdata = new FormData();

            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            try {
                formdata.append("page", "CreateUser");
                formdata.append("firstname", firstName)
                formdata.append("lastname", lastName)
                formdata.append("email", email)
                formdata.append("username", userName)
                formdata.append("phone", phone)
                formdata.append("dob", DOB)
                formdata.append("idcard", idCard)
                formdata.append("idcard_front", idCardFront)
                formdata.append("idcard_back", idCardBack)
                formdata.append("password", password)
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${API_LINK}Authentication.php`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result);
                        if (result.status === 1) {
                            setTimeout(() => {
                                toast.update(toaster, {
                                    type: "success",
                                    render: "You account has been created successfully and under process please check your provided email for further details.",
                                    isLoading: false,
                                    autoClose: toaster,
                                    pauseOnHover: false,
                                    pauseOnFocusLoss: false,
                                    closeButton: true,
                                    closeOnClick: true,
                                })
                                setFirstName("")
                                setLastName("")
                                setEmail("")
                                setUserName("")
                                setPhone("")
                                setDOB("")
                                setIdCardBack("")
                                setIdCard("")
                                setIdCardFront("")
                                setPassword("")
                                setConfirmPassword("")
                                form.find("button").attr("disabled", false);
                                navigate("/");
                            }, 1000)
                        } else if (result.status === 2) {
                            form.find("button").attr("disabled", false);
                            toast.update(toaster, {
                                type: "info",
                                render: "Please check your email an account already exist with the provided email address.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        } else {
                            toast.update(toaster, {
                                type: "error",
                                render: "Server Error! Please try again later.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        }
                    })
                    .catch(error => console.log('error', error));
            } catch (e) {
                console.log(e)
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <div className={'container'}>
                <div className="auth-form-container">
                    {/*<form>*/}
                    <div className="auth-form">
                        <div className="auth-form-title">
                            <h1>Register</h1>
                        </div>
                        <Steps current={current} items={items} responsive={true}/>

                        <div className="auth-form-field">
                            <div>{steps[current].content}</div>
                            <div
                                style={{
                                    marginTop: 24,
                                }}
                                className={'button-group'}
                            >
                                {current < steps.length - 1 && (
                                    <div className="form-group">
                                        <button className={'btn btn-primary'} onClick={async () => {
                                            if (current === 0) {
                                                if (firstName && lastName && email && userName && phone && DOB) {
                                                    setCurrent(current + 1);
                                                } else {
                                                    toast.warning("All the fields are required! (Step 1)");
                                                }
                                            } else if (current === 1) {
                                                if (idCard && idCardFront && idCardBack) {
                                                    setCurrent(current + 1);
                                                } else {
                                                    toast.warning("All the fields are required! (Step 2)");
                                                }
                                            }
                                        }}>Next
                                        </button>
                                    </div>
                                )}
                                {current === steps.length - 1 && (
                                    <div className="form-group">
                                        <button className={'btn btn-primary'} onClick={handleSubmit}>Done</button>
                                    </div>
                                )}
                                {current > 0 && (
                                    <div className="form-group">
                                        <button className={'btn btn-secondary'} onClick={prev}>Previous</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/*</form>*/}
                </div>
            </div>
        </>
    )
}
