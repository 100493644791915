import React, {useEffect} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {MdDashboard} from "react-icons/md";
import {LogoutOutlined} from "@ant-design/icons";
import $ from "jquery";
import {AiOutlineClose} from "react-icons/ai";

function SideBar() {
    const navigate = useNavigate();
    useEffect(() => {
        console.log(localStorage.getItem("UserId"));
        if (localStorage.getItem("UserId") === null || localStorage.getItem("UserId") === undefined || localStorage.getItem("UserId") === "") {
            navigate("/", {replace: true});
        }
        $('#side_panel a').click(function () {
            $('#side_panel').removeClass('show');
        })
    }, [navigate]);
    return (
        <>
            <div className="sidebar-panel" id={'side_panel'}>
                <button className="btn-mobile-menu btn-close" onClick={() => {
                    $('#side_panel').removeClass('show');
                }}>
                    <AiOutlineClose/>
                </button>
                <ul>
                    <li>
                        <NavLink to="/user/dashboard">
                            <MdDashboard/>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/user/profile">
                            <MdDashboard/>
                            <span>Profile</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/user/change-password">
                            <MdDashboard/>
                            <span>Password Change</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/user/verifications">
                            <MdDashboard/>
                            <span>Verifications</span>
                        </NavLink>
                    </li>
                    {
                        localStorage.getItem("Role") === "User" &&
                        <li>
                            <NavLink to="/user/messages">
                                <MdDashboard/>
                                <span>Messages</span>
                            </NavLink>
                        </li>
                    }
                    {
                        localStorage.getItem("Role") === "User" &&
                        <>
                            <li>
                                <span className={'sidebar-subtitle'}>Platforms</span>
                            </li>
                            <li>
                                <NavLink to="/user/platforms">
                                    <MdDashboard/>
                                    <span>Upload PlatformIDs</span>
                                </NavLink>
                            </li>
                        </>
                    }
                    <li>
                        <span className={'sidebar-subtitle'}>Finance</span>
                    </li>
                    <li>
                        <NavLink to="/user/deposit">
                            <MdDashboard/>
                            <span>Deposit</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/user/withdrawals">
                            <MdDashboard/>
                            <span>Withdrawals</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/user/logout">
                            <LogoutOutlined/>
                            <span>Logout</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default SideBar;