import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "./components/navigation/ScrollToTop";
import './assets/css/style.css';
import './assets/css/responsive.css';
import {Navigation} from "./components/navigation/Navigation";

AOS.init();

function App() {
    return (
        <>
            <ScrollToTop/>
            <ToastContainer draggable closeButton closeOnClick pauseOnFocusLoss={false} pauseOnHover={false}/>
            <Navigation/>
        </>
    );
}

export default App;
