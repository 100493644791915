import React, {useEffect, useState} from 'react';
import GameData from "../../../components/games/GameData";
import {Button, Popconfirm, Table} from "antd";
import API_LINK from "../../../components/link/Link";
import {toast} from "react-toastify";
import $ from "jquery";
import {BiTrash} from "react-icons/bi";

function UploadPlatformId() {
    const [platform, setPlatform] = useState('')
    const [platformID, setPlatformID] = useState('');
    const [platformData, setPlatformData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        LoadData();
    }, []);

    const LoadData = async () => {
        try {
            setIsLoading(true)
            let formdata = new FormData();
            formdata.append("page", "LoadPlatformData");
            formdata.append("userId", localStorage.getItem('UserId'));

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    // console.log(result);
                    if (result.status === 1) {
                        setPlatformData(result.data);
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                })
                .catch(error => console.log('error', error));
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    function handleSubmit() {
        try {
            if (platform && platformID) {
                let toaster;
                var form = $('.dashboard-content-table');
                let formdata = new FormData();

                form.find("button").attr("disabled", true);
                toaster = toast.loading("Processing...");
                formdata.append("page", "SavePlatformID");
                formdata.append("userId", localStorage.getItem("UserId"))
                formdata.append("platform", platform.trim())
                formdata.append("platformId", platformID)


                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${API_LINK}Orders.php`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        // console.log(result);
                        if (result.status === 1) {
                            toast.update(toaster, {
                                type: "success",
                                render: "Platform id has saved successfully.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            setPlatform('')
                            setPlatformID('')
                            LoadData();
                            form.find("button").attr("disabled", false);
                        } else if (result.status === 2) {
                            toast.update(toaster, {
                                type: "info",
                                render: "Platform Id already exist!",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            form.find("button").attr("disabled", false);
                        } else {
                            toast.update(toaster, {
                                type: "error",
                                render: "Server Error! Please try again later.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            form.find("button").attr("disabled", false);
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                toast.warn("All fields are required!");
            }
        } catch (e) {
            console.log(e)
        }
    }

    function handleDelete(platform) {
        try {
            let toaster;
            var form = $('.dashboard-content-table');
            let formdata = new FormData();

            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            formdata.append("page", "DeletePlatformID");
            formdata.append("platform", platform.id)


            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${API_LINK}Orders.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    // console.log(result);
                    if (result.status === 1) {
                        toast.update(toaster, {
                            type: "success",
                            render: "Platform id has deleted successfully.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                        LoadData();
                        form.find("button").attr("disabled", false);
                    } else {
                        toast.update(toaster, {
                            type: "error",
                            render: "Server Error! Please try again later.",
                            isLoading: false,
                            autoClose: toaster,
                            pauseOnHover: false,
                            pauseOnFocusLoss: false,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="dashboard-content-container">
            <div className="dashboard-content">
                <div className="dashboard-content-title">
                    <h1>Deposits</h1>
                </div>
                <div className="dashboard-content-table">
                    <div className="form-group-row">
                        <div className="form-group">
                            <label>Platform</label>
                            <select name="platform" id="platform" value={platform}
                                    onChange={(event) => setPlatform(event.target.value)}>
                                <option disabled selected value="">Select Platform</option>
                                {
                                    GameData.map((platform, index) => {
                                        return (
                                            <option key={index} value={platform.name}>{platform.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Platform ID</label>
                            <input type="text" placeholder={'Platform ID'} value={platformID}
                                   onChange={event => setPlatformID(event.target.value)}/>
                        </div>
                    </div>
                    <div className="form-group mv-2">
                        <button className={'btn btn-primary'} onClick={() => {
                            handleSubmit();
                        }}>Save ID
                        </button>
                    </div>
                </div>
                <div className="dashboard-content-table">
                    <Table dataSource={platformData} pagination={{pageSize: 10}} loading={isLoading}
                           rowHoverable={true}>
                        <Table.Column title="#" width={70} render={(j, r, index) => {
                            return (
                                <span>{index + 1}</span>
                            )
                        }}/>
                        <Table.Column title={'Platform'} dataIndex={'Platform'}/>
                        <Table.Column title={'Platform ID'} dataIndex={'PlatformID'}/>
                        <Table.Column title={'Date'} dataIndex={'Date'}/>
                        <Table.Column title="Delete" render={(u) => {
                            return (
                                <Popconfirm
                                    title="Delete this id?"
                                    description="Are you sure to delete this platform id?"
                                    onConfirm={() => {
                                        handleDelete(u);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type={'primary'} danger icon={<BiTrash width={15}/>}/>
                                </Popconfirm>
                            )
                        }}/>
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default UploadPlatformId;