export const PrivacyPolicy = () => {
    return (
        <div className="container">
            <div className="text-banner">
                <h1>Privacy Policy</h1>
            </div>
            <section className="terms-conditions-section">
                <div className="css-1qzjy3y"><p className="chakra-text css-0">The Privacy Policy
                    conducts the manner in which <b>Cloud9</b> collects, maintains,
                    and discloses the information that has been collected from <b>Cloud9</b> website. The policy
                    applies to all the products and services that <b>Cloud9</b> offers.</p><h4
                    className="chakra-heading css-1saq518">Personal identification information</h4>
                    <p className="chakra-text css-0">The personal identification information can be collected from users
                        in multiple ways when they:
                        <ul>
                            <li>Register at our website;</li>
                            <li>Subscribe to the newsletter;</li>
                            <li>Fill out certain forms on the website, and so on.</li>
                        </ul>
                        Users may be asked to provide their full name, email address, phone number, payment credentials,
                        etc. On the other hand, users might as well visit our website anonymously. Users' personal
                        information is subject to be collected if only they voluntarily agree to share it. Users have
                        the right to refuse to share certain information. However, that might prevent them from engaging
                        in some of the site-related activities.
                    </p>
                    <h4 className="chakra-heading css-1saq518">Non-personal identification information</h4><p
                        className="chakra-text css-0">Whenever users interact with our website, we might collect
                        non-personal identification info. That might include the type of computer they used to connect
                        to our site, their browser name, and other technical information, including their internet
                        service provider and their device's operating system.</p><h4
                        className="chakra-heading css-1saq518">Web browser cookies</h4><p
                        className="chakra-text css-0">To enhance the user experience, our site uses 'cookies.' "Cookies"
                        on the web are placed on the hard drive of the user's device, and they track info about them.
                        The purpose of these tools is to keep a record of online actions. On the other hand, users might
                        refuse cookies or set alerts to get informed whenever a website sends cookies to them. While
                        doing so, they need to consider that some parts of the website might not function properly when
                        "cookies" got disabled.</p><h4 className="chakra-heading css-1saq518">How we use collected
                        information</h4><p className="chakra-text css-98urqk"><b>Cloud9</b> might collect and use users' info for the following
                        purposes:</p>
                    <ul>
                        <li><strong className="chakra-text css-98urqk">To improve customer service</strong><br/>The
                            provided information can help us improve customer service and meet their needs more
                            efficiently.
                        </li>
                        <li><strong className="chakra-text css-98urqk">To personalize user experience</strong><br/>We
                            use the data to understand how our user base utilizes our website's resources and services.
                        </li>
                        <li><strong className="chakra-text css-98urqk">To improve our Site</strong><br/>User feedback
                            can help us improve our products and services.
                        </li>
                        <li className="css-0"><strong className="chakra-text css-98urqk">To process
                            payments</strong><br/>We use the payment info to finalize the transaction for that specific
                            user/order. It would not be shared with third parties unless the operation is needed for
                            providing that service.
                        </li>
                        <li className="css-0"><strong className="chakra-text css-98urqk">To run a promotion, contest,
                            survey or other site feature</strong><br/>To send Users information they agreed to receive
                            about topics we think will be of interest to them.
                        </li>
                        <li className="css-0"><strong className="chakra-text css-98urqk">To send periodic
                            emails</strong><br/>might use the provided email address to send them daily updates about
                            their order. It can also be used to answer user questions and cooperate with them on their
                            requests. If the user accepts to be added to the mailing list, they will receive emails
                            about our services, products, news, and related subjects. We provide the subscription
                            details at the end of every email. So, whenever users decide to opt out of the email
                            newsletter, they can use those unsubscribe instructions or directly contact us via <b>Cloud9's</b> official website.
                        </li>
                    </ul>
                    <h4 className="chakra-heading css-1saq518">How we protect your information</h4><p>To protect user
                        data from unauthorized access, disclosure, alteration, or destruction of personal information,
                        such as username, password, payment credentials, and transaction details, we employ appropriate
                        security measures and data collection, storage, and processing practices.</p><br/><p
                        className="chakra-text css-0">Sensitive data exchange between the site and user happens over a
                        secure SSL encrypted communication channel protected with digital signatures. To create a safe
                        environment for our users, we designed our website in a way that complies with PCI vulnerability
                        standards.</p><h4 className="chakra-heading css-1saq518">Sharing your personal information</h4>
                    <p className="chakra-text css-0">Users' personal identification information is kept private, and we
                        do not share, sell or trade it. However, we may share generic info about demographic which is
                        not linked to the personal information that users provided. It can be shared only with our
                        trusted affiliates, business partners, and advertisers for the sole purpose that is mentioned
                        above. Only if users permit us may we share their information with the third-party service
                        providers for them to use it to improve the business and complete administer activities on our
                        behalf.</p><h4 className="chakra-heading css-1saq518">Compliance with children’s online privacy
                        protection act</h4><p className="chakra-text css-98urqk">Protecting the privacy of young
                        children is especially important. Therefore, we never collect or maintain data from our users
                        who are under 21. On top of that, no part of our website is neither intended nor structured to
                        attract young children under 21.</p><h4 className="chakra-heading css-1saq518">Changes to this
                        privacy policy</h4><p className="chakra-text css-0"><b>Cloud9</b> has the right to update this privacy policy at any
                        time. Whenever it happens, users can find the relative updates on this page. When we change the
                        privacy policy, we will set up a notification on the website's main page so that users can be
                        informed. So, please, check the privacy policy page frequently to get updated about the security
                        measures that we take to keep your data safe. You agree and acknowledge that the responsibility
                        to check the updates regarding the privacy policy is yours.</p><h4
                        className="chakra-heading css-1saq518">Your acceptance of these terms</h4><p
                        className="chakra-text css-0">By using the website, you accept the terms that we set on this
                        page. If you are not accepting any of the mentioned points, please do not use our website.
                        Following the posting of updates regarding the privacy policy, you are entitled to be aware of
                        the changes to the current version if you continue to use the site.</p></div>
            </section>
        </div>
    )
}
