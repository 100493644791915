import React from 'react';
import {BANNER_1} from "../../assets/images/Images";
import {AiOutlineClose} from "react-icons/ai";

function PromotionModal({isVisible, setIsVisible}) {
    if (isVisible) {
        return (
            <div className="promotion-modal-container">
                <div className={`promotion-modal ${isVisible ? 'show' : ''}`}>
                    <button className="btn-modal-close" onClick={() => setIsVisible(false)}>
                        <AiOutlineClose/>
                    </button>
                    <div className="promotion-modal-content">
                        <img src={BANNER_1} alt=""/>
                    </div>
                </div>
            </div>
        );
    } else
        return null;
}

export default PromotionModal;
