import {BANNER_1, BANNER_2, BANNER_3, BANNER_4, BANNER_5, BANNER_6} from "../../assets/images/Images";

const PromotoinData = [
    {
        id: 1,
        link: "/",
        title: "Register Now to Claim a $20 Worth of Welcome Bonus",
        image: BANNER_1,
    }, {
        id: 2,
        link: "/",
        title: "Earn 100% of your First Deposit + $5 Free Play",
        image: BANNER_2,
    }, {
        id: 3,
        link: "/",
        title: "Take Advantage of the 2nd Deposit Match Bonus",
        image: BANNER_3,
    }, {
        id: 4,
        link: "/",
        title: "Enjoy 3rd Deposit Match Bonus + $15 Free Play",
        image: BANNER_4,
    }, {
        id: 5,
        link: "/",
        title: "Check Cloud9’s Lucky Charm Treasure Table for Big Wins!",
        image: BANNER_5,
    }, {
        id: 6,
        link: "/",
        title: "Quest Achievements",
        image: BANNER_6,
    },
]
export default PromotoinData;