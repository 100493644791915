import React, {useState} from "react";
import {BsChevronDown, BsChevronUp} from "react-icons/bs";
import FAQData from "../../../components/faq_data/FAQData";

export const Faqs = () => {
    const [hiddenIndices, setHiddenIndices] = useState([0]);
    const toggleHide = (index) => {
        setHiddenIndices((prevHiddenIndices) => {
            if (prevHiddenIndices.includes(index)) {
                // Remove index if it already has the `hide` class
                return prevHiddenIndices.filter(i => i !== index);
            } else {
                // Add index if it doesn't have the `hide` class
                return [...prevHiddenIndices, index];
            }
        });
    };

    return (
        <div className="container">
            <div className="text-banner">
                <h1>FAQs</h1>
            </div>
            <div className="section-faqs">
                {
                    FAQData.map((faq, index) => (
                        <div
                            className={`faq-item ${hiddenIndices.includes(index) ? '' : 'hide'}`} // Apply `hide` class conditionally
                            key={index}
                            onClick={() => toggleHide(index)} // Toggle hide on click
                        >
                            <div className="faq-header">
                                <span>{faq.title}</span>
                                <button>
                                    {
                                        hiddenIndices.includes(index) ?
                                            <BsChevronDown/> : <BsChevronUp/>
                                    }

                                </button>
                            </div>
                            <div className="faq-content">
                                {faq.content}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
